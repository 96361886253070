import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import Card from '../../../components/Card';
import { Link } from 'react-router-dom';
import axios from 'axios'; // Import Axios
import Swal from 'sweetalert2';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import '../Main/tablesearch.css';

const Invoice = ({ user }) => {
    const [Invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const token = user.user.user.token
    useEffect(() => {
        // Fetch invoices data using Axios
        axios.get(process.env.REACT_APP_API_URL + '/companies/' + user.user.user.companyDetails.id + '/invoices', {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }) // Replace with your API endpoint
            .then((response) => {

                setInvoices(response.data.invoiceData);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching invoices:', error);
                setLoading(false);
            });
    }, []);
    useEffect(() => {

        // Initialize the DataTable when categories change
        if (!loading && Invoices.length > 0) {
            if (!$.fn.DataTable.isDataTable(dataTableRef.current)) {
                // If not initialized, initialize the DataTable
                $(dataTableRef.current).DataTable({
                    columns: [
                        { data: 'id', title: 'ID' },
                        { data: 'customer', title: 'Customer' },
                        { data: 'date', title: 'Date' },
                        { data: 'order_number', title: 'Order Number' },

                        { data: 'total', title: 'Total' },
                        { data: 'status', title: 'Status' },
                        { data: '', title: 'Action' },
                    ],
                    order: [[0, 'desc']],
                });
            }
        }

        $(dataTableRef.current).on('click', '.delete-button', function () {
            const invoiceId = $(this).data('invoice-id');
            cancelInvoiceHandler(invoiceId);
        });
        $(dataTableRef.current).on('click', '.show-invoice-button', function () {
            const invoiceId = $(this).data('invoice-id');
            const invoiceNumber = $(this).data('invoice-number');
            const invoiceEmail = $(this).data('invoice-email');
            showInvoiceActionsDialog(invoiceId, invoiceNumber, invoiceEmail);
        });
    }, [Invoices, loading]);
    const dataTableRef = useRef(null);
    const updateInvoiceStatus = (invoiceId, newStatus) => {
        // Create a copy of the current Invoices state
        const updatedInvoices = [...Invoices];
        // Find the index of the invoice with the given ID
        const invoiceIndex = updatedInvoices.findIndex((invoice) => invoice.id === invoiceId);
        // Update the status of the invoice
        if (invoiceIndex !== -1) {
            updatedInvoices[invoiceIndex].invoice_status = newStatus;
            if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
                const table = $(dataTableRef.current).DataTable();
                const row = table.row(`#row-${invoiceId}`);
                const statusCell = row.cell({ row: row.index(), column: 5 });
                const newStatusContent = `
            <p class="mb-0 ${getStatusColor(newStatus)} font-weight-bold d-flex justify-content-start align-items-center">
                <small><svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                    <circle cx="12" cy="12" r="8" fill="${getStatusColor(newStatus)}"></circle></svg>
                </small>${capitalizeFirstLetter(newStatus)}
            </p>
              `;
                statusCell.data(newStatusContent);
                row.draw();
            }

        }
    };

    const cancelInvoiceHandler = (invoiceId) => {
        Swal.fire({
            title: 'Confirm Cancellation',
            text: 'Are you sure you want to cancel this invoice?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, cancel it!',
            cancelButtonText: 'No, keep it',
        }).then((result) => {
            if (result.isConfirmed) {
                // User confirmed, send an Axios request to cancel the invoice
                axios.delete(process.env.REACT_APP_API_URL + '/companies/' + user.user.user.companyDetails.id + '/invoices/' + invoiceId, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                })
                    .then((response) => {
                        if (response.data.status) {
                            updateInvoiceStatus(invoiceId, 'canceled');
                            Swal.fire('Cancelled!', 'The invoice has been canceled.', 'success');
                        } else {
                            Swal.fire('Error', 'An error occurred while canceling the invoice.', 'error');
                        }

                    })
                    .catch((error) => {
                        Swal.fire('Error', 'An error occurred while canceling the invoice.', 'error');
                    });
            }
        });
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'draft':
                return '#f39c12'; // Yellow
            case 'issued':
                return '#3498db'; // Blue
            case 'paid':
                return '#3cb72c'; // Green
            case 'canceled':
                return '#e74c3c'; // Red
            default:
                return '#000000'; // Black (Fallback color)
        }
    };

    const showInvoiceActionsDialog = (invoiceId, invoiceNumber, email) => {
        const url = process.env.REACT_APP_BASE_URL + 'terminal/' + btoa(invoiceNumber);
        Swal.fire({
            title: 'Invoice Actions',
            html:
                `<div>
                <div class="mb-3">
                    <label for="copyLink" class="form-label">Copy Invoice Link</label>
                    <input type="text" class="form-control" id="copyLink" value=${url} readonly>
                    
                    <button class="btn btn-primary mt-2" id="copyButton">Copy</button>
                </div>
                <div class="mb-3">
                <span class="text-success ml-2" id="linkMessage"></span>
                </div>
                <div class="mb-3">
                    <label for="emailInput" class="form-label">Send Email to Customer</label>
                    <input type="email" class="form-control" id="emailInput" value=${email}>
                    
                    <button class="btn btn-primary mt-2" id="sendEmailButton">Send Email</button>
                </div>
                <div class="mb-3">
                <span class="text-success ml-2" id="emailMessageSuccess"></span>
                    <span class="text-danger ml-2" id="emailMessageError"></span>
                </div>
            </div>`,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Close',
        });

        // Copy link button functionality
        const copyButton = document.getElementById('copyButton');
        copyButton.addEventListener('click', () => {
            const copyLinkInput = document.getElementById('copyLink');
            copyLinkInput.select();
            document.execCommand('copy');
            document.getElementById('linkMessage').innerHTML = "Link Copied!";

        });

        // Send email button functionality
        const sendEmailButton = document.getElementById('sendEmailButton');
        sendEmailButton.addEventListener('click', () => {
            const emailInput = document.getElementById('emailInput');
            if (emailInput.value.trim() === '') {
                document.getElementById('emailMessageError').innerHTML = "Please enter a valid email address!";
            } else {
                sendEmailButton.innerHTML = "Sending....";
                sendEmailButton.disabled = true;
                const invoiceData = {
                    invoiceId: invoiceId,
                    toEmail: emailInput.value,
                }
                axios.post(process.env.REACT_APP_API_URL + '/send-invoice', invoiceData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                })
                    .then(response => {
                        if (response.data.status) {
                            sendEmailButton.innerHTML = "Send Email";
                            sendEmailButton.disabled = false;
                            document.getElementById('emailMessageSuccess').innerHTML = "The invoice has been sent to the customer\'s email.";
                        } else {
                            sendEmailButton.innerHTML = "Send Email";
                            sendEmailButton.disabled = false;
                            document.getElementById('emailMessageError').innerHTML = 'Sending invoice failed';
                        }
                    })
                    .catch(error => {
                        sendEmailButton.innerHTML = "Send Email";
                        sendEmailButton.disabled = false;
                        document.getElementById('emailMessageError').innerHTML = 'Sending invoice failed';
                    });

            }
        });
    };

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <>
            <Container fluid>
                <Row>

                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold">All Invoice</h4>
                            </div>
                            <div className="create-workform">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="modal-product-search d-flex">

                                        <Link to="/orders-new" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                            Add Invoice
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Row>
                            <Col lg="12">
                                <Card className="card-block card-stretch">
                                    <Card.Body className="p-0">
                                        <div className="d-flex justify-content-between align-items-center p-3">
                                            <h5 className="font-weight-bold">Invoices List</h5>

                                        </div>
                                        <div className="table-responsive">
                                            {loading ? (
                                                <div className="text-center mb-5">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <table ref={dataTableRef} className="table data-table mb-0">
                                                    <thead className="table-color-heading">
                                                        <tr className="">
                                                            <th scope="col">
                                                                <label className="text-muted m-0" >ID</label>
                                                            </th>
                                                            <th scope="col">
                                                                <label className="text-muted m-0" >Customer</label>

                                                            </th>
                                                            <th scope="col" className="dates">
                                                                <label className="text-muted mb-0" >Date</label>

                                                            </th>
                                                            <th scope="col">
                                                                <label className="text-muted mb-0" >Number</label>

                                                            </th>
                                                            <th scope="col" className="">
                                                                <label className="text-muted mb-0">Total</label>

                                                            </th>
                                                            <th scope="col">
                                                                <label className="text-muted mb-0" >Status</label>

                                                            </th>
                                                            <th scope="col" className="text-center">
                                                                <span className="text-muted" >Action</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            Invoices.map((item) => (
                                                                <tr key={item.id} id={`row-${item.id}`} className="white-space-no-wrap">
                                                                    <td>{item.id}</td>
                                                                    <td className="">
                                                                        <div className="active-project-1 d-flex align-items-center mt-0 ">

                                                                            <div className="data-content">
                                                                                <div>
                                                                                    <span className="font-weight-bold">{item.customer != null ? item.customer.name : 'N/A'}</span>
                                                                                </div>
                                                                                <p className="m-0 text-secondary small">
                                                                                    {item.customer.email}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{item.invoice_date}</td>
                                                                    <td>
                                                                        {item.invoice_number}
                                                                    </td>
                                                                    <td className="">
                                                                        {item.currency.symbol} {item.paid}
                                                                    </td>
                                                                    <td>
                                                                        <p className={`mb-0 ${getStatusColor(item.invoice_status)} font-weight-bold d-flex justify-content-start align-items-center`}>
                                                                            <small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                                                                <circle cx="12" cy="12" r="8" fill={getStatusColor(item.invoice_status)}></circle></svg>
                                                                            </small>{capitalizeFirstLetter(item.invoice_status)}
                                                                        </p>

                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>} >
                                                                                <Link className="" to={`/invoices-view/${item.id}`}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                                                    </svg>
                                                                                </Link>
                                                                            </OverlayTrigger>
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Link</Tooltip>} >

                                                                                <button className='btn btn-defualt show-invoice-button' data-invoice-id={item.id} data-invoice-number={item.invoice_number} data-invoice-email={item.customer.email} onClick={() => showInvoiceActionsDialog(item.id, item.invoice_number, item.customer.email)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="text-secondary">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                                                                                    </svg>
                                                                                </button>

                                                                            </OverlayTrigger>
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Cancel</Tooltip>} >
                                                                                <button className="badge bg-danger delete-button" href="#" data-invoice-id={item.id} onClick={() => cancelInvoiceHandler(item.id)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                                    </svg>
                                                                                </button>


                                                                            </OverlayTrigger>


                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))

                                                        }

                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Invoice;