import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import logo from '../../../assets/images/logo.png'
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';

const TopUpInvoice = () => {

    const history = useHistory();
    const user = useSelector(state => state?.login?.user?.user?.userModel);
    console.log(user, "user");
    const params = useParams();
    const id = atob(params?.id);
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [invoiceData, setInvoiceData] = useState({});

    useEffect(() => {
        setInvoiceLoading(true);
        axios.post(process.env.REACT_APP_API_URL + '/get-topup-transactions-by-id', { id: id })
            .then((res) => {
                console.log(res, "res");
                if (res?.data?.status) {
                    setInvoiceData(res?.data?.data);
                    setInvoiceLoading(false);
                }
            }).catch((err) => {
                setInvoiceLoading(false);
                console.log(err, "err");
            })
    }, []);

    const handlePrint = (e) => {
        e.preventDefault();
        window.print();
    };

    const goBack = () => {
        history.goBack();
    };

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                        <h4 className="font-weight-bold">Users Details</h4>
                        <Button onClick={goBack} className="loginBtn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                            </svg>
                            Back
                        </Button>
                    </div>
                </Col>
                <Col lg={8} md={12} className='in-center'>
                    <div className={invoiceLoading ? 'invoice-main-box loading' : 'invoice-main-box'}>
                        {invoiceLoading ?
                            <div className='invoice-loader'>
                                <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />
                            </div> :
                            <>
                                <div className='invoice_header_box'>
                                    <div className='logo-box'>
                                        {invoiceData?.attempt_by?.user_details?.company_name}
                                    </div>
                                </div>
                                <div className='invoice-header'>
                                    <h6>Order Receipt</h6>
                                </div>
                                <div className='main-body'>
                                    <Row>
                                        <Col md={8}>
                                            <div className="invoice-info-box">
                                                <h3>Product Description:</h3>
                                                <h5>Top Up Recharge</h5>
                                                <div className="invoice-info">
                                                    <h6>Receiver's Phone :</h6>
                                                    <p>{invoiceData?.full_number}</p>
                                                </div>
                                                <div className="invoice-info">
                                                    <h6>Receiver's Country :</h6>
                                                    <p>{invoiceData?.country}</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="invoice-right-main-box">
                                                <div className="invoice-right-box">
                                                    <h5>Order #</h5>
                                                    <p>{invoiceData?.id}</p>
                                                </div>
                                                <div className="invoice-right-box">
                                                    <h5>Date :</h5>
                                                    <p>{moment(invoiceData?.created_at).format('YYYY-MM-D')}</p>
                                                </div>
                                                <button type="submit" onClick={handlePrint} className="btn btn-primary btn-pay-now btn-md my-1 print-hide" id="print-invoice"><i className="la la-print" id="payIcon"></i> Print </button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive mt-4">
                                        <table className="table table-striped">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th>#</th>
                                                    {user?.is_admin ?
                                                        <th>Reseller</th> : null
                                                    }
                                                    <th>Operator</th>
                                                    {/* <th>Product</th> */}
                                                    {/* <th>Date</th> */}
                                                    <th>Amount</th>
                                                    <th>Amount Received</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    {user?.is_admin ?
                                                        <td>{invoiceData?.attempt_by?.user_details?.company_name}</td> : null
                                                    }
                                                    <td className="mb-tb-wrap">{invoiceData?.operator?.our_name}</td>
                                                    {/* <td className="td-wrap">Top Up</td> */}
                                                    {/* <td className="td-wrap">{moment(invoiceData?.created_at).format('YYYY-MM-D')}</td> */}
                                                    <td className="td-wrap">&euro; {invoiceData?.amount}</td>
                                                    <td className="td-wrap">&euro; {invoiceData?.total_amount}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="total-sec">
                                        <div className="total-box">
                                            <Row className='align-items-center'>
                                                <Col md={9} sm={4} className='text-md-right text-sm-left text-left'>
                                                    <h5>Sub Total : </h5>
                                                </Col>
                                                <Col md={3} sm={8} className="text-md--right text-sm-left text-left">
                                                    <p>&euro; {invoiceData?.amount}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        {/* <div className="total-box">
                                            <Row className='align-items-center'>
                                                <Col md={9} sm={4} className='text-md-right text-sm-left text-left'>
                                                    <h5>Commission : </h5>
                                                </Col>
                                                <Col md={3} sm={8} className="text-md--right text-sm-left text-left">
                                                    <p>&euro; {(invoiceData?.total_amount - invoiceData?.amount).toFixed(2)}</p>
                                                </Col>
                                            </Row>
                                        </div> */}
                                        <div className="total-box all-total">
                                            <Row className='align-items-center p-tb-8'>
                                                <Col md={9} sm={4} className='text-md-right text-sm-left text-left'>
                                                    <h5>Total amount payed: </h5>
                                                </Col>
                                                <Col md={3} sm={8} className="text-md--right text-sm-left text-left">
                                                    <p>&euro; {invoiceData?.total_amount}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                <div className="invoice-footer-box">
                                    <h5>Thank you for your business</h5>
                                    <div className="invoice-message">
                                        <p>System generated invoice does not require signature.</p>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </Col>
            </Row>
        </Container >
    );
}

export default TopUpInvoice;
