import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Button, Form, Spinner } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Card from '../../../components/Card';
import { connect } from "react-redux";
import { getDarkMode } from '../../../store/mode';
import axios from 'axios';
import Swal from 'sweetalert2';
import { loginSuccess, loginWith2FASuccess } from '../../../store/actions';
import logo from '../../../assets/images/logo.png';
import logo2 from '../../../assets/images/logo2.png';

function mapStateToProps(state) {
   return {
      darkMode: getDarkMode(state)
   };
}



const SignIn = (props) => {
   let history = useHistory()
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [isLoading, setIsLoading] = useState(false);
   const [errors, setErrors] = useState({
      email: '',
      password: ''
   });

   useEffect(() => {
      const containsTerminal = window.location.pathname.includes('/terminal');
      if (containsTerminal) {
         const match = window.location.pathname.match(/^\/terminal\/([^/]+)\/?/);
         const dynamicInvoiceId = match ? match[1] : null;
         window.location.href = "/terminal/" + dynamicInvoiceId;
      }
   }, [history]);

   useEffect(() => {
      document.title = 'Log in | 2G Connect';
   }, []);

   const handleSubmit = event => {
      event.preventDefault();
      const validationErrors = {};
      setIsLoading(true);

      // Validate email and password
      if (!email || !email.match(/^\S+@\S+\.\S+$/)) {
         validationErrors.email = 'Please enter a valid email address';
      }

      if (!password || password.length < 8) {
         validationErrors.password = 'Password must be at least 8 characters long';
      }

      // If there are validation errors, update the state and return
      if (Object.keys(validationErrors).length > 0) {
         setErrors(validationErrors);
         setIsLoading(false);
         return;
      }

      // If validation passes, attempt login
      axios.post(process.env.REACT_APP_API_URL + '/login', { email, password })
         .then(response => {
            console.log(response, "response");
            setIsLoading(false);
            // Handle successful login, for example dispatch a redux action
            // and navigate to the dashboard
            if (response.data.data.user.userModel.allow_2fa) {
               props.dispatchLoginWith2FASuccess(response.data.data);
               history.push('/auth/otp');
            } else {
               props.dispatchLoginSuccess(response.data.data);
               history.push('/dashboard');
            }
         })
         .catch(error => {
            console.log(error);
            setIsLoading(false);
            Swal.fire('Ooops!', error.response.data.message, 'error');
         });
   };

   const handleInputChange = event => {
      const { name, value } = event.target;
      setErrors({
         ...errors,
         [name]: ''
      });

      if (name === 'email') {
         setEmail(value);
      } else if (name === 'password') {
         setPassword(value);
      }
   };

   return (
      <>
         <section className="login-content">
            <Container className="h-100">
               <Row className="align-items-center justify-content-center h-100">
                  <Col md="5">
                     <Card className="p-3">
                        <Card.Body>
                           <div className="auth-logo">
                              <img src={logo} className={`img-fluid  rounded-normal  ${!props.darkMode ? 'd-none' : ''}`} alt="logo" />
                              <img src={logo} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} alt="logo" />
                              <img src={logo2} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} alt="logo" style={{ height: '40px', marginTop: '15px', marginLeft: '15px' }} />
                           </div>
                           <h3 className="mb-3 font-weight-bold text-center">Log In</h3>
                           <p className="text-center text-secondary mb-4">Log in to your account to continue</p>

                           <Form>
                              <Row>
                                 <Col lg="12">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">Email</Form.Label>
                                       <Form.Control
                                          type="email"
                                          name="email"
                                          placeholder="example@example.com"
                                          value={email}
                                          onChange={handleInputChange}
                                       />
                                       {errors.email && <div className="text-danger">{errors.email}</div>}
                                    </Form.Group>
                                 </Col>
                                 <Col lg="12" className="mt-2">
                                    <Form.Group>
                                       <div className="d-flex justify-content-between align-items-center">
                                          <Form.Label className="text-secondary">Password</Form.Label>
                                          <Form.Label><Link to="/auth/recoverpw" className="linkColor">Forgot Password?</Link></Form.Label>
                                       </div>
                                       <Form.Control
                                          type="password"
                                          name="password"
                                          placeholder="Your Password"
                                          value={password}
                                          onChange={handleInputChange}
                                       />
                                       {errors.password && <div className="text-danger">{errors.password}</div>}
                                    </Form.Group>
                                 </Col>
                              </Row>
                              {isLoading ?
                                 <div className="loader-box">
                                    <Spinner animation="border" role="status">
                                       <span className="sr-only">Loading...</span>
                                    </Spinner>
                                 </div> :
                                 <Button type="submit" onClick={handleSubmit} variant="btn btn-primary btn-block mt-2 loginBtn">Log In</Button>
                              }
                              <Col lg="12" className="mt-3">
                                 <p className="mb-0 text-center">Don't have an account? <Link to="/auth/sign-up" className="linkColor">Sign Up</Link></p>
                              </Col>
                           </Form>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </section>
      </>
   )
}
const mapDispatchToProps = (dispatch) => ({
   dispatchLoginSuccess: (userData) => dispatch(loginSuccess(userData)),
   dispatchLoginWith2FASuccess: (userData) => dispatch(loginWith2FASuccess(userData))
});
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);