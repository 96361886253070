//router
import LayoutsRoute from './router/layouts-route';
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

//scss files
import './assets/scss/backend.scss';
import './assets/css/custom.css';
const stripePromise = loadStripe('pk_live_51NloiLAXaXOhqH5WLysOiWA1JTDaDUR71dCSEwbyapbDZyFrHa7Fi1EykxXTdiU1TDOw0W7RrwuyplpgLkswC98g009e0H6Yna');

function App() {
  return (
    <div className="App">
      <Elements stripe={stripePromise}>
        <LayoutsRoute />
      </Elements>,

    </div>
  );
}

export default App;
