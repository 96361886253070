import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Tab, Form, Button } from 'react-bootstrap'
import Card from '../../../../components/Card';
import '../../Main/colorPicker.css';
import axios from 'axios'; // Import Axios
//img 
import user1 from '../../../../assets/images/user/1.jpg'

const UserProfileEdit = ({ user }) => {
    const [colorThem, setColorThem] = useState('');
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [userName, setName] = useState('');
    const [surName, setSurName] = useState('');
    const [userEmail, setEmail] = useState('');
    const [userPhone, setPhone] = useState('');
    const [userImage, setImage] = useState(null);
    const [userImagePreview, setUserImagePreview] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [countryId, setCountryId] = useState('');
    const [stateId, setStateId] = useState('');
    const [city, setCity] = useState({});
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const token = user.user.user.token;
    const currentUserData = user.user.user.user.userModel;
    const currentUserDetailsData = user.user.user.user.UserDetailModel;

    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {
        setName(currentUserData.name);
        setSurName(currentUserData.surname);
        setEmail(currentUserData.email);
        setPhone(currentUserData.phone);
        setCountryId(currentUserDetailsData.country_id);
        setStateId(currentUserDetailsData.state_id);
        setImage(null);
        if (currentUserData.image) {
            setUserImagePreview(process.env.REACT_APP_BACKEND_URL + currentUserData.image);
        }
    }, [currentUserData]);

    useEffect(() => {
        getStatesByCountryId();
    }, [countryId]);

    const getCountries = () => {
        axios.get(process.env.REACT_APP_API_URL + '/get-all-countries')
            .then((res) => {
                if (res.data.status) {
                    setCountries(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getStatesByCountryId = () => {
        const header = {
            Authorization: `Bearer ${token}`
        };

        axios.post(process.env.REACT_APP_API_URL + '/get-states-by-country-id', { countryId: countryId }, { header })
            .then((res) => {
                if (res.data.status) {
                    setStates(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }


    // const toggleColorPicker = () => {
    //     if (colorThem !== null) {
    //         setShowColorPicker(!showColorPicker);
    //     }
    // };

    // const closeColorPicker = () => {
    //     setShowColorPicker(false);
    // };

    // const handleColorChange = (color) => {
    //     setColorThem(color.hex);
    // };
    // // const handleLogoChange = (event) => {
    // //     const file = event.target.files[0];
    // //     if (file) {
    // //         setLogo(file);
    // //         setLogoPreview(URL.createObjectURL(file));
    // //     }
    // // };

    const handleImageChange = (event) => {
        const selectedUserImage = event.target.files[0];
        if (selectedUserImage) {
            setImage(selectedUserImage);
            setUserImagePreview(URL.createObjectURL(selectedUserImage));
        }
    };

    // const handleSave = async () => {

    //     const formData = new FormData();
    //     // formData.append('name', name);
    //     // formData.append('email', email);
    //     // formData.append('phone', phone);
    //     formData.append('color_them', colorThem);
    //     formData.append("_method", "PUT");
    //     formData.append('userName', userName);
    //     formData.append('userEmail', userEmail);
    //     formData.append('userPhone', userPhone);

    //     // if (logo) {
    //     //     formData.append('logo', logo);
    //     // }

    //     if (userImage) {
    //         formData.append('userImage', userImage);
    //     }

    //     // try {
    //     //     setIsUpdating(true);
    //     //     const response = await axios.post(
    //     //         process.env.REACT_APP_API_URL + '/company/' + currentCompanyData.id,
    //     //         formData,
    //     //         {
    //     //             headers: {
    //     //                 Authorization: `Bearer ${token}`,
    //     //                 'Content-Type': 'multipart/form-data', // Set the correct content type
    //     //             },
    //     //         }
    //     //     );

    //     //     if (response.data.status) {
    //     //         Swal.fire('Done!', "Details has been updated you need to re-login again for updates", 'success').then(() => {

    //     //         });
    //     //     } else {
    //     //         Swal.fire('Oops!', response.data.message, 'error');
    //     //     }
    //     // } catch (error) {
    //     //     console.error('Error updating company:', error);
    //     //     // Handle error scenarios
    //     // } finally {
    //     //     setIsUpdating(false);
    //     // }
    // };

    return (
        <>
            <Container fluid>
                <Tab.Container defaultActiveKey="personal-information">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Header>
                                    <Card.Header.Title>
                                        <h4 className="card-title">Edit Profile Information</h4>
                                    </Card.Header.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row className="form-group  align-items-center">
                                        <Col md="12">
                                            <div className="profile-img-edit">
                                                <div className="crm-profile-img-edit">
                                                    <img className="crm-profile-pic rounded-circle avatar-100" src={userImagePreview ? userImagePreview : user1} alt="profile-pic" />
                                                    <div className="crm-p-image bg-primary">
                                                        <label htmlFor="userImageUpload" className="upload-label text-white" style={{ cursor: 'pointer' }}>
                                                            <i className="las la-pen upload-button"></i>
                                                        </label>
                                                        <input type="file" accept="image/*" id="userImageUpload" onChange={handleImageChange} style={{ display: 'none' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center">
                                        <Col md={12}>
                                            <Form className="row g-3 date-icon-set-modal">
                                                <div className="col-md-6 mb-3">
                                                    <Form.Label htmlFor="Text1" className="font-weight-bold text-muted text-uppercase">Name</Form.Label>
                                                    <Form.Control type="text" id="Text1" placeholder="Name" name="name" value={userName} onChange={(e) => setName(e.target.value)} />
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <Form.Label htmlFor="Text2" className="font-weight-bold text-muted text-uppercase">Surname</Form.Label>
                                                    <Form.Control type="text" id="Text2" placeholder="Surname" name="surname" value={surName} onChange={(e) => setName(e.target.value)} />
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <Form.Label htmlFor="Text3" className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                                    <Form.Control type="email" id="Text3" placeholder="Enter Email" name="email" value={userEmail} onChange={(e) => setEmail(e.target.value)} />
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <Form.Label htmlFor="Text4" className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                    <Form.Control type="number" id="Text4" placeholder="Enter Phone" name="phone" value={userPhone} onChange={(e) => setPhone(e.target.value)} />
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <Form.Label htmlFor="Select1" className="font-weight-bold text-muted text-uppercase">Country</Form.Label>
                                                    <Form.Control as={'select'} id="Select1" name="country" value={countryId}>
                                                        {countries.length > 0 ? countries.map((country, index) => {
                                                            return (
                                                                <option value={country.id} key={index}>{country.name}</option>
                                                            )
                                                        }) : null}
                                                    </Form.Control>
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <Form.Label htmlFor="Select2" className="font-weight-bold text-muted text-uppercase">State</Form.Label>
                                                    <Form.Control as={'select'} id="Select2" name="state" value={stateId}>
                                                        {states.length > 0 ? states.map((state, index) => {
                                                            return (
                                                                <option value={state.id} key={index}>{state.name}</option>
                                                            )
                                                        }) : null}
                                                    </Form.Control>
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <Form.Label htmlFor="Text5" className="font-weight-bold text-muted text-uppercase">City</Form.Label>
                                                    <Form.Control type="number" id="Text5" placeholder="Enter City" name="city" value={city} onChange={(e) => setCity(e.target.value)} />
                                                </div>
                                            </Form>
                                        </Col>
                                        <Button type="submit" className="loginBtn" variant="primary" disabled={isUpdating}>
                                            {isUpdating ? 'Updating...' : 'Update Details'}
                                        </Button>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </>
    )
}

export default UserProfileEdit