import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Card from '../../../components/Card';
import { Link, useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

const CategoriesEdit = ({ user }) => {
    const { categoryId } = useParams();
    let history = useHistory();

    const [formData, setFormData] = useState({
        name: '',
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const token = user.user.user.token;

    useEffect(() => {
        // Fetch the category details when the component mounts
        fetchCategoryDetails();
    }, []);

    const fetchCategoryDetails = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + `/companies/${user.user.user.companyDetails.id}/categories/${categoryId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const categoryData = response.data.category;
            setFormData({
                name: categoryData.name,
            });
        } catch (error) {
            console.error('Error fetching category details:', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const newErrors = {};

        // Check for required fields
        if (!formData.name) {
            newErrors.name = 'Name is required';
        }

        setErrors(newErrors);

        // Return true if there are no errors
        return Object.keys(newErrors).length === 0;
    };

    const handleEditCategory = async () => {

        if (validateForm()) {
            try {
                setIsLoading(true);
                const formDataObj = new FormData();
                formDataObj.append("name", formData.name);
                formDataObj.append("_method", "PUT");
                const response = await axios.put(
                    process.env.REACT_APP_API_URL + `/companies/${user.user.user.companyDetails.id}/categories/${categoryId}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.data.status) {
                    Swal.fire('Done!', response.data.message, 'success').then(() => {
                        history.push('/categories'); // Redirect to the categories list page
                    });
                } else {
                    Swal.fire('Oops!', response.data.message, 'error');
                }
            } catch (error) {

                if (error.response) {
                    const errorMessages = Object.values(error.response.data.errors).flat().join('\n');
                    Swal.fire('Validation Error', errorMessages, 'error');
                } else {
                    Swal.fire('Validation Error', error.message, 'error');
                }

            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/categories">Categories</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Edit Category
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <Link
                                to="/categories"
                                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path
                                        fillRule="evenodd"
                                        d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold0 d-flex align-items-center">Edit Category</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md="6">
                                        <Form className="row g-3">
                                            <div className="col-md-12 mb-3">
                                                <Form.Label htmlFor="name" className="font-weight-bold text-muted text-uppercase">
                                                    Name
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.name && <div className="text-danger">{errors.name}</div>}
                                            </div>
                                        </Form>

                                        <div className="d-flex justify-content-end mt-3">
                                            <Button
                                                variant="btn btn-primary"
                                                onClick={handleEditCategory}
                                                disabled={isLoading}
                                            >
                                                {isLoading ? 'Updating...' : 'Update Category'}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default CategoriesEdit;
