import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { verifyOTP } from '../../../store/actions';

const OTP = (props) => {

    useEffect(() => {
        document.title = 'Verification | 2G Connect';
    }, []);

    const history = useHistory();
    const dispatch = useDispatch();

    const userID = props.user.user.user.user.userModel.id;

    const [otp, setOtp] = useState('');
    const [errors, setErrors] = useState({ otp: '' });
    const [isLoading, setIsLoading] = useState(false);
    const changeHandler = (e) => {
        setOtp(e.target.value);
    }

    const submitHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (otp === null || otp === '') {
            setErrors({ otp: 'OTP is required.' });
            setIsLoading(false);
        } else {
            axios.post(process.env.REACT_APP_API_URL + '/verify-otp', { otp, userID })
                .then((res) => {
                    if (res.data.status) {
                        setIsLoading(false);
                        dispatch(verifyOTP(res.data.data));
                        history.push('/dashboard');
                    } else {
                        setIsLoading(false);
                        Swal.fire('Ooops!', res.data.message, 'error');
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                    Swal.fire('Ooops!', err.response.data.message, 'error');
                });
        }
    }

    const handleButtonClick = () => {
        history.push('/auth/2FA');
    };

    return (
        <>
            <section className="login-content">
                <Container className="h-100">
                    <Row className="row justify-content-center align-items-center " style={{ height: '70vh' }}>
                        <Col className="col-md-8 col-md-offset-2">
                            <Card>
                                <h4 className="card-heading text-center mt-4">Verification</h4>
                                <hr />
                                <Card.Body style={{ textAlign: 'center' }}>
                                    <Form className="form-horizontal">
                                        <Form.Group className="form-group">
                                            <p>Please enter the  <strong>OTP (One Time Password)</strong> generated on your Authenticator App. <br /><br /> Ensure you submit the current one because it refreshes every 30 seconds.</p>
                                            {/* <Form.Label htmlFor="one_time_password" className="col-md-4 control-label">One Time Password</Form.Label> */}
                                            <Form.Control id="otp" onChange={changeHandler} type="number" className={errors.otp ? 'is-invalid' : ''} name="otp" placeholder='OTP' />
                                            {errors.otp ? <div className="invalid-feedback text-left">{errors.otp}</div> : null}
                                        </Form.Group>
                                        <Button onClick={handleButtonClick} className="btn btn-default mr-2 backBtn">Scan QR</Button>
                                        {isLoading ?
                                            <div className="loader-box">
                                                <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            </div> :
                                            <Button onClick={submitHandler} className="btn btn-primary loginBtn">Verify</Button>
                                        }
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default OTP;