import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button, ListGroup, Spinner, Alert } from 'react-bootstrap';
import Card from './../components/Card'
import { Link, useParams, useHistory } from 'react-router-dom'
import axios from 'axios'; // Import Axios
import Swal from 'sweetalert2';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


// const stripePromise = loadStripe('pk_live_51NloiLAXaXOhqH5WLysOiWA1JTDaDUR71dCSEwbyapbDZyFrHa7Fi1EykxXTdiU1TDOw0W7RrwuyplpgLkswC98g009e0H6Yna');

const Terminal = () => {
    const history = useHistory();
    const { invoiceId } = useParams();
    const [invoiceData, setInvoiceData] = useState([]);
    const [orderData, setOrderData] = useState({});
    const [orderItemsData, setOrderItemsData] = useState([]);
    const [customerData, setCustomerData] = useState({});
    const [customerCountryData, setCustomerCountryData] = useState({});
    const [customerStateData, setCustomerStateData] = useState({});
    const [companyData, setCompanyData] = useState({});
    const [currencyData, setCurrencyData] = useState({});
    const [cardNumber, setCardNumber] = useState('');
    const [cvv, setCvv] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cardNumberError, setCardNumberError] = useState('');
    const [cvvError, setCvvError] = useState('');
    const [expiryDateError, setExpiryDateError] = useState('');
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);
    const [isPaymentSuccessful, setPaymentSuccessful] = useState(false);
    const [nameOnCard, setNameOnCard] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [stripePromise, setStripePromise] = useState(null);
    const stripe = useStripe();
    const elements = useElements();
    useEffect(() => {
        checkInvoiceFormat();
        fetchInvoiceData();
        loadStripePromise();
    }, []);

    const loadStripePromise = () => {
        if (invoiceData.random_number_pk) {
            const stripePublishableKey = atob(invoiceData.random_number_pk);
            const stripePromise = loadStripe(stripePublishableKey);
            setStripePromise(stripePromise);
        }
    };
    const checkInvoiceFormat = () => {
        if (invoiceId) {
            const decodedInvoiceNumber = atob(invoiceId);
            if (!decodedInvoiceNumber.startsWith("INV-")) {
                history.push('/not-found');
            }
        } else {
            history.push('/not-found');
        }

    }
    const fetchInvoiceData = () => {
        axios.get(process.env.REACT_APP_API_URL + '/invoice/' + invoiceId)
            .then((response) => {
                const fetchedInvoiceData = response.data;
                if (fetchedInvoiceData.status) {
                    setInvoiceData(fetchedInvoiceData.invoiceData);
                    setCompanyData(fetchedInvoiceData.invoiceData.company);
                    setOrderData(fetchedInvoiceData.invoiceData.order);
                    setOrderItemsData(fetchedInvoiceData.invoiceData.order.order_items);
                    setCustomerData(fetchedInvoiceData.invoiceData.customer);
                    setCustomerCountryData(fetchedInvoiceData.invoiceData.customer.country);
                    setCustomerStateData(fetchedInvoiceData.invoiceData.customer.state);
                    setCurrencyData(fetchedInvoiceData.invoiceData.currency);
                    setClientSecret(atob(fetchedInvoiceData.invoiceData.random_number));

                } else {
                    history.push('/not-found');
                }
            })
            .catch((error) => {
                history.push('/not-found');
            });
    }

    const validateCardNumber = () => {
        const errors = {};
        if (!cardNumber || !/^[0-9]{16}$/.test(cardNumber)) {
            errors.cardNumber = 'Invalid card number. It must be 16 digits.';
        }

        setCardNumberError(errors.cardNumber || '');
        return Object.keys(errors).length === 0;
    };

    const validateCvv = () => {
        const errors = {};

        if (!cvv || !/^[0-9]{3}$/.test(cvv)) {
            errors.cvv = 'Invalid CVV. It must be a 3-digit number.';
        }

        setCvvError(errors.cvv || '');
        return Object.keys(errors).length === 0;
    };

    const validateExpiryDate = () => {
        const errors = {};

        if (!expiryDate || !/^(0[1-9]|1[0-2])\/\d{2}$/.test(expiryDate)) {
            errors.expiryDate = 'Invalid expiry date. It must be in the format MM/YY.';
        }

        setExpiryDateError(errors.expiryDate || '');
        return Object.keys(errors).length === 0;
    };


    function formatDate(inputDate) {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };

        const date = new Date(inputDate);
        const formattedDate = date.toLocaleDateString('en-US', options);

        // Replace 'st', 'nd', 'rd', 'th' for the day part
        const dayWithSuffix = formattedDate.replace(
            /(\d{1,2})(st|nd|rd|th)/,
            (match, day, suffix) => {
                return day + (suffix === 'st' ? 'st' : suffix === 'nd' ? 'nd' : suffix === 'rd' ? 'rd' : 'th');
            }
        );

        return dayWithSuffix;
    }

    const handlePayment = async (e) => {
        e.preventDefault();


        setIsProcessingPayment(true);
        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: customerData.name,
                },
            },
        });

        if (error) {

            setIsProcessingPayment(false);
            saveTransaction(error, false, error.message);

        } else {

            console.log('Payment successful:', paymentIntent);
            // Handle successful payment (e.g., show success message, update state)
        }
    };

    const saveTransaction = (paymentResponse, status, message) => {
        const paymentData = {
            customerId: customerData.id,
            invoiceId: invoiceData.id,
            completeTransactionDetails: paymentResponse,
            transactionStatus: status,
            transactionMessage: message
        }
        axios.post(process.env.REACT_APP_API_URL + '/payment', paymentData)
            .then((response) => {
                setIsProcessingPayment(false);
                const fetchedPaymentData = response.data;
                if (fetchedPaymentData.status) {
                    Swal.fire('Done!', response.data.message, 'success').then(() => {
                        setPaymentSuccessful(true);

                    });
                } else {
                    if (status) {
                        Swal.fire('Ooopsss!', response.data.message, 'error');

                    } else {
                        Swal.fire("Failed", paymentResponse.message, "error");
                    }

                }
            })
            .catch((error) => {
                setIsProcessingPayment(false);
                // Handle any error that occurs during the payment request
                Swal.fire('Ooopsss!', error.message, 'error');

            });
    }
    const handlePrint = () => {
        window.print(); // This will trigger the browser's print dialog
    };
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">

                        </div>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row className="pb-4 mx-0 card-header-border">
                                    <Col lg="12" className="mb-3">
                                        <img className="avatar avatar-50 is-squared" alt="user-icon" src={process.env.REACT_APP_BACKEND_URL + companyData.logo} />
                                        <button className="btn btn-secondary px-4 float-right no-print" onClick={handlePrint}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"></path>
                                            </svg>
                                            Print
                                        </button>
                                    </Col>
                                    <Col lg="6">
                                        <div className="text-left">
                                            <h5 className="font-weight-bold mb-2">Invoice number</h5>
                                            <p className="mb-0">{invoiceData.invoice_number}</p>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="text-right">
                                            <h5 className="font-weight-bold mb-2">Invoice Date</h5>
                                            <p className="mb-0">{formatDate(invoiceData.invoice_date)}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="pt-4 pb-5 mx-0">
                                    <Col lg="6">
                                        <div className="text-left">
                                            <h5 className="font-weight-bold mb-3">Invoice From</h5>
                                            <p className="mb-0 mb-1">{companyData.name}</p>
                                            <p className="mb-0 mb-2">{companyData.email}</p>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="text-right">
                                            <h5 className="font-weight-bold mb-3">Invoice To</h5>
                                            <p className="mb-0 mb-1">{customerData.name}</p>
                                            <p className="mb-0 mb-1">{customerData.address}</p>
                                            <p className="mb-0 mb-1">{customerStateData.name}</p>
                                            <p className="mb-0 mb-1">{customerData.zipcode}</p>
                                            <p className="mb-0 mb-2">{customerCountryData.name}</p>
                                            <p className="mb-0 mb-2">info@blueberry.com</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <ListGroup as="ul" className="list-group-flush">
                                            <ListGroup.Item as="li" className="p-0">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered mb-0">
                                                        <thead>
                                                            <tr className="text-muted">
                                                                <th scope="col" className="text-left">No</th>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Description</th>
                                                                <th scope="col" className="text-right">Quantity</th>
                                                                <th scope="col" className="text-right">Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                orderItemsData.map((item) => (

                                                                    <tr key={item.id}>
                                                                        <td className="text-left">
                                                                            1
                                                                        </td>
                                                                        <td>
                                                                            {item.product.name}
                                                                        </td>
                                                                        <td>
                                                                            {item.product.product_description}
                                                                        </td>
                                                                        <td className="text-right">
                                                                            {item.quantity}
                                                                        </td>
                                                                        <td className="text-right">
                                                                            {currencyData.symbol}{item.price}
                                                                        </td>

                                                                    </tr>
                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li">
                                                <div className="d-flex justify-content-end mb-2">
                                                    Subtotal: <p className="ml-2 mb-0">{currencyData.symbol}{invoiceData.total_amount}</p>
                                                </div>
                                                <div className="d-flex justify-content-end mb-2">
                                                    Discount: <p className="ml-2 mb-0">{currencyData.symbol}{invoiceData.discount_amount}</p>
                                                </div>
                                                <div className="d-flex justify-content-end mb-2">
                                                    Total: <p className="ml-2 mb-0 font-weight-bold">{currencyData.symbol}{invoiceData.paid}</p>
                                                </div>

                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Col>
                                    <Col lg="12">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center p-4">
                                            <div className="flex align-items-start flex-column">
                                                <h6>Notes</h6>
                                                <p className="mb-0 my-2">{orderData.comments}</p>
                                            </div>

                                        </div>
                                    </Col>
                                    {isPaymentSuccessful ? (
                                        <Alert variant="success">Payment successful!, Invoice has been paid!</Alert>
                                    ) : (
                                        <Col lg="6">
                                            <div className="flex align-items-start flex-column text-center no-print" >
                                                <div style={{ border: '1px solid black', padding: '10px' }}>
                                                    <CardElement
                                                        options={{
                                                            style: {
                                                                base: {
                                                                    fontSize: '16px',
                                                                    color: '#424770',
                                                                    border: '1px solid black', // Add this line to set the border
                                                                    '::placeholder': {
                                                                        color: 'black',
                                                                    },
                                                                },
                                                                invalid: {
                                                                    color: '#9e2146',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </div>

                                                <Button
                                                    variant="btn btn-primary px-4 mt-2"
                                                    type="submit"
                                                    disabled={isProcessingPayment}
                                                    onClick={(e) => handlePayment(e)}
                                                >
                                                    {isProcessingPayment ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" role="status" />
                                                            <span className="ml-2">Processing...</span>
                                                        </>
                                                    ) : (
                                                        'Pay Now'
                                                    )}
                                                </Button>
                                            </div>

                                        </Col>
                                    )}

                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Terminal;