import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Col, Container, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";
import $ from 'jquery';
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../store/actions";

const RechargeWallet = ({ user }) => {

    const dispatch = useDispatch();
    const dataTableRef = useRef(null);
    const bankDetailsDataTableRef = useRef(null);
    const [rechargeAmount, setRechargeAmount] = useState('');
    const [selectedPaymentProof, setSelectedPaymentProof] = useState(null);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [rechargeLoading, setRechargeLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [walletDetails, setWalletDetails] = useState([]);
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState({});
    const [walletCurrencyDetails, setWalletCurrencyDetails] = useState([]);
    const [bankDetailsShow, setBankDetailsShow] = useState(false);

    useEffect(() => {
        const token = user.user.user.token;
        getTransactionsData(token);
        getWalletData(token);
        getBanks(token);
    }, [user]);

    useEffect(() => {
        // Initialize the DataTable when categories change
        if (!loading && transactions.length > 0) {
            if (!$.fn.DataTable.isDataTable(dataTableRef.current)) {
                // If not initialized, initialize the DataTable
                $(dataTableRef.current).DataTable({
                    data: transactions,
                    columns: [
                        { data: 'id', title: 'ID' },
                        { data: 'requested_amount', title: 'Requested Amount' },
                        { data: 'transaction_amount', title: 'Approved Amount' },
                        { data: 'transaction_status', title: 'Status' },
                    ], columnDefs: [
                        // Custom rendering for the 'status' column
                        {
                            targets: 3, // Index of the 'status' column
                            render: function (data, type, row) {
                                // Call your custom functions for color and icon based on 'status'
                                const color = getStatusColor(data);
                                const statusName = getStatusName(data);

                                // Return HTML with custom styling
                                return `<p className= "mb-0 ${color} font-weight-bold d-flex justify-content-start align-items-center">
                                <small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                    <circle cx="12" cy="12" r="8" fill=${color}></circle></svg>
                                </small>${statusName}
                            </p>`;
                            },
                        },
                    ],
                    order: [[0, 'desc']],
                });
            }
        }

    }, [transactions, loading]);

    useEffect(() => {
        if ($.fn.DataTable.isDataTable(bankDetailsDataTableRef.current)) {
            $(bankDetailsDataTableRef.current).DataTable().destroy();
        }

        if (!isLoading && banks.length > 0) {
            if (!$.fn.DataTable.isDataTable(bankDetailsDataTableRef.current)) {
                $(bankDetailsDataTableRef.current).DataTable({
                    order: [[0, 'desc']],
                });
            }
        }

        $(bankDetailsDataTableRef.current).on('click', '.status-button', function () {
            const id = $(this).data('bank-id');
            const selectBank = banks.find(x => x?.id === id);
            setSelectedBank(selectBank);
            showBankDetailsModal();
        });
    }, [banks, isLoading]);

    const rechargeAmountChangeHandler = (e) => {
        setRechargeAmount(e.target.value);
    }

    const paymentProofChangeHandler = (e) => {
        // Handle the selected payment proof file
        setSelectedPaymentProof(e.target.files[0]);
    }

    const rechargeWalletHandler = async (e) => {
        e.preventDefault();
        const token = user.user.user.token;
        // Validate recharge amount and payment proof selection
        if (!rechargeAmount || isNaN(rechargeAmount) || rechargeAmount <= 0) {
            Swal.fire('Ooops!', 'Please enter a valid recharge amount.', 'error');
            return;
        }

        if (!selectedPaymentProof) {
            Swal.fire('Ooops!', 'Please select a payment proof.', 'error');
            return;
        }

        try {
            setRechargeLoading(true);
            // Create a FormData object to send the file
            const formData = new FormData();
            formData.append('transaction_amount', rechargeAmount);
            formData.append('payment_proof', selectedPaymentProof);
            formData.append('user_id', user.user.user.user.userModel.id);
            formData.append('currency_id', '1');

            // Make the Axios request
            const response = await axios.post(process.env.REACT_APP_API_URL + '/resellers/store/transactions', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.status) {

                setTransactions((prevTransactions) => {
                    const updatedTransactions = [...prevTransactions, response.data.transactionData];
                    udpateDataTable(updatedTransactions);
                    return updatedTransactions;
                });
                getWalletData(token);
                Swal.fire('Done!', response.data.message, 'success');
                setRechargeAmount('');
                setSelectedPaymentProof(null);
                document.getElementById('paymentProof').value = '';
            } else {
                const errors = response.data.errors;
                const errorMessage = Object.values(errors).flat().join('\n');
                Swal.fire('Validation Error', errorMessage, 'error');
                setRechargeAmount('');
                setSelectedPaymentProof("");
                document.getElementById('paymentProof').value = '';
            }

        } catch (error) {
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            // Handle errors, show a message, etc.
            Swal.fire('Ooops!', 'Recharge failed. Please try again later.', 'error');
        } finally {
            setRechargeLoading(false);
        }
    }

    const getTransactionsData = (token) => {
        axios
            .get(process.env.REACT_APP_API_URL + '/resellers/' + user.user.user.user.userModel.id + '/transactions', {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            .then((response) => {
                setTransactions(response.data.transactionData); // Assuming API response is an array of transactions
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching transactions:', error);
                setLoading(false);

                if (error.response.status === 401) {
                    dispatch(logoutUser());
                }
            });
    }

    const getWalletData = (token) => {
        axios.get(process.env.REACT_APP_API_URL + '/resellers/wallet/' + user.user.user.user.userModel.id, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response) => {
                setWalletDetails(response.data.walletDetails);
                setWalletCurrencyDetails(response.data.walletDetails.currency);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching wallet details:', error);
                setLoading(false);

                if (error.response.status === 401) {
                    dispatch(logoutUser());
                }
            });
    }

    const udpateDataTable = (updatedTransactions) => {
        // Check if DataTable is initialized
        if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
            // Clear existing data and add new data
            $(dataTableRef.current)
                .DataTable()
                .clear()
                .rows.add(updatedTransactions)
                .draw();
        }
    }

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return '#3498db'; // Blue
            case 'completed':
                return '#3cb72c'; // Green
            case 'declined':
                return '#e74c3c'; // Red
            case 'refunded':
                return '#f39c12'; // Orange
            case 'partially refunded':
                return '#e67e22'; // Darker Orange
            default:
                return '#000000'; // Black (Fallback color)
        }
    };

    const getStatusName = (status) => {
        let statusText;
        switch (status) {
            case 'pending':
                statusText = 'Pending';
                break;
            case 'completed':
                statusText = 'Completed';
                break;
            case 'declined':
                statusText = 'Declined';
                break;
            case 'refunded':
                statusText = 'Refunded';
                break;
            case 'partially refunded':
                statusText = 'Partially Refunded';
                break;
            default:
                statusText = 'Unknown Status';
        }
        return statusText;
    };

    const getBanks = (token) => {
        if ($.fn.DataTable.isDataTable(bankDetailsDataTableRef.current)) {
            $(bankDetailsDataTableRef.current).DataTable().destroy();
        }
        setBanks([]);
        setIsLoading(true);
        const headers = {
            Authorization: `Bearer ${token}`,
        }
        axios.get(process.env.REACT_APP_API_URL + '/get-banks', headers)
            .then((res) => {
                setIsLoading(false);
                if (res?.data?.status) {
                    setBanks(res?.data?.data);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err, "err");
            })
    }

    const showBankDetailsModal = () => {
        setBankDetailsShow(true);
    }

    const closeBankDetailsModal = () => {
        setBankDetailsShow(false);
    }

    return (
        <section className="rechargeWallet">
            <Container fluid>
                <Row>
                    <Col md={6}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Balance Details</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row className="align-items-center">
                                    <Col md={12} className="text-center">
                                        <p><strong style={{ fontSize: '3rem' }}>{walletCurrencyDetails.symbol}{walletDetails.balance}</strong></p>
                                        <p style={{ fontSize: '1.2rem' }}>Available Balance</p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Recharge Wallet</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <p><strong>Recharge Amount</strong></p>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Control
                                            type="number"
                                            name="rechargeAmount"
                                            className="mb-3"
                                            value={rechargeAmount}
                                            onChange={rechargeAmountChangeHandler}
                                            placeholder="Enter Recharge Amount"
                                        />
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <p><strong>Select Payment Proof</strong></p>
                                    </Col>
                                    <Col md={6}>
                                        <input
                                            type="file"
                                            accept="image/*,application/pdf"
                                            id="paymentProof"
                                            onChange={paymentProofChangeHandler}
                                        />
                                    </Col>

                                </Row>
                                <Row className="align-items-right mt-3">
                                    <Col md={12}>
                                        <Button
                                            type="button"
                                            className="float-right"
                                            onClick={rechargeWalletHandler}
                                            variant="btn btn-primary mt-2 loginBtn"
                                        >
                                            {rechargeLoading ? (
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    {' '}Loading...
                                                </>
                                            ) : (
                                                'Recharge'
                                            )}
                                        </Button>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                    {banks.length > 0 ?
                        <Col lg="12">
                            <Card className="card-block card-stretch">
                                <Card.Body className="p-0">
                                    <div className="d-flex justify-content-between align-items-center p-3">
                                        <h5 className="font-weight-bold">Banks</h5>
                                    </div>
                                    {isLoading ?
                                        <div className="text-center mb-5">
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                        : <div className="table-responsive">
                                            <table ref={bankDetailsDataTableRef} className="table data-table mb-0">
                                                <thead className="table-color-heading text-left">
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Bank Name</th>
                                                        <th scope="col">Account Holder</th>
                                                        <th scope="col">Iban</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {banks.map((bank, index) => {
                                                        return (
                                                            <tr key={index} className="white-space-no-wrap">
                                                                <td>{index + 1}</td>
                                                                <td>{bank?.bank_name}</td>
                                                                <td>{bank?.account_holder}</td>
                                                                <td>{bank?.iban}</td>
                                                                <td>{bank?.email}</td>
                                                                <td>{bank?.country?.name}</td>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>View Bank Details</Tooltip>}>
                                                                            <div style={{ cursor: 'pointer' }} >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" data-bank-id={bank?.id} className="w-6 h-6 status-button">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                                                </svg>
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>}
                                </Card.Body>
                            </Card>
                        </Col> : null}
                    <Col lg="12">
                        <Card className="card-block card-stretch">
                            <Card.Body className="p-0">
                                <div className="d-flex justify-content-between align-items-center p-3">
                                    <h5 className="font-weight-bold">Transactions List</h5>
                                </div>
                                <div className="table-responsive">
                                    {loading ? (
                                        <div className="text-center mb-5">
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                    ) : transactions.length > 0 ? (
                                        <table ref={dataTableRef} className="table data-table mb-0">
                                        </table>
                                    ) : (
                                        <div className="text-center mb-5">
                                            <p>No transactions available.</p>
                                        </div>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal show={bankDetailsShow} onHide={closeBankDetailsModal} size="lg">
                <Modal.Header>
                    <Modal.Title as="h5">Bank Details</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={closeBankDetailsModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <h5>Bank Name:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.bank_name}</p>
                        </Col>
                        <Col md={4}>
                            <h5>Account Holder:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.account_holder}</p>
                        </Col>
                        <Col md={4}>
                            <h5>IBAN:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.iban}</p>
                        </Col>
                        <Col md={4}>
                            <h5>BIC / SWIFT:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.bic_swift}</p>
                        </Col>
                        <Col md={4}>
                            <h5>Email:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.email}</p>
                        </Col>
                        <Col md={4}>
                            <h5>Country:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.country?.name}</p>
                        </Col>
                        <Col md={4}>
                            <h5>More Info:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.more_info}</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-primary loginBtn' onClick={closeBankDetailsModal}>close</Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default RechargeWallet;
