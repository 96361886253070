import React, { useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const QRCode = (props) => {

    useEffect(() => {
        document.title = '2-Factor Authentication Setup | 2G Connect';
    }, []);

    const history = useHistory();
    const qr = props.user.user.user.qr;
    const secret = props.user.user.user.secret;
    return (
        <section className="login-content">
            <Container className="h-100">
                <Row className="align-items-center justify-content-center h-100">
                    <Col md={7}>
                        <Card>
                            <h4 className="card-heading text-center mt-4">Set up Google Authenticator</h4>
                            <Card.Body className="card-body" style={{ textAlign: 'center' }}>
                                <p>Set up your two factor authentication by scanning the QR Code below. Alternatively, you can use the code: <strong>{secret}</strong></p>
                                <div dangerouslySetInnerHTML={{ __html: qr }}>
                                </div>
                                <p>You must set up your Google Authenticator app before continuing. You will be unable to login otherwise.</p>
                                <div>
                                    <Button onClick={() => history.push('/auth/otp')} className="btn btn-primary loginBtn">Complete Registration</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}

export default QRCode;