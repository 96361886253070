import { Switch, Route } from 'react-router-dom'
import Layout1 from '../layouts/backend/Layout1'
import BlankLayout from '../layouts/BlankLayout';
import NotFound from '../components/NotFound';
import SignIn from '../views/backend/Auth/SignIn'
import Terminal from '../components/Terminal';
import React from 'react';
import { connect } from 'react-redux'; // Import connect function

const LayoutsRoute = (props) => {
    const { user } = props.user;

    return (
        <Switch>
            <Route path="/auth" render={(props) => <BlankLayout {...props} user={user} />} />
            <Route path="/not-found" component={NotFound} />
            <Route path="/terminal/:invoiceId" component={Terminal} />
            <Route
                path="/"
                render={() => {
                    // Check if the user is authenticated or if the route is /terminal/:invoiceId
                    if (props.user.isLoggedIn) {
                        return <Layout1 user={user} />;
                    } else {
                        return <SignIn />;
                    }
                }}
            />
        </Switch>
    )
}

// Map Redux state to component props
const mapStateToProps = (state) => {
    return {
        user: state.login // Assuming you have a "login" reducer with "user" in your Redux state
    };
};

// Connect App component to Redux store
export default connect(mapStateToProps)(LayoutsRoute);