import React, { useEffect, useState } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { getDarkMode } from '../../../store/mode'
import axios from 'axios';
//img
import logo from '../../../assets/images/logo.png'
import darklogo from '../../../assets/images/logo.png';
import logo2 from '../../../assets/images/logo2.png';


function mapStateToProps(state) {
    return {
        darkMode: getDarkMode(state)
    };
}

const EmailVerified = (props) => {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const email = atob(params.get('email'));

    useEffect(() => {
        emailVerify();
    }, []);

    const emailVerify = () => {
        axios.post(process.env.REACT_APP_API_URL + '/email-verify', { email: email })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return (
        <>
            <section className="login-content">
                <Container fluid className="h-100">
                    <Row className="align-items-center justify-content-center h-100">
                        <Col md="5" className="col-md-5">
                            <Card>
                                <Card.Body>
                                    <Row className="align-items-center">
                                        <Col lg="12" className="text-center">
                                            <img src={logo} className={`img-fluid  rounded-normal  ${!props.darkMode ? 'd-none' : ''}`} width="80" alt="logo" />
                                            <img src={darklogo} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} width="80" alt="logo" />
                                            <img src={logo2} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} alt="logo" style={{ height: '40px', marginTop: '15px', marginLeft: '15px' }} />
                                            <h2 className="mt-3 mb-0">Verified!</h2>
                                            <p className="mb-1">Your email has been verified. Please log in to you account.</p>
                                            <div className="d-inline-block w-100">
                                                <Link to="/" className="btn btn-primary mt-3 loginBtn">
                                                    <span>Back to Login</span>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}


export default connect(mapStateToProps)(EmailVerified)