import axios from "axios";
import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import $ from 'jquery';
import { useDispatch } from "react-redux";
import { logoutUser, resetTwoFactor, setDefaultGain, twoFactorStatusChange, updateProfile } from "../../../store/actions";
import noImage from '../../../assets/images/no-image.png'

const ResellerProfile = ({ user }) => {
    const dispatch = useDispatch();
    const qr = user.user.user.qr;
    const secretKey = user.user.user.secret;
    const [twoFA, setTwoFA] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isResetCodeLoading, setIsResetCodeLoading] = useState(false);
    const [isTwoFALoading, setIsTwoFALoading] = useState(false);
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [gainType, setGainType] = useState('1');
    const [defaultGain, setGain] = useState('');
    const [defaultGainLoading, setDefaultGainLoading] = useState(false);
    const [gainError, setGainError] = useState('');
    const [twoFAChecked, setTwoFAChecked] = useState(user.user.user.user.userModel.allow_2fa);
    const [passwordData, setPasswordData] = useState({
        id: user.user.user.user.userModel.id,
        password: '',
        password_confirmation: ''
    });
    const [userName, setName] = useState('');
    const [surName, setSurName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [userEmail, setEmail] = useState('');
    const [emailVerifiedAt, setEmailVerifiedAt] = useState('');
    const [userPhone, setPhone] = useState('');
    const [userImagePreview, setUserImagePreview] = useState(null);
    const [residencePermitName, setResidencePermitName] = useState(null);
    const [companyRegistrationName, setCompanyRegistrationName] = useState(null);
    const [countryId, setCountryId] = useState('');
    const [stateId, setStateId] = useState('');
    const [city, setCity] = useState(null);
    const [zip, setZip] = useState(null);
    const [pIVANumber, setPIVANumber] = useState(null);
    const [companyRegistration, setCompanyRegistration] = useState(null);
    const [residencePermit, setResidencePermit] = useState(null);
    const [userData, setUserData] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [balance, setBalance] = useState('');

    const [errors, setErrors] = useState({});

    useEffect(() => {
        document.title = 'Account Settings | 2G Connect';
        getUserDetails();
    }, []);

    const getUserDetails = () => {
        setIsLoading(true);
        const token = user?.user?.user?.token;

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        axios.post(process.env.REACT_APP_API_URL + '/get-user-details', { userId: user.user.user.user.userModel.id }, { headers })
            .then((res) => {
                setIsLoading(false);
                if (res.data.status) {
                    setUserData(res.data.data.user);
                    setUserDetails(res.data.data.userDetails);
                }
            })
            .catch((err) => {
                console.log(err);

                if (err.response.status === 401) {
                    dispatch(logoutUser());
                }

                setIsLoading(false);
                Swal.fire('Oops!', err.response.data.message, 'error')
            });
    }

    useEffect(() => {
        setName(userData.name);
        setSurName(userData.surname);
        setCompanyName(userDetails.company_name);
        setEmail(userData.email);
        setEmailVerifiedAt(userData.email_verified_at);
        setPhone(userData.phone);
        setCountryId(userDetails?.country ? userDetails?.country.name : '');
        setStateId(userDetails?.state ? userDetails?.state.name : '');
        setCity(userDetails.city);
        setZip(userDetails.zip_code);
        setPIVANumber(userDetails.p_iva_number);
        setGainType(userDetails.gainType);
        setGain(userDetails.default_gain);
        setBalance(userDetails?.balance);
        if (userDetails.residence_permit) {
            setResidencePermitName(userDetails.residence_permit.split('/')[3]);
            setResidencePermit(userDetails.residence_permit);
        }
        if (userDetails.company_registration) {
            setCompanyRegistrationName(userDetails.company_registration.split('/')[3]);
            setCompanyRegistration(userDetails.company_registration);
        }
        if (userData.image) {
            setUserImagePreview(process.env.REACT_APP_BACKEND_URL + userData.image);
        }
    }, [userData, userDetails]);

    const passwordChangeHandler = (e) => {
        setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
    }

    const passwordUpddateHandler = (e) => {
        e.preventDefault();
        const validate = checkValidation();

        if (validate) {
            setIsChangePassword(true);
            const token = user.user.user.token;

            const headers = {
                Authorization: `Bearer ${token}`,
            }

            axios.post(process.env.REACT_APP_API_URL + '/change-password', { id: passwordData.id, password: passwordData.password, password_confirmation: passwordData.password_confirmation }, { headers })
                .then((res) => {
                    setIsChangePassword(false);
                    if (res.data.status) {
                        Swal.fire('Success!', res.data.message, 'success');
                        setPasswordData({
                            id: user.user.user.user.userModel.id,
                            password: '',
                            password_confirmation: ''
                        });
                    } else {
                        Swal.fire('Ooops!', res.data.message, 'error');
                    }
                })
                .catch((err) => {
                    setIsChangePassword(false);

                    if (err.response.status === 401) {
                        dispatch(logoutUser());
                    }

                    if (Object.keys(err.response.data.errors).length > 0) {
                        var errors = "";
                        $.each(err.response.data.errors, function (index, value) {
                            if (value.length > 1) {
                                $.each(value, function (index, error) {
                                    errors += `${error}\n`;
                                })
                            } else {
                                errors += `${value}\n`;
                            }
                        });
                        Swal.fire('Ooops!', errors, 'error');
                    } else {
                        Swal.fire('Ooops!', err.response.data.message, 'error');
                    }
                });
        }
    }

    const checkValidation = () => {
        const validationError = {};

        if (passwordData.password == null || passwordData.password == '') {
            validationError.password = 'Please enter new password';
        } else if (passwordData.password.length < 8) {
            validationError.password = 'New password must be 8 digits long';
        } else if (passwordData.oldPassword == passwordData.password) {
            validationError.password = 'The password and old password must be different.';
        }

        if (passwordData.password_confirmation == null || passwordData.password_confirmation == '') {
            validationError.password_confirmation = 'Please enter confirm password';
        } else if (passwordData.password_confirmation.length < 8) {
            validationError.password_confirmation = 'Confirm password must be 8 digits long';
        } else if (passwordData.password_confirmation !== passwordData.password) {
            validationError.password_confirmation = 'The password confirmation does not match.'
        }

        if (Object.keys(validationError).length > 0) {
            setErrors(validationError);
            return false;
        } else {
            setErrors({});
            return true;
        }
    }

    const twoFactorChangeHandler = (e) => {
        if (e.target.checked) {
            setTwoFA("ON");
            setTwoFAChecked(1);
        } else {
            setTwoFA("OFF");
            setTwoFAChecked(0);
        }
    }

    const twoFactorUpdateHandler = (e) => {
        e.preventDefault();
        if (user.user.user.user.userModel.allow_2fa !== twoFAChecked) {
            setIsTwoFALoading(true);
            const token = user.user.user.token;
            const id = user.user.user.user.userModel.id;

            const headers = {
                Authorization: `Bearer ${token}`,
            }

            axios.post(process.env.REACT_APP_API_URL + '/change-status-two-factor', { id: id, twoFA: twoFA }, { headers })
                .then((res) => {
                    setIsTwoFALoading(false);
                    if (res.data.status) {
                        dispatch(twoFactorStatusChange(res.data.data));
                        Swal.fire('Success!', res.data.message, 'success');
                    } else {
                        Swal.fire('Ooops!', res.data.message, 'error');
                    }
                })
                .catch((err) => {
                    console.log(err);

                    if (err.response.status === 401) {
                        dispatch(logoutUser());
                    }

                    setIsTwoFALoading(false);
                    Swal.fire('Ooops!', err.response.data.message, 'error');
                });
        }
    }

    const resetCodeForTwoFA = (e) => {
        e.preventDefault();
        setIsResetCodeLoading(true);

        const token = user.user.user.token;
        const id = user.user.user.user.userModel.id;

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        axios.post(process.env.REACT_APP_API_URL + '/reset-two-factor', { id: id }, { headers })
            .then((res) => {
                setIsResetCodeLoading(false);
                if (res.data.status) {
                    dispatch(resetTwoFactor(res.data.data));
                    setShowModal(true);
                } else {
                    Swal.fire('Ooops!', res.data.message, 'error');
                }
            })
            .catch((err) => {
                console.log(err);

                if (err.response.status === 401) {
                    dispatch(logoutUser());
                }

                setIsResetCodeLoading(false);
                Swal.fire('Ooops!', err.response.data.message, 'error');
            });
    }

    const modalCloseHandler = () => {
        setShowModal(false);
    }

    const gainTypeChangeHandler = (e) => {
        setGainType(e.target.value);
    }

    const defaultGainChangeHandler = (e) => {
        setGain(e.target.value);
    }

    const defaultGainSubmitHandler = (e) => {
        e.preventDefault();

        const validate = checkGainValidation();

        if (validate) {
            setDefaultGainLoading(true);
            const token = user.user.user.token;
            const id = user.user.user.user.userModel.id;

            const headers = {
                Authorization: `Bearer ${token}`,
            }

            axios.post(process.env.REACT_APP_API_URL + '/set-gain', { id: id, gain: defaultGain, gainType: gainType }, { headers })
                .then((res) => {
                    setDefaultGainLoading(false);
                    if (res.data.status) {
                        dispatch(setDefaultGain(res.data.data));
                        Swal.fire('Success!', res.data.message, 'success');
                    } else {
                        Swal.fire('Ooops!', res.data.message, 'error');
                    }
                })
                .catch((err) => {
                    console.log(err);

                    if (err.response.status === 401) {
                        dispatch(logoutUser());
                    }

                    setDefaultGainLoading(false);
                    Swal.fire('Ooops!', err.response.data.message, 'error');
                });
        }
    }

    const checkGainValidation = () => {
        if (defaultGain == null || defaultGain == '') {
            setGainError('Please enter default gain amount');
            return false;
        } else {
            return true;
        }
    }

    const downloadFile = (url, fileName) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName; // Set the desired file name
        link.target = '_blank'
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <section className="resellerProfile">
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold">Account Settings</h4>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <Card>
                            <Card.Body>
                                <div className="profile-image-box">
                                    <a href={userImagePreview ? userImagePreview : noImage} data-fancybox>
                                        <img src={userImagePreview ? userImagePreview : noImage} />
                                    </a>
                                </div>
                                <Row className="mt-4">
                                    <Col md={12} className="mt-3">
                                        <h6>Company Registration</h6>
                                        <div className="downloadFile">
                                            {companyRegistrationName ?
                                                <a onClick={() => downloadFile(process.env.REACT_APP_BACKEND_URL + companyRegistration, companyRegistrationName)}>
                                                    {companyRegistrationName}
                                                </a>
                                                : "N/A"}
                                        </div>
                                    </Col>
                                    <Col md={12} className="mt-3">
                                        <h6>Residence Permit</h6>
                                        <div className="downloadFile">
                                            {residencePermitName ?
                                                <a onClick={() => downloadFile(process.env.REACT_APP_BACKEND_URL + residencePermit, residencePermitName)}>
                                                    {residencePermitName}
                                                </a>
                                                : "N/A"}
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={7}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md={4}>
                                        <h6>Full Name</h6>
                                    </Col>
                                    <Col md={8}>
                                        <p>{userName + ' ' + surName}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <h6>Company Name</h6>
                                    </Col>
                                    <Col md={8}>
                                        <p>{companyName ? companyName : "N/A"}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <h6>Email</h6>
                                    </Col>
                                    <Col md={8}>
                                        <p>{userEmail} {emailVerifiedAt !== null && emailVerifiedAt !== '' ? <Badge className="badge badge-success text-success">Verified</Badge> : null}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <h6>Phone</h6>
                                    </Col>
                                    <Col md={8}>
                                        <p>{userPhone}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <h6>P IVA Number</h6>
                                    </Col>
                                    <Col md={8}>
                                        <p>{pIVANumber ? pIVANumber : "N/A"}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <h6>City</h6>
                                    </Col>
                                    <Col md={8}>
                                        <p>{city ? city : "N/A"}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <h6>Zip / Postal Code</h6>
                                    </Col>
                                    <Col md={8}>
                                        <p>{zip ? zip : "N/A"}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <h6>State</h6>
                                    </Col>
                                    <Col md={8}>
                                        <p>{stateId ? stateId : "N/A"}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <h6>Country</h6>
                                    </Col>
                                    <Col md={8}>
                                        <p>{countryId ? countryId : "N/A"}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <h6>Balance</h6>
                                    </Col>
                                    <Col md={8}>
                                        {balance ? <p>&euro; {balance}</p> : "N/A"}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <h6>Default Gain</h6>
                                    </Col>
                                    <Col md={8}>
                                        {gainType == 1 ? <p>{defaultGain} %</p> : (gainType == 0 ? <p>&euro; {defaultGain}</p> : "N/A")}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Account Information</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row className="mb-3">
                                    <Col md={6}><strong>ID:</strong></Col>
                                    <Col md={6} >{user.user.user.user.userModel.id}</Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={6}><strong>Balance:</strong></Col>
                                    <Col md={6}>&euro; {user.user.user.user.UserDetailModel.balance.toFixed(2)}</Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={6}><strong>Limit per Transaction:</strong></Col>
                                    <Col md={6}>&euro; {user.user.user.user.UserDetailModel.limit_per_transaction}</Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={6}><strong>Daily Transaction Limit:</strong></Col>
                                    <Col md={6}>&euro; {user.user.user.user.UserDetailModel.daily_transaction_limit}</Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={6}><strong>Default gain:</strong></Col>
                                    <Col md={6}>{user.user.user.user.UserDetailModel.default_gain} {user.user.user.user.UserDetailModel.gainType == 1 ? '%' : <span>&euro;</span>}</Col>
                                </Row>
                                <Row className="align-items-center mb-3">
                                    <Col md={3}>
                                        <p><strong>Set gain</strong></p>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Control as={'select'} name="gainType" value={gainType} onChange={gainTypeChangeHandler}>
                                            <option value={1}>%</option>
                                            <option value={0}>&euro;</option>
                                        </Form.Control>
                                    </Col>
                                    <Col md={5}>
                                        <Form.Control type="number" name="defaultGain" className={gainError ? 'is-invalid' : ''} value={defaultGain} onChange={defaultGainChangeHandler} />
                                        {gainError ? <div className="invalid-feedback">{gainError}</div> : null}
                                    </Col>
                                </Row>
                                {defaultGainLoading ?
                                    <div className="loader-box float-right">
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div> :
                                    <Button type="button" className="float-right" variant="btn btn-primary mt-3 loginBtn" onClick={defaultGainSubmitHandler}>Confirm</Button>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title>Setup 2-Factor Authentication</Card.Title>
                                        {isResetCodeLoading ?
                                            <div className="loader-box">
                                                <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            </div> :
                                            <Button type="button" variant="btn btn-primary mt-2 loginBtn" onClick={resetCodeForTwoFA}>Reset Code</Button>
                                        }
                                    </Card.Header>
                                    <Card.Body>
                                        <p>You have to download <strong>Google Authenticator</strong> from <strong>Playstore</strong> or <strong>Appstore</strong> in your mobile phone.</p>
                                        <div className="custom-control custom-switch custom-control-inline">
                                            <Form.Check.Input bsPrefix="custom-control-input" id="customSwitch2" defaultChecked={user.user.user.user.userModel.allow_2fa ? true : false} onChange={twoFactorChangeHandler} />
                                            <Form.Check.Label bsPrefix="custom-control-label" htmlFor="customSwitch2">Enable</Form.Check.Label>
                                        </div>
                                        {isTwoFALoading ?
                                            <div className="loader-box float-right">
                                                <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            </div> :
                                            <Button type="button" className="float-right" onClick={twoFactorUpdateHandler} variant="btn btn-primary mt-2 loginBtn">Update</Button>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={12}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title>Change Password</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        {/* <Row className="align-items-center">
                                            <Col md={3}>
                                                <p><strong>Old Password</strong></p>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control name="oldPassword" className={errors.oldPassword ? 'is-invalid' : ''} onChange={passwordChangeHandler} value={passwordData.oldPassword} placeholder="Enter Old Password" />
                                                {errors.oldPassword ? <div className="invalid-feedback">{errors.oldPassword}</div> : null}
                                            </Col>
                                        </Row> */}
                                        <Row className="align-items-center mt-1">
                                            <Col md={3}>
                                                <p><strong>New Password</strong></p>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control name="password" type="password" className={errors.password ? 'is-invalid' : ''} onChange={passwordChangeHandler} value={passwordData.password} placeholder="Enter New Password" />
                                                {errors.password ? <div className="invalid-feedback">{errors.password}</div> : null}
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center mt-1">
                                            <Col md={3}>
                                                <p><strong>Confirm Password</strong></p>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control name="password_confirmation" type="password" className={errors.password_confirmation ? 'is-invalid' : ''} onChange={passwordChangeHandler} value={passwordData.password_confirmation} placeholder="Enter Confirm Password" />
                                                {errors.password_confirmation ? <div className="invalid-feedback">{errors.password_confirmation}</div> : null}
                                            </Col>
                                        </Row>
                                        {isChangePassword ?
                                            <div className="loader-box float-right">
                                                <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            </div> :
                                            <Button type="button" className="float-right" onClick={passwordUpddateHandler} variant="btn btn-primary mt-2 loginBtn">Update</Button>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Modal show={showModal} onHide={modalCloseHandler}>
                <Modal.Header>
                    <Modal.Title as="h5">Scan QR Code</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={modalCloseHandler}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Row className="align-items-center justify-content-center h-100">
                        <Col md={12}>
                            <Card>
                                <h4 className="card-heading text-center mt-4">Set up Google Authenticator</h4>
                                <Card.Body className="card-body" style={{ textAlign: 'center' }}>
                                    <p>Set up your two factor authentication by scanning the QR Code below. Alternatively, you can use the code: <strong>{secretKey}</strong></p>
                                    <div dangerouslySetInnerHTML={{ __html: qr }}></div>
                                    <p>You have to download <strong>Google Authenticator</strong> from <strong>Playstore</strong> or <strong>Appstore</strong> in your mobile phone.</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="loginBtn" onClick={modalCloseHandler}>Close</Button>
                </Modal.Footer>
            </Modal>
        </section>
    )
}

export default ResellerProfile;