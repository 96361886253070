import { createStore, combineReducers } from 'redux';
import Mode from './mode/index'; // Import your mode reducer
import loginReducer from './mode/loginReducer'; // Import your login reducer

// Load state from local storage if available
const persistedState = JSON.parse(localStorage.getItem('reduxState')) || {};

// Combine your reducers
const rootReducer = combineReducers({
    mode: Mode,
    login: loginReducer,
});

// Create the store
const store = createStore(rootReducer, persistedState);

// Subscribe to store updates and save state to local storage
store.subscribe(() => {
    const state = store.getState();
    localStorage.setItem('reduxState', JSON.stringify(state));
});

export default store;
