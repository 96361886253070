import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Spinner, Badge } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link, useHistory } from 'react-router-dom'
import Card from '../../../components/Card';
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import './tablesearch.css';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../store/actions';


const RechargeRequest = ({ user }) => {
    const [pendingTransactions, setPendingTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [transactionAmount, setTransactionAmount] = useState(true);
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        getPendingRechargeRequest();
    }, []);

    useEffect(() => {
        // Initialize the DataTable when categories change
        if (!isLoading && pendingTransactions.length > 0) {
            if (!$.fn.DataTable.isDataTable(dataTableRef.current)) {
                // If not initialized, initialize the DataTable
                $(dataTableRef.current).DataTable({
                    columns: [

                        { data: 'id', title: 'ID' },
                        { data: 'Reseller', title: 'Reseller' },
                        { data: 'Phone', title: 'Phone' },
                        { data: 'Company', title: 'Company' },
                        { data: 'Amount', title: 'Amount' },
                        { data: 'Balance', title: 'Balance' },
                        { data: 'Status', title: 'Status' },
                        { data: '', title: 'Action' },
                    ],
                    order: [[0, 'desc']],
                });
            }
        }

        $(dataTableRef.current).on('click', '.status-button', function () {
            const transactionId = $(this).data('transaction-id');
            const transactionAmount = $(this).data('transaction-amount');
            changeUserStatus(transactionId, transactionAmount);

        });
    }, [pendingTransactions, isLoading]);

    const dataTableRef = useRef(null);

    const handleRedirect = (userId) => {
        console.log(userId);
        return;
        history.push('/user-details/' + btoa(userId));
    };

    const removeTableRow = (transactionId) => {
        const dataTable = $(dataTableRef.current).DataTable();

        // Find the row to remove based on data-row-id attribute
        const rowToRemove = dataTable.row(`[data-row-id="${transactionId}"]`).node();

        // Remove the row and redraw the table
        dataTable.row(rowToRemove).remove().draw();
    }

    const getPendingRechargeRequest = async () => {
        const token = user.user.user.token;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        await axios.get(process.env.REACT_APP_API_URL + '/resellers/pending-requests', { headers })
            .then((response) => {
                setIsLoading(false);
                setPendingTransactions(response.data.transactionData);
            })
            .catch((error) => {
                console.error('Error fetching users:', error);

                if (error.response.status === 401) {
                    dispatch(logoutUser());
                }

                setIsLoading(false);
            });
    };

    const changeUserStatus = (transactionId, transactionAmount) => {
        const token = user.user.user.token;
        setTransactionAmount(transactionAmount);
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        const statusOptions = ['Approve', 'Reject'];

        Swal.fire({
            title: 'Select the status for this recharge',
            text: 'Select the status for this recharge:',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Submit',
            html: `
            <div style="display: flex; flex-direction: column;">
            <label for="statusSelect" style="margin-bottom: 8px;">Status:</label>
            <select class="swal2-select" id="statusSelect" style="margin-bottom: 16px;">
                ${statusOptions.map((option) => `<option value="${option}">${option}</option>`).join('')}
            </select>

            <label for="amountInput" style="margin-bottom: 8px;">Transaction Amount:</label>
            <input type="number" class="swal2-input" id="amountInput" value="${transactionAmount}" placeholder="Enter transaction amount" style="margin-bottom: 16px;" />
        </div>
        `,
            preConfirm: () => {
                // Get the selected status and updated transaction amount from the form
                const selectedStatus = document.getElementById('statusSelect').value;
                const updatedTransactionAmount = document.getElementById('amountInput').value;

                // Use the updatedTransactionAmount in the Axios request
                return {
                    transactionId,
                    selectedStatus,
                    updatedTransactionAmount,
                };
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Get the selected status from the dropdown
                const { transactionId, selectedStatus, updatedTransactionAmount } = result.value;

                axios
                    .post(
                        process.env.REACT_APP_API_URL + '/resellers/approve-reject-pending-request',
                        { id: transactionId, status: selectedStatus, transactionAmount: updatedTransactionAmount },
                        { headers }
                    )
                    .then((response) => {
                        if (response.status) {
                            Swal.fire({
                                title: "Done",
                                text: "The recharge has been approved, and the record will now be moved to the transaction list.",
                                icon: "info",
                                showCancelButton: false,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Ok!"
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    removeTableRow(transactionId);
                                }
                            });

                        }
                    })
                    .catch((error) => {
                        console.error('There is an error in the process of approving/rejecting this request:', error);

                        if (error.response.status === 401) {
                            dispatch(logoutUser());
                        }

                        setIsLoading(false);
                    });
            }
        });
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return 'badge badge-primary'; // Bootstrap primary color (Blue)
            case 'completed':
                return 'badge badge-success'; // Bootstrap success color (Green)
            case 'declined':
                return 'badge badge-danger'; // Bootstrap danger color (Red)
            case 'refunded':
                return 'badge badge-warning'; // Bootstrap warning color (Orange)
            case 'partially refunded':
                return 'badge badge-warning'; // Bootstrap warning color (Orange) or choose another class as needed
            default:
                return 'badge badge-dark'; // Bootstrap dark color (Fallback color)
        }
    };

    const getStatusName = (status) => {
        let statusText;
        switch (status) {
            case 'pending':
                statusText = 'Pending';
                break;
            case 'completed':
                statusText = 'Completed';
                break;
            case 'declined':
                statusText = 'Declined';
                break;
            case 'refunded':
                statusText = 'Refunded';
                break;
            case 'partially refunded':
                statusText = 'Partially Refunded';
                break;
            default:
                statusText = 'Unknown Status';
        }
        return statusText;
    };

    return (
        <Container fluid>
            <Row>
                <Col lg="12">
                    <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                        <div className="d-flex align-items-center justify-content-between">
                            <h4 className="font-weight-bold">Pending Recharge Request</h4>
                        </div>
                    </div>
                    <Row>
                        <Col lg="12">
                            <Card className="card-block card-stretch">
                                <Card.Body className="p-0">
                                    <div className="d-flex justify-content-between align-items-center p-3">
                                        <h5 className="font-weight-bold">Reseller Pending Recharge Requests</h5>
                                    </div>
                                    <div className="table-responsive">
                                        {isLoading ? (
                                            <div className="text-center mb-5">
                                                <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            </div>
                                        ) : pendingTransactions.length > 0 ? (
                                            <table ref={dataTableRef} className="table data-table mb-0">
                                                <thead className="table-color-heading text-left">
                                                    <tr>
                                                        <th scope="col">Id</th>
                                                        <th scope="col">Reseller</th>
                                                        <th scope="col">Phone</th>
                                                        <th scope="col">Company</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Balance</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pendingTransactions.map((transaction, index) => (
                                                        <tr key={index} className="white-space-no-wrap" data-row-id={transaction.id}>
                                                            <td>{transaction.id}</td>
                                                            <td className="">
                                                                <div className="active-project-1 d-flex align-items-center mt-0">
                                                                    <div className="data-content">
                                                                        <div>
                                                                            <span className="font-weight-bold">{transaction.user.name}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{transaction.user.phone}</td>
                                                            <td>{transaction.user.user_details.company_name}</td>
                                                            <td>&euro; {transaction.transaction_amount}</td>
                                                            <td>&euro; {transaction.user.user_details.balance.toFixed(2)}</td>
                                                            <td>

                                                                <Badge pill className={`${getStatusColor(transaction.transaction_status)}`}>
                                                                    {getStatusName(transaction.transaction_status)}
                                                                </Badge>

                                                            </td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    {/* Change Status */}
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Change Status</Tooltip>} >
                                                                        <div style={{ cursor: 'pointer' }} >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="20"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                strokeWidth={1.5}
                                                                                data-transaction-id={transaction.id} data-transaction-amount={transaction.transaction_amount}
                                                                                stroke="currentColor"
                                                                                className="w-6 h-6 status-button"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </OverlayTrigger>

                                                                    {/* Wallet View */}
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>View Wallet</Tooltip>}>
                                                                        <Link className="" to={`/reseller/wallet-view/${transaction.user_id}`}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 14V6a2 2 0 00-2-2H6a2 2 0 00-2 2v8m16 0h2m0 0h-2m0 0h-2m0 0h2m-2 0a4 4 0 01-4 4H6a4 4 0 01-4-4M4 10h16m4 0a6 6 0 01-6 6H8a6 6 0 01-6-6m16 0v4m0 0a2 2 0 01-2 2H6a2 2 0 01-2-2m0 0a6 6 0 016-6h4a6 6 0 016 6z"></path>
                                                                            </svg>

                                                                        </Link>
                                                                    </OverlayTrigger>
                                                                    {/* Reseller Profile */}
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>View Profile</Tooltip>} >
                                                                        <Link to={`/user-details/${btoa(transaction.user_id)}`}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 2c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3zM12 14c-2.598 0-8 1.334-8 4v2c0 1.105.895 2 2 2h12c1.105 0 2-.895 2-2v-2c0-2.666-5.402-4-8-4z"></path>
                                                                            </svg>
                                                                        </Link>

                                                                    </OverlayTrigger>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div className="text-center mb-5">
                                                <p>Pending request not available.</p>
                                            </div>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default RechargeRequest;
