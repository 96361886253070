import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import './tablesearch.css';

const Orders = [
    {
        id: 'OR-325548',
        date: '01 Jan 2021',
        cust: 'Jack Ryan',
        total: '$104.98',
        status: 'Completed',
        color: 'text-success'
    },
    {
        id: 'OR-223544',
        date: '02 Jan 2021',
        cust: 'Matt Damon',
        total: '$99.00',
        status: 'Completed',
        color: 'text-success'
    },
    {
        id: 'OR-125623',
        date: '02 Jan 2021',
        cust: 'Caitriona Balfe',
        total: '$249.49',
        status: 'Pending',
        color: 'text-warning'
    },
    {
        id: 'OR-662210',
        date: '02 Jan 2021',
        cust: 'Josh Lucas',
        total: '$9.99',
        status: 'Completed',
        color: 'text-success'
    },
    {
        id: 'OR-901020',
        date: '03 Jan 2021',
        cust: 'Jon Bernthal',
        total: '$90.49',
        status: 'Cancelled',
        color: 'text-danger'
    },
    {
        id: 'OR-902210',
        date: '05 Jan 2021',
        cust: 'Noah Jupe',
        total: '$39.99',
        status: 'Completed',
        color: 'text-success'
    },
    {
        id: 'OR-902559',
        date: '06 Jan 2021',
        cust: 'Tracy Letts',
        total: '$19.22',
        status: 'Pending',
        color: 'text-warning'
    },
    {
        id: 'OR-253524',
        date: '09 Jan 2021',
        cust: 'Ray McKinnon',
        total: '$102.9',
        status: 'Cancelled',
        color: 'text-danger'
    },
    {
        id: 'OR-902559',
        date: '10 Jan 2021',
        cust: 'Remo Girone',
        total: '$13.99',
        status: 'Completed',
        color: 'text-success'
    },
    {
        id: 'OR-302240',
        date: '	12 Jan 2021',
        cust: 'Jack McMullen',
        total: '$25.99',
        status: 'Completed',
        color: 'text-success'
    },

]


const Order = ({ user }) => {

    const [Orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const token = user.user.user.token

    useEffect(() => {
        fetchOrders();
    }, []);
    useEffect(() => {

        // Initialize the DataTable when categories change
        if (!isLoading && Orders.length > 0) {
            if (!$.fn.DataTable.isDataTable(dataTableRef.current)) {
                // If not initialized, initialize the DataTable
                $(dataTableRef.current).DataTable({
                    columns: [
                        { data: 'id', title: 'ID' },
                        { data: 'order_number', title: 'Order Number' },
                        { data: 'date', title: 'Date' },
                        { data: 'customer', title: 'Customer' },
                        { data: 'total', title: 'Total' },
                        { data: 'status', title: 'Status' },
                        { data: '', title: 'Action' },
                    ],
                    order: [[0, 'desc']],
                });
            }
        }

        $(dataTableRef.current).on('click', '.delete-button', function () {
            const orderId = $(this).data('order-id');
            handleDelete(orderId);
        });
    }, [Orders, isLoading]);
    const dataTableRef = useRef(null);
    const fetchOrders = () => {

        axios.get(process.env.REACT_APP_API_URL + '/companies/' + user.user.user.companyDetails.id + '/orders', {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response) => {
                const fetchedOrders = response.data;
                if (fetchedOrders.status) {
                    setOrders(fetchedOrders.orders);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.error('Error fetching products:', error);
                setIsLoading(false);
            }).finally(() => {
                setIsLoading(false); // Set isLoading to false after fetching (success or error)
            });
    };
    const handleDelete = (orderId) => {
        Swal.fire({
            title: 'Delete Order',
            text: 'Are you sure you want to delete this order?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it',
        }).then((result) => {
            if (result.isConfirmed) {

                axios
                    .delete(process.env.REACT_APP_API_URL + `/companies/${user.user.user.companyDetails.id}/orders/${orderId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((response) => {
                        if (response.data.status) {

                            Swal.fire('Done!', response.data.message, 'success').then(() => {
                                setOrders(prevOrders => prevOrders.filter(order => order.id !== orderId));
                            });
                        } else {
                            Swal.fire('Ooopsss!', response.data.message, 'error');
                        }
                    })
                    .catch((error) => {
                        Swal.fire('Ooopsss!', error.response.data.message, 'error');
                    });
            }
        });
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return '#f39c12'; // Yellow
            case 'processing':
                return '#3498db'; // Blue
            case 'completed':
                return '#3cb72c'; // Green
            case 'canceled':
                return '#e74c3c'; // Red
            default:
                return '#000000'; // Black (Fallback color)
        }
    };

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return (
        <>

            <Container fluid>
                <Row>

                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold">All Orders</h4>
                            </div>
                            <div className="create-workform">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="modal-product-search d-flex">

                                        <Link to="/orders-new" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                            Add Order
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Row>
                            <Col lg="12">
                                <Card className="card-block card-stretch">
                                    <Card.Body className="p-0">
                                        <div className="d-flex justify-content-between align-items-center p-3">
                                            <h5 className="font-weight-bold">Orders List</h5>

                                        </div>
                                        <div className="table-responsive">
                                            {isLoading ? (
                                                <div className="text-center mb-5">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <table ref={dataTableRef} className="table data-table mb-0">
                                                    <thead className="table-color-heading">
                                                        <tr className="">

                                                            <th scope="col">
                                                                <label className="text-muted m-0" >ID</label>
                                                                {/* <input type="text" className="form-control mt-2" id="text1" aria-describedby="textHelp" placeholder="ID"/>  */}
                                                            </th>
                                                            <th scope="col" className="dates">
                                                                <label className="text-muted mb-0" >Order Number</label>
                                                                {/* <input type="text" className="form-control" id="usr1" name="event_date" placeholder="Enter Date" autocomplete="off" aria-describedby="basic-addon2"/> */}
                                                            </th>
                                                            <th scope="col" className="dates">
                                                                <label className="text-muted mb-0" >Date</label>
                                                                {/* <input type="text" className="form-control" id="usr1" name="event_date" placeholder="Enter Date" autocomplete="off" aria-describedby="basic-addon2"/> */}
                                                            </th>
                                                            <th scope="col">
                                                                <label className="text-muted mb-0">Customer</label>
                                                                {/* <input type="text" className="form-control mt-2" id="text2" aria-describedby="textHelp" placeholder="Customer"/> */}
                                                            </th>
                                                            <th scope="col" className="">
                                                                <label className="text-muted mb-0">Total</label>
                                                                {/* <input type="text" className="form-control mt-2" id="text3" aria-describedby="textHelp" placeholder="Total"/>  */}
                                                            </th>
                                                            <th scope="col">
                                                                <label className="text-muted mb-0" >Status</label>

                                                            </th>
                                                            <th scope="col" className="text-center">
                                                                <span className="text-muted" >Action</span>
                                                                {/* <p className="text-muted"></p>  */}
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            Orders.map((item) => (
                                                                <tr key={item.id} className="white-space-no-wrap">

                                                                    <td>
                                                                        {item.id}
                                                                    </td>
                                                                    <td>
                                                                        {item.number}
                                                                    </td>
                                                                    <td>{item.date}</td>
                                                                    <td>
                                                                        {item.customer.name}
                                                                    </td>
                                                                    <td className="">
                                                                        {item.currency.symbol}{item.total_amount}
                                                                    </td>
                                                                    <td>
                                                                        <p className={`mb-0 ${getStatusColor(item.status)} font-weight-bold d-flex justify-content-start align-items-center`}>
                                                                            <small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                                                                <circle cx="12" cy="12" r="8" fill={getStatusColor(item.status)}></circle></svg>
                                                                            </small>{capitalizeFirstLetter(item.status)}
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                                                                <Link className="" to={`/orders-edit/${item.id}`}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                                                    </svg>
                                                                                </Link>
                                                                            </OverlayTrigger>
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>}>
                                                                                <Link className="" to={`/orders-details/${item.id}`}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                                                    </svg>
                                                                                </Link>
                                                                            </OverlayTrigger>

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))

                                                        }
                                                    </tbody>

                                                </table>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Order;
