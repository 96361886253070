import React from 'react';
// Partials
import HeaderStyle1 from '../../components/partials/backend/HeaderStyle/HeaderStyle1';
import SidebarStyle from '../../components/partials/backend/SidebarStyle/SidebarStyle'
import FooterStyle from '../../components/partials/backend/FooterStyle/FooterStyle'


// Router Component
import Layout1Route from '../../router/layout1-route'


const Layout1 = (user) => {
    return (
        <>
            <div className="wrapper">
                <HeaderStyle1 user={user} />
                <SidebarStyle user={user} />
                <div className="content-page">
                    {/* {user.user.companyDetails.publishableKey == "" ? (
                        <div className="alert alert-danger" role="alert">
                            <div className="iq-alert-text"><b>Note:</b> Your payment setup is still pending please update <Link to="/user-profile-edit">here</Link> details to accept payment online!</div>
                        </div>
                    ) : null} */}
                    <Layout1Route user={user} />
                </div>
            </div>
            <FooterStyle />
        </>
    )
}


export default Layout1;