const initialState = {
    isLoggedIn: false,
    user: null,
};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload
            };
        case 'LOGIN_SUCCESS_WITH_2FA':
            return {
                ...state,
                user: action.payload
            };
        case 'LOGOUT':
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case 'REGISTER_SUCCESS':
            return {
                ...state
            };
        case 'TWO_FACTOR_STATUS_CHANGE_SUCCESS':
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload,
            }
        case 'RESET_TWO_FACTOR_SUCCESS':
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload,
            }
        case 'SET_DEFAULT_GAIN_SUCCESS':
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload,
            }
        case 'UPDATE_PROFILE_SUCCESS':
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload,
            }
        case 'UPDATE_ADMIN_PROFILE_SUCCESS':
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload,
            }
        case 'VERIFY_OTP':
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload,
            }
        case 'UPDATE_USER_STATE':
            return {
                ...state,
                isLoggedIn: true,
                user: { ...state.user, user: action.payload }
            }
        default:
            return state;
    }
};

export default loginReducer;
