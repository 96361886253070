import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Card from '../../../components/Card';
import { Link, useHistory } from 'react-router-dom';
import { ChromePicker } from 'react-color';
import './colorPicker.css';
import Swal from 'sweetalert2';
import axios from 'axios'; // Import Axios
import './custome-select.css'

const Companiesadd = ({ user }) => {
    let history = useHistory();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [colorThem, setColorThem] = useState('#000000');
    const [logo, setLogo] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userImage, setUserImage] = useState(null);
    const [userImagePreview, setUserImagePreview] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [publishableKey, setPublishableKey] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const token = user.user.user.token;

    useEffect(() => {

        fetchCurrencyOptions();
    }, []);

    const fetchCurrencyOptions = () => {
        axios.get(process.env.REACT_APP_API_URL + '/currencies', {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response) => {
                const fetchedCurrencies = response.data;
                setCurrencyOptions(fetchedCurrencies.currencies);
            })
            .catch((error) => {
                console.error('Error fetching currencies:', error);
            });
    };


    const toggleColorPicker = () => {
        setShowColorPicker(!showColorPicker);
    };

    const closeColorPicker = () => {
        setShowColorPicker(false);
    };

    const handleColorChange = (color) => {
        setColorThem(color.hex);
    };

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setLogo(file);
            setLogoPreview(URL.createObjectURL(file));
        }
    };

    const handleImageChange = (event) => {
        const selectedUserImage = event.target.files[0];
        if (selectedUserImage) {
            setUserImage(selectedUserImage);
            setUserImagePreview(URL.createObjectURL(selectedUserImage));
        }
    };
    const validateForm = () => {
        const errors = {};
        if (!name) {
            errors.name = 'Name is required';
        }
        if (!email) {
            errors.email = 'Email is required';
        }
        if (!phone) {
            errors.phone = 'Phone is required';
        }
        if (!selectedCurrency) {
            errors.selectedCurrency = 'Currency is required';
        }
        if (!userName) {
            errors.userName = 'User Name is required';
        }
        if (!userEmail) {
            errors.userEmail = 'User Email is required';
        }
        if (!userPhone) {
            errors.userPhone = 'User Phone is required';
        }
        if (!logo) {
            errors.logo = 'Logo is required';
        }
        if (!userImage) {
            errors.userImage = 'User Image is required';
        }
        if (!userPassword) {
            errors.userPassword = 'Password is required';
        } else if (userPassword.length < 8) {
            errors.userPassword = 'Password must be at least 8 characters long';
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSave = async () => {
        if (!validateForm()) {
            return;
        }
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('currencyId', selectedCurrency);
        formData.append('theme_color', colorThem);
        formData.append('userName', userName);
        formData.append('userEmail', userEmail);
        formData.append('userPhone', userPhone);
        formData.append('userPassword', userPassword);
        formData.append('publishableKey', publishableKey);
        formData.append('secretKey', secretKey);

        if (logo) {
            formData.append('company_logo', logo);
        }
        if (userImage) {
            formData.append('userImage', userImage);
        }
        try {
            setIsSaving(true);
            const response = await axios.post(process.env.REACT_APP_API_URL + '/company', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            );

            if (response.data.status) {
                Swal.fire('Done!', response.data.message, 'success').then(() => {
                    // Handle the success response
                    history.push('/companies');
                });
            } else {
                Swal.fire('Oops!', response.data.message, 'error');
            }
        } catch (error) {
            Swal.fire('Oops!', error.message, 'error')
            // Handle error scenarios
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/companies">Companies</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Edit Companies</li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/companies" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold d-flex align-items-center">Edit Company</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md="3" className="mb-3">
                                        <Card.Body className="rounded bg-light">
                                            <div className="d-flex justify-content-center">
                                                {validationErrors.logo && (
                                                    <div className="text-danger">{validationErrors.logo}</div>
                                                )}
                                                {logoPreview && (
                                                    <img src={logoPreview} className="img-fluid" alt="company logo" />
                                                )}
                                            </div>
                                            <div className="d-flex justify-content-center mt-2 mb-3">
                                                <label htmlFor="imageUpload" className="mb-0 text-muted font-weight-bold upload-label" style={{ cursor: 'pointer' }}>
                                                    Upload Image
                                                </label>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    id="imageUpload"
                                                    onChange={handleLogoChange}
                                                    style={{ display: 'none' }}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Col>
                                    <Col md="9">
                                        <Form className="row g-3 date-icon-set-modal">
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text1" className="font-weight-bold text-muted text-uppercase">
                                                    Name
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Text1"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                                {validationErrors.name && (
                                                    <div className="text-danger">{validationErrors.name}</div>
                                                )}
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text4" className="font-weight-bold text-muted text-uppercase">
                                                    Email
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Text4"
                                                    placeholder="Enter Email"
                                                    name="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                                {validationErrors.email && (
                                                    <div className="text-danger">{validationErrors.email}</div>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text5" className="font-weight-bold text-muted text-uppercase">
                                                    Phone
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Text5"
                                                    placeholder="Enter Phone"
                                                    name="phone"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                />
                                                {validationErrors.phone && (
                                                    <div className="text-danger">{validationErrors.phone}</div>
                                                )}

                                            </div>
                                            <div className="col-6 mb-3">
                                                <Form.Label className="form-label font-weight-bold text-muted text-uppercase">Currency</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    className="form-select"
                                                    value={selectedCurrency}
                                                    onChange={(e) => setSelectedCurrency(e.target.value)}
                                                >
                                                    <option value="">Select Currency</option>
                                                    {currencyOptions.map((currency) => (
                                                        <option key={currency.id} value={currency.id}>
                                                            {currency.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="inputState" className="font-weight-bold text-muted text-uppercase">
                                                    Theme Color
                                                </Form.Label>
                                                <div className="input-group my-colorpicker2">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={colorThem}

                                                        onChange={(e) => setColorThem(e.target.value)}
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text" style={{ cursor: 'pointer' }} onClick={toggleColorPicker}>
                                                            <i className="fas fa-square" style={{ color: colorThem }} />
                                                        </span>
                                                    </div>
                                                </div>
                                                {showColorPicker && (
                                                    <div className="color-picker-popover" onClick={closeColorPicker}>
                                                        <ChromePicker color={colorThem} onChange={handleColorChange} />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text6" className="font-weight-bold text-muted text-uppercase">Stripe Publishable Key</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Text6"
                                                    placeholder="Enter Publishable Key"
                                                    name="publishableKey"
                                                    value={publishableKey}
                                                    onChange={(e) => setPublishableKey(e.target.value)}
                                                />

                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text7" className="font-weight-bold text-muted text-uppercase">Stripe Secret Key</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Text7"
                                                    placeholder="Enter Secret Key"
                                                    name="secretKey"
                                                    value={secretKey}
                                                    onChange={(e) => setSecretKey(e.target.value)}
                                                />
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold d-flex align-items-center">Company User Detail</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md="3" className="mb-3">
                                        <Card.Body className="rounded bg-light">
                                            <div className="d-flex justify-content-center">
                                                {validationErrors.userImage && (
                                                    <div className="text-danger">{validationErrors.userImage}</div>
                                                )}
                                                {userImagePreview && (
                                                    <img src={userImagePreview} className="img-fluid" alt="user" />
                                                )}
                                            </div>
                                            <div className="d-flex justify-content-center mt-2 mb-3">
                                                <label htmlFor="userImageUpload" className="mb-0 text-muted font-weight-bold upload-label" style={{ cursor: 'pointer' }}>
                                                    Upload Image
                                                </label>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    id="userImageUpload"
                                                    onChange={handleImageChange}
                                                    style={{ display: 'none' }} // Hide the input element
                                                />

                                            </div>
                                        </Card.Body>
                                    </Col>
                                    <Col md="9">
                                        <Form className="row g-3 date-icon-set-modal">
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text1" className="font-weight-bold text-muted text-uppercase">Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Text1"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={userName}
                                                    onChange={(e) => setUserName(e.target.value)}
                                                />
                                                {validationErrors.userName && (
                                                    <div className="text-danger">{validationErrors.userName}</div>
                                                )}
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text4" className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Text4"
                                                    placeholder="Enter Email"
                                                    name="email"
                                                    value={userEmail}
                                                    onChange={(e) => setUserEmail(e.target.value)}
                                                />
                                                {validationErrors.userEmail && (
                                                    <div className="text-danger">{validationErrors.userEmail}</div>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text5" className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Text5"
                                                    placeholder="Enter Phone"
                                                    name="phone"
                                                    value={userPhone}
                                                    onChange={(e) => setUserPhone(e.target.value)}
                                                />
                                                {validationErrors.userPhone && (
                                                    <div className="text-danger">{validationErrors.userPhone}</div>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text5" className="font-weight-bold text-muted text-uppercase">Password</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    id="Text5"
                                                    placeholder="Enter Password"
                                                    name="password"
                                                    value={userPassword}
                                                    onChange={(e) => setUserPassword(e.target.value)}
                                                />
                                                {validationErrors.userPassword && (
                                                    <div className="text-danger">{validationErrors.userPassword}</div>
                                                )}
                                            </div>
                                        </Form>
                                        <div className="d-flex flex-wrap justify-content-between mt-3">
                                            <Button
                                                variant="btn btn-primary font-weight-bold btn-sm"
                                                onClick={handleSave}
                                                disabled={isSaving}
                                            >
                                                {isSaving ? 'Saving...' : 'Save Company'}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </>
    )
}

export default Companiesadd;
