import React, { useState } from 'react';
import { Container, Col, Row, Form, Button } from 'react-bootstrap';
import Card from '../../../components/Card';
import { useParams, useHistory } from 'react-router-dom'
import { connect } from "react-redux";
import { getDarkMode } from '../../../store/mode';
import axios from 'axios'; // Import axios for making API requests
import Swal from 'sweetalert2'; // Import SweetAlert for displaying alerts
import { loginSuccess } from '../../../store/actions';

//img
import logo from '../../../assets/images/logo.png';
import darklogo from '../../../assets/images/logo.png';

function mapStateToProps(state) {
    return {
        darkMode: getDarkMode(state)
    };
}

const UpdatePassword = (props) => {
    let history = useHistory();
    const { userId } = useParams();
    const [isSaving, setIsSaving] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({
        password: '',
        confirmPassword: '',
    });

    const handleSubmit = event => {
        event.preventDefault();
        const validationErrors = {};
        if (!password || password.length < 8) {
            validationErrors.password = 'Password must be at least 8 characters long';
        }

        if (password !== confirmPassword) {
            validationErrors.confirmPassword = 'Passwords do not match';
        }

        // If there are validation errors, update the state and return
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setIsSaving(true);
        // If validation passes, you can proceed with updating the password
        // Here, you can make an API request to update the password
        axios.post(process.env.REACT_APP_API_URL + '/reset-password', { userId, password })
            .then(response => {
                if (response.data.status) {
                    Swal.fire('Done!', response.data.message, 'success').then(() => {
                        // Handle the success response
                        history.push('/');
                    });
                } else {
                    setIsSaving(false);
                    Swal.fire('Oops!', response.data.message, 'error');
                }

            })
            .catch(error => {
                setIsSaving(false);
                Swal.fire('Ooops!', 'Password update failed', 'error');
            });


    };

    const handleInputChange = event => {
        const { name, value } = event.target;
        setErrors({
            ...errors,
            [name]: ''
        });

        if (name === 'password') {
            setPassword(value);
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
        }
    };

    return (
        <>
            <section className="login-content">
                <Container className="h-100">
                    <Row className="align-items-center justify-content-center h-100">
                        <Col md="5">
                            <Card className="p-3">
                                <Card.Body>
                                    <div className="auth-logo">
                                        <img src={logo} className={`img-fluid  rounded-normal  ${!props.darkMode ? 'd-none' : ''}`} alt="logo" />
                                        <img src={darklogo} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} alt="logo" />
                                    </div>
                                    <h3 className="mb-3 font-weight-bold text-center">Update Password</h3>
                                    <p className="text-center text-secondary mb-4">Enter your new password</p>

                                    <Form>
                                        <Row>

                                            <Col lg="12">
                                                <Form.Group>
                                                    <Form.Label className="text-secondary">New Password</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        name="password"
                                                        placeholder="Enter New Password"
                                                        value={password}
                                                        onChange={handleInputChange}
                                                    />
                                                    {errors.password && <div className="text-danger">{errors.password}</div>}
                                                </Form.Group>
                                            </Col>
                                            <Col lg="12">
                                                <Form.Group>
                                                    <Form.Label className="text-secondary">Confirm Password</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        name="confirmPassword"
                                                        placeholder="Confirm New Password"
                                                        value={confirmPassword}
                                                        onChange={handleInputChange}
                                                    />
                                                    {errors.confirmPassword && <div className="text-danger">{errors.confirmPassword}</div>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Button type="button"
                                            variant="btn btn-primary btn-block mt-2"
                                            onClick={handleSubmit}
                                            disabled={isSaving}
                                        >
                                            {isSaving ? 'Updating...' : 'Update Password'}
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default connect(mapStateToProps)(UpdatePassword);
