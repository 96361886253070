import React from 'react'
import ExtraPages from '../router/extra-pages-route'


const BlankLayout = (user) => {
    return (
        <>
            <div className="wrapper">
                <ExtraPages user={user} />
            </div>
        </>
    )
}

export default BlankLayout;