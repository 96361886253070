import React from 'react';
import * as XLSX from 'xlsx';

const DownloadExcel = ({ data, fileName }) => {
    const downloadExcel = () => {
        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Convert data to worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate a binary string representation of the workbook
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        // Create a Blob object from the binary string
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        // Create an object URL and download the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName + '.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <button onClick={downloadExcel} className="btn btn-primary loginBtn">Download</button>
    );
};

export default DownloadExcel;