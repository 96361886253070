import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { getDarkMode } from '../../../store/mode'
//img
import logo from '../../../assets/images/logo.png'
import darklogo from '../../../assets/images/logo.png';
import logo2 from '../../../assets/images/logo2.png';


function mapStateToProps(state) {
    return {
        darkMode: getDarkMode(state)
    };
}
const EmailVerification = (props) => {


    return (
        <>
            <section className="login-content">
                <Container fluid className="h-100">
                    <Row className="align-items-center justify-content-center h-100">
                        <Col md="5" className="col-md-5">
                            <Card>
                                <Card.Body>
                                    <Row className="align-items-center">
                                        <Col lg="12" className="text-center">
                                            <img src={logo} className={`img-fluid  rounded-normal  ${!props.darkMode ? 'd-none' : ''}`} width="80" alt="logo" />
                                            <img src={darklogo} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} width="80" alt="logo" />
                                            <img src={logo2} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} alt="logo" style={{ height: '40px', marginTop: '15px', marginLeft: '15px' }} />
                                            <h2 className="mt-3 mb-0">Verify Your Email!</h2>
                                            <p className="mb-1">An email has been sent to your provided email. Please check your
                                                email's inbox or spam folder for an email from <strong>2G Connect</strong> and click
                                                on the included link to verify your email.</p>
                                            <div className="d-inline-block w-100">
                                                <Link to="/" className="btn btn-primary mt-3 loginBtn">
                                                    <span>Back</span>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}


export default connect(mapStateToProps)(EmailVerification)