import { Switch, Route } from 'react-router-dom'
import React from 'react';

//auth pages
import ConfirmMail from '../views/backend/Auth/ConfirmMail'
import LockScreen from '../views/backend/Auth/LockScreen'
import RecoverPassword from '../views/backend/Auth/RecoverPassword'
import UpdatePassword from '../views/backend/Auth/UpdatePassword'
import SignUp from '../views/backend/Auth/SignUp'

//ExtarPages
import Maintainance from '../views/backend/pages/Maintainance'
import Error404 from '../views/backend/pages/Error/Error404'
import Error500 from '../views/backend/pages/Error/Error500'
import CommingSoon from '../views/backend/pages/CommingSoon'
import QRCode from '../views/backend/Auth/QRCode';
import OTP from '../views/backend/Auth/OTP';
import EmailVerification from '../views/backend/Auth/EmailVerification';
import EmailVerified from '../views/backend/Auth/EmailVerified';

const ExtraPages = (user) => {
    return (
        <Switch>
            {/* auth */}
            <Route path="/auth/confirm-mail" component={ConfirmMail} />
            <Route path="/auth/verify-email" component={EmailVerification} />
            <Route path="/auth/email-verification" component={EmailVerified} />
            <Route path="/auth/lock-screen" component={LockScreen} />
            <Route path="/auth/recoverpw" component={RecoverPassword} />
            {/* <Route path="/auth/sign-in" component={SignIn} /> */}
            <Route path="/auth/sign-up" component={SignUp} />
            <Route path="/auth/2FA" render={(props) => <QRCode {...props} user={user} />} />
            <Route path="/auth/otp" render={(props) => <OTP {...props} user={user} />} />
            <Route path="/auth/update-password/:userId" component={UpdatePassword} />

            {/* ExtraPages */}
            <Route path="/extra-pages/pages-error" component={Error404} />
            <Route path="/extra-pages/pages-error-500" component={Error500} />
            <Route path="/extra-pages/pages-comingsoon" component={CommingSoon} />
            <Route path="/extra-pages/pages-maintenance" component={Maintainance} />
        </Switch>
    )
}

export default ExtraPages