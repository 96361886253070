import axios from 'axios';
import React, { useState } from 'react';
import { Button, Card, Col, Container, Form, FormGroup, Row, Spinner, Table } from 'react-bootstrap';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { updateUserState } from '../../../store/actions';
import { useEffect } from 'react';

const TopUp = () => {
    const dispatch = useDispatch();
    const [number, setNumber] = useState('');
    const [fullNumber, setFullNumber] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [country, setCountry] = useState(null);
    const [showOperators, setShowOperators] = useState(false);
    const [operators, setOperators] = useState([]);
    const [selectedOperator, setSelectedOperator] = useState({});
    const [receivedAmount, setReceivedAmount] = useState('');
    const [totalAmount, setTotalAmount] = useState('');
    const [topUpAmount, setTopUpAmount] = useState('');
    const [amountExcludeAdminCommission, setAmountExcludeAdminCommission] = useState('');
    const [getOperatorLoading, setGetOperatorLoading] = useState(false);
    const [topLoading, setTopLoading] = useState(false);
    const [commission, setCommission] = useState('');
    const [numbersOperator, setNumbersOperator] = useState('N/A');
    const [operatorDetailsLoading, setOperatorDetailsLoading] = useState(false);
    const [offerId, setOfferId] = useState('');
    const userDetails = useSelector(state => state?.login?.user?.user?.UserDetailModel);

    useEffect(() => {
        setCommission(userDetails?.default_gain);
    }, [userDetails]);

    const onNumberChangeHandler = (isValid, value, selectedCountryData, fullNumber) => {
        const country = selectedCountryData.name.split('(')[0];
        setNumber(value);
        setFullNumber(fullNumber);
        setIsValid(isValid);
        setCountry(country);
    }

    const onSubmitHandler = () => {
        if (isValid) {
            setGetOperatorLoading(true);
            setReceivedAmount('');
            setTotalAmount('');
            setSelectedOperator({});
            const data = { country: country, number: fullNumber };
            axios.post(process.env.REACT_APP_API_URL + '/get-operators', data)
                .then((res) => {
                    setOperators(res?.data?.data);
                    setGetOperatorLoading(false);
                    if (res?.data?.numberDetails?.valid && res?.data?.numberDetails?.carrier !== "" && res?.data?.numberDetails?.carrier !== null) {
                        for (const operator of res?.data?.data) {
                            if (haveCommonWords(res?.data?.numberDetails?.carrier, operator?.our_name)) {
                                setNumbersOperator(operator?.our_name);
                                setSelectedOperator(operator);
                                break;
                            }
                        };
                    }
                    setShowOperators(true);
                })
                .catch((err) => {
                    setGetOperatorLoading(false);
                    console.log(err, "err");
                });
        } else {
            Swal.fire('Ooops!', 'Please enter a valid number.', 'error');
        }
    }

    const haveCommonWords = (str1, str2) => {
        // Split strings into words
        const words1 = str1.split(' ');
        const words2 = str2.split(' ');
        // Check for any common substrings
        for (let word1 of words1) {
            for (let word2 of words2) {
                if (word1.includes(word2) || word2.includes(word1)) {
                    return true; // Common substring found
                }
            }
        }

        return false; // No common substrings
    }

    const onOperatorClick = (id) => {
        setOperatorDetailsLoading(true);
        const selectOperator = operators.find(x => x.id == id);
        axios.post(process.env.REACT_APP_API_URL + '/get-operator-details', selectOperator)
            .then((res) => {
                if (res?.status) {
                    setNumbersOperator(res?.data?.data?.our_name);
                    setSelectedOperator(res?.data?.data);
                    setReceivedAmount('');
                    setTotalAmount('');
                    setTopUpAmount('');
                    setOperatorDetailsLoading(false);
                } else {
                    setOperatorDetailsLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setOperatorDetailsLoading(false);
            })
    }

    const amountChangeHandler = (e, getAmount = null, offerId = null, margin = null, originalAmount = null) => {
        if (offerId !== null && offerId !== '') {
            setOfferId(offerId);
        }
        if (getAmount !== null && getAmount !== '') {
            if (userDetails?.gainType == 1 || userDetails?.gainType == '1') {
                const topUpAmount = parseFloat(getAmount);
                const amountToBeReceived = originalAmount * parseFloat(selectedOperator?.max_value ? selectedOperator?.max_value : selectedOperator?.fx_rates);
                const amountWithCommission = parseFloat(topUpAmount) * (parseFloat(commission) / 100);
                const amountTotal = parseFloat(amountWithCommission) + parseFloat(topUpAmount);
                setReceivedAmount(amountToBeReceived.toFixed(2));
                setTopUpAmount(topUpAmount);
                setTotalAmount(amountTotal.toFixed(2));
                setAmountExcludeAdminCommission(originalAmount);
            } else {
                const topUpAmount = parseFloat(getAmount);
                const amountToBeReceived = originalAmount * parseFloat(selectedOperator?.max_value ? selectedOperator?.max_value : selectedOperator?.fx_rates);
                const amountTotal = parseFloat(commission) + parseFloat(topUpAmount);
                setReceivedAmount(amountToBeReceived.toFixed(2));
                setTopUpAmount(topUpAmount);
                setTotalAmount(amountTotal.toFixed(2));
                setAmountExcludeAdminCommission(originalAmount);
            }
        } else {
            if (e.target.value) {
                if (userDetails?.gainType == 1 || userDetails?.gainType == '1') {
                    const topUpAmount = parseFloat(e.target.value);
                    const amountAfterAdminCommission = topUpAmount - ((topUpAmount * parseFloat(margin !== null ? margin : selectedOperator?.margin) / 100));
                    const amountToBeReceived = amountAfterAdminCommission * parseFloat(selectedOperator?.max_value ? selectedOperator?.max_value : selectedOperator?.fx_rates);
                    const amountWithCommission = parseFloat(topUpAmount) * (parseFloat(commission) / 100);
                    const amountTotal = parseFloat(amountWithCommission) + parseFloat(topUpAmount);
                    setReceivedAmount(amountToBeReceived.toFixed(2));
                    setTopUpAmount(topUpAmount);
                    setTotalAmount(amountTotal.toFixed(2));
                    setAmountExcludeAdminCommission(amountAfterAdminCommission);
                } else {
                    const topUpAmount = parseFloat(e.target.value);
                    const amountAfterAdminCommission = topUpAmount - ((topUpAmount * parseFloat(margin !== null ? margin : selectedOperator?.margin) / 100));
                    const amountToBeReceived = amountAfterAdminCommission * parseFloat(selectedOperator?.max_value ? selectedOperator?.max_value : selectedOperator?.fx_rates);
                    const amountTotal = parseFloat(commission) + parseFloat(topUpAmount);
                    setReceivedAmount(amountToBeReceived.toFixed(2));
                    setTopUpAmount(topUpAmount);
                    setTotalAmount(amountTotal.toFixed(2));
                    setAmountExcludeAdminCommission(amountAfterAdminCommission);
                }
            } else {
                setReceivedAmount(0);
                setTotalAmount(0);
                setTopUpAmount(null);
            }
        }
    }

    const onTopUpSubmitHandler = () => {
        const validate = checkValidation();
        if (validate) {
            const data = {
                id: selectedOperator?.id,
                gateway_id: selectedOperator?.gateway_id,
                user_id: userDetails?.user_id,
                phone: number,
                fullNumber: fullNumber,
                totalAmount: totalAmount,
                countryCode: country,
                receivedAmount: receivedAmount,
                amountWithAdminCommission: topUpAmount,
                amount: amountExcludeAdminCommission,
                offerId: offerId
            }
            setTopLoading(true);
            axios.post(process.env.REACT_APP_API_URL + '/get-topup', data)
                .then((res) => {
                    if (res?.data?.status == 'success') {
                        setTopLoading(false);
                        Swal.fire('Done!', res.data.message, 'success').then(() => {
                            dispatch(updateUserState(res?.data?.data));
                            setReceivedAmount(0);
                            setTotalAmount(0);
                            setSelectedOperator({});
                            setNumber('');
                            setFullNumber(null);
                            setIsValid(false);
                            setCountry(null);
                            setShowOperators(false);
                        });
                    } else {
                        setTopLoading(false);
                        Swal.fire('Oops!', res.data.message, 'error');
                    }
                })
                .catch((err) => {
                    console.log(err, "err");
                    setTopLoading(false);
                    Swal.fire('Oops!', err.response.data.message, 'error');
                });
        }
    }

    const checkValidation = () => {
        if (JSON.parse(selectedOperator?.response)?.items) {
            if (amountExcludeAdminCommission < JSON.parse(selectedOperator?.response)?.items[0]?.Minimum?.SendValue) {
                Swal.fire('Ooops!', 'Please enter amount greater than or equal to ' + JSON.parse(selectedOperator?.response)?.items[0]?.Minimum?.SendValue + '.', 'error');
                return false;
            } else if (amountExcludeAdminCommission > JSON.parse(selectedOperator?.response)?.Items[0]?.Maximum?.SendValue) {
                Swal.fire('Ooops!', 'Please enter amount less than or equal to ' + JSON.parse(selectedOperator?.response)?.Items[0]?.Maximum?.SendValue + '.', 'error');
                return false;
            } else {
                if (receivedAmount > 0) {
                    if (amountExcludeAdminCommission <= userDetails?.balance) {
                        return true;
                    } else {
                        Swal.fire('Ooops!', 'You do not have enough balance in your account.', 'error');
                        return false;
                    }
                } else {
                    Swal.fire('Ooops!', 'Please enter valid amount.', 'error');
                    return false;
                }
            }
        } else if (JSON.parse(selectedOperator?.response)?.fixedAmounts?.length > 0) {
            if (JSON.parse(selectedOperator?.response)?.fixedAmounts?.includes(parseFloat(amountExcludeAdminCommission))) {
                if (receivedAmount > 0) {
                    if (parseFloat(amountExcludeAdminCommission) <= userDetails?.balance) {
                        return true;
                    } else {
                        Swal.fire('Ooops!', 'You do not have enough balance in your account.', 'error');
                        return false;
                    }
                } else {
                    Swal.fire('Ooops!', 'Please enter valid amount.', 'error');
                    return false;
                }
            } else {
                Swal.fire('Ooops!', 'Please enter a amount from these ' + JSON.parse(selectedOperator?.response)?.fixedAmounts?.map(amount => ' ' + amount) + '.', 'error');
                return false;
            }

        } else if (JSON.parse(selectedOperator?.response)?.maxAmount !== null && JSON.parse(selectedOperator?.response)?.minAmount !== null) {
            if (parseFloat(amountExcludeAdminCommission) > JSON.parse(selectedOperator?.response)?.maxAmount) {
                Swal.fire('Ooops!', 'Please enter amount less than or equal to ' + JSON.parse(selectedOperator?.response)?.maxAmount + '.', 'error');
                return false;
            } else if (parseFloat(amountExcludeAdminCommission) < JSON.parse(selectedOperator?.response)?.minAmount) {
                Swal.fire('Ooops!', 'Please enter amount greater than or equal to ' + JSON.parse(selectedOperator?.response)?.minAmount + '.', 'error');
                return false;
            } else {
                if (receivedAmount > 0) {
                    if (parseFloat(amountExcludeAdminCommission) <= userDetails?.balance) {
                        return true;
                    } else {
                        Swal.fire('Ooops!', 'You do not have enough balance in your account.', 'error');
                        return false;
                    }
                } else {
                    Swal.fire('Ooops!', 'Please enter valid amount.', 'error');
                    return false;
                }
            }
        }

        return true;
    }

    const onCommissionChangeHandler = (e) => {
        if (topUpAmount !== '' && topUpAmount !== null) {
            if (userDetails?.gainType == 1 || userDetails?.gainType == '1') {
                // const topUpAmount = e.target.value;
                const amountToBeReceived = parseFloat(topUpAmount) * parseFloat(selectedOperator?.max_value);
                const amountWithCommission = parseFloat(topUpAmount) * (parseFloat(e.target.value) / 100);
                const amountTotal = parseFloat(amountWithCommission) + parseFloat(topUpAmount);
                setReceivedAmount(amountToBeReceived.toFixed(2));
                setCommission(e.target.value);
                setTotalAmount(amountTotal.toFixed(2));
            } else {
                const amountToBeReceived = parseFloat(topUpAmount) * parseFloat(selectedOperator?.max_value);
                const amountTotal = parseFloat(e.target.value) + parseFloat(topUpAmount);
                setReceivedAmount(amountToBeReceived.toFixed(2));
                setCommission(e.target.value);
                setTotalAmount(amountTotal.toFixed(2));
            }
        } else {
            setCommission(e.target.value);
        }
    }

    const onReceivedAmountChangeHandler = (e) => {
        if (e.target.value) {
            if (userDetails?.gainType == 1 || userDetails?.gainType == '1') {
                const expectedChargeAmount = e.target.value;
                const topUpAmount = parseFloat(expectedChargeAmount) / parseFloat(selectedOperator?.max_value);
                const amountWithCommission = parseFloat(topUpAmount) * (parseFloat(commission) / 100);
                const amountTotal = parseFloat(amountWithCommission) + parseFloat(topUpAmount);
                setReceivedAmount(expectedChargeAmount);
                setTopUpAmount(topUpAmount.toFixed(2));
                setTotalAmount(amountTotal.toFixed(2));
            } else {
                const expectedChargeAmount = e.target.value;
                const topUpAmount = parseFloat(expectedChargeAmount) / parseFloat(selectedOperator?.max_value);
                const amountTotal = parseFloat(commission) + parseFloat(topUpAmount);
                setReceivedAmount(expectedChargeAmount);
                setTopUpAmount(topUpAmount.toFixed(2));
                setTotalAmount(amountTotal.toFixed(2));
            }
        } else {
            setReceivedAmount('');
            setTotalAmount('');
            setTopUpAmount('');
        }
    }

    const goBack = (e) => {
        e.preventDefault();
        setNumber('');
        setFullNumber(null);
        setIsValid(false);
        setCountry(null);
        setShowOperators(false);
        setOperators([]);
        setSelectedOperator({});
        setReceivedAmount('');
        setTotalAmount('');
        setTopUpAmount('');
        setGetOperatorLoading(false);
        setTopLoading(false);
        setCommission('');
        setNumbersOperator('N/A');
        setOperatorDetailsLoading(false);
        setOfferId('');
    }

    return (
        <section className="resellerProfile">
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold">Top Up</h4>
                            </div>
                        </div>
                    </Col>
                </Row>
                {!showOperators ?
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>
                                        Enter Mobile Number
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form className="row g-3 date-icon-set-modal">
                                        <div className="col-md-10">
                                            {/* <Form.Label htmlFor="Text1" className="font-weight-bold text-muted text-uppercase">Mobile Number</Form.Label> */}
                                            <IntlTelInput
                                                containerClassName="intl-tel-input"
                                                inputClassName="form-control"
                                                onPhoneNumberChange={onNumberChangeHandler}
                                                value={number}
                                            />
                                        </div>
                                    </Form>
                                    <Button type="button" className="float-right" variant="btn btn-primary loginBtn" onClick={onSubmitHandler}>
                                        {getOperatorLoading ? (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                {' '}Loading...
                                            </>
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> : null}
                {showOperators ?
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>
                                        Top Up Details
                                    </Card.Title>
                                    {/* <div>Your Balance: &euro; {userDetails?.balance.toFixed(2)}</div> */}
                                    <Button type="button" className="float-right mt-3" variant="btn btn-primary loginBtn" onClick={(e) => goBack(e)}>Back</Button>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col md={3}>
                                            <h4>{numbersOperator}</h4>
                                            <h5>{number}</h5>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col md={3}>
                                            <label>Choose Operator: </label>
                                        </Col>
                                        <Col md={9}>
                                            <div className='operators-main-box'>
                                                {operators?.length > 0 ? operators?.map((operator, index) => {
                                                    return (
                                                        <div className={numbersOperator === operator?.our_name ? 'operator-box active' : 'operator-box'} key={index} onClick={() => onOperatorClick(operator?.id)}>{operator?.our_name}</div>
                                                    )
                                                }) : null}
                                            </div>
                                        </Col>
                                    </Row>

                                    {operatorDetailsLoading ?
                                        <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />
                                        :
                                        Object.keys(selectedOperator).length > 0 ?
                                            selectedOperator?.gateway_id === 2 ?
                                                (JSON.parse(selectedOperator?.response).Items ? (
                                                    JSON.parse(selectedOperator?.response)?.Items[0]?.Minimum?.SendValue === JSON.parse(selectedOperator?.response)?.Items[0]?.Maximum?.SendValue ?
                                                        <Row className='mt-3'>
                                                            <Col md={3}>
                                                                <label>Choose Amount: </label>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className='amount-main-box'>
                                                                    <div onClick={(e) => amountChangeHandler(e, (JSON.parse(selectedOperator?.response)?.Items[0]?.Minimum?.SendValue + ((JSON.parse(selectedOperator?.response)?.Items[0]?.Minimum?.SendValue * parseFloat(selectedOperator?.margin)) / 100)).toFixed(2), null, null, JSON.parse(selectedOperator?.response)?.Items[0]?.Minimum?.SendValue)} className={topUpAmount == (JSON.parse(selectedOperator?.response)?.Items[0]?.Minimum?.SendValue + ((JSON.parse(selectedOperator?.response)?.Items[0]?.Minimum?.SendValue * parseFloat(selectedOperator?.margin)) / 100)).toFixed(2) ? 'amount-box active' : 'amount-box'}>{(JSON.parse(selectedOperator?.response)?.Items[0]?.Minimum?.SendValue + ((JSON.parse(selectedOperator?.response)?.Items[0]?.Minimum?.SendValue * parseFloat(selectedOperator?.margin)) / 100)).toFixed(2)} EUR</div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        <Row className='mt-3 align-items-center'>
                                                            <Col md={3}>
                                                                <label>Amount &euro; ({(JSON.parse(selectedOperator?.response)?.Items[0]?.Minimum?.SendValue + ((JSON.parse(selectedOperator?.response)?.Items[0]?.Minimum?.SendValue * parseFloat(selectedOperator?.margin)) / 100)).toFixed(2)}/{(JSON.parse(selectedOperator?.response)?.Items[0]?.Maximum?.SendValue + ((JSON.parse(selectedOperator?.response)?.Items[0]?.Maximum?.SendValue * parseFloat(selectedOperator?.margin)) / 100)).toFixed(2)}) </label>
                                                            </Col>
                                                            <Col md={9}>
                                                                <input className='form-control' type='number' onChange={(e) => amountChangeHandler(e)} value={topUpAmount} />
                                                            </Col>
                                                        </Row>
                                                ) : null)
                                                :
                                                (selectedOperator?.gateway_id === 1 ?
                                                    (selectedOperator?.response && JSON.parse(selectedOperator?.response).fixedAmounts?.length > 0 ?
                                                        <Row className='mt-3'>
                                                            <Col md={3}>
                                                                <label>Choose Amount: </label>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className='amount-main-box'>
                                                                    {JSON.parse(selectedOperator?.response)?.fixedAmounts?.map((amount, i) => {
                                                                        let exactAmount = parseFloat(amount) + ((parseFloat(amount) * parseFloat(selectedOperator?.margin)) / 100)
                                                                        return (
                                                                            <div key={i} onClick={(e) => amountChangeHandler(e, exactAmount.toFixed(2), null, null, amount)} className={topUpAmount == exactAmount.toFixed(2) ? 'amount-box active' : 'amount-box'}>{exactAmount.toFixed(2)} EUR</div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        (JSON.parse(selectedOperator?.response).minAmount !== null && JSON.parse(selectedOperator?.response).maxAmount !== null ?
                                                            <Row className='mt-3 align-items-center'>
                                                                <Col md={3}>
                                                                    <label>Amount &euro; ({(parseFloat(JSON.parse(selectedOperator?.response)?.minAmount) + ((JSON.parse(selectedOperator?.response)?.minAmount * parseFloat(selectedOperator?.margin)) / 100)).toFixed(2)}/{(JSON.parse(selectedOperator?.response)?.maxAmount + ((JSON.parse(selectedOperator?.response)?.maxAmount * parseFloat(selectedOperator?.margin)) / 100)).toFixed(2)}) </label>
                                                                </Col>
                                                                <Col md={9}>
                                                                    <input className='form-control' type='number' onChange={(e) => amountChangeHandler(e)} value={topUpAmount} />
                                                                </Col>
                                                            </Row> : null
                                                        ))
                                                    :
                                                    (selectedOperator?.gateway_id === 3 ?
                                                        JSON.parse(selectedOperator?.response)?.priceType === "RANGE" ? (
                                                            <Row className='mt-3 align-items-center'>
                                                                <Col md={3}>
                                                                    <label>Amount &euro; ({(JSON.parse(selectedOperator?.response)?.price?.min + ((JSON.parse(selectedOperator?.response)?.price?.min * parseFloat(selectedOperator?.margin)) / 100)).toFixed(2)}/{(JSON.parse(selectedOperator?.response)?.price?.max + ((JSON.parse(selectedOperator?.response)?.price?.max * parseFloat(selectedOperator?.margin)) / 100)).toFixed(2)}) </label>
                                                                </Col>
                                                                <Col md={9}>
                                                                    <input className='form-control' type='number' onChange={(e) => amountChangeHandler(e)} value={topUpAmount} />
                                                                </Col>
                                                            </Row>
                                                        ) :
                                                            <Row className='mt-3'>
                                                                <Col md={3}>
                                                                    <label>Choose Amount: </label>
                                                                </Col>
                                                                <Col md={9}>
                                                                    <div className='amount-main-box'>
                                                                        {selectedOperator?.prices?.length > 0 ? selectedOperator?.prices?.map((price, index) => {
                                                                            let exactAmount = (price?.price?.fixed + ((price?.price?.fixed * parseFloat(price?.margin)) / 100)).toFixed(2);
                                                                            return (
                                                                                <div onClick={(e) => amountChangeHandler(e, exactAmount, price?.offerId, price?.margin, price?.price?.fixed)} className={topUpAmount == (price?.price?.fixed + ((price?.price?.fixed * parseFloat(price?.margin)) / 100)).toFixed(2) ? 'amount-box active' : 'amount-box'} key={index}>{exactAmount} EUR</div>
                                                                            )
                                                                        }) : null}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        :
                                                        '')
                                                )
                                            : null}
                                    {userDetails?.gainType == 1 || userDetails?.gainType == '1' ?
                                        <Row className='mt-3 align-items-center'>
                                            <Col md={3}>
                                                <label>Commission (in Percentage %)</label>
                                            </Col>
                                            <Col md={9}>
                                                <input className='form-control' value={commission} onChange={onCommissionChangeHandler} />
                                            </Col>
                                        </Row>
                                        :
                                        <Row className='mt-3 align-items-center'>
                                            <Col md={3}>
                                                <label>Commission (in Euro &euro;)</label>
                                            </Col>
                                            <Col md={9}>
                                                <input className='form-control' value={commission} onChange={onCommissionChangeHandler} />
                                            </Col>
                                        </Row>
                                    }
                                    <Row className='mt-3 align-items-center'>
                                        <Col md={3}>
                                            <label>Total Amount (in Euro &euro;)</label>
                                        </Col>
                                        <Col md={9}>
                                            <input className='form-control' value={totalAmount} readOnly />
                                        </Col>
                                    </Row>
                                    <Row className='mt-3 align-items-center'>
                                        <Col md={3}>
                                            <label>Receiver Amount</label>
                                        </Col>
                                        <Col md={9}>
                                            <input className='form-control' value={receivedAmount} onChange={onReceivedAmountChangeHandler} />
                                        </Col>
                                    </Row>
                                    <Button type="button" className="float-right mt-3" variant="btn btn-primary loginBtn" onClick={onTopUpSubmitHandler}>
                                        {topLoading ? (
                                            <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}Loading...</>
                                        ) : ('Submit')}
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    : null
                }
            </Container>
        </section >
    );
}

export default TopUp;
