import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { useParams, Link, useHistory } from 'react-router-dom'
import axios from 'axios';


const Companiesview = ({ user }) => {
    let history = useHistory()
    const { companyId } = useParams();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [colorThem, setColorThem] = useState('');
    const [logo, setLogo] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userImage, setUserImage] = useState(null);
    const [userImagePreview, setUserImagePreview] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const token = user.user.user.token;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/company/${companyId}/edit`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                console.log(response.data);
                setName(response.data.company.name);
                setEmail(response.data.company.email);
                setPhone(response.data.company.phone);
                setColorThem(response.data.company.color_them || '#ffffff');
                setLogo(response.data.company.logo);
                if (response.data.company.logo) {
                    setLogoPreview(process.env.REACT_APP_BACKEND_URL + response.data.company.logo);
                }
                setUserName(response.data.company.userName);
                setUserEmail(response.data.company.userEmail || '');
                setUserPhone(response.data.company.userPhone || '');
                // You might not want to set the userPassword here for security reasons
                setUserImage(null); // Clear previously selected user image
                if (response.data.company.userImage) {
                    setUserImagePreview(process.env.REACT_APP_BACKEND_URL + response.data.company.userImage);
                }

            } catch (error) {
                console.error('Error fetching Company details:', error);
            }
        };

        fetchData();
    }, [companyId, token, user.user.user.companyDetails.id]);
    const getStatusColor = (status) => {
        console.log(status);
        switch (status) {

            case 'draft':
                return '#999999'; // Gray for draft status
            case 'issued':
                return '#ffeb3b'; // Orange for issued status
            case 'paid':
                return '#3cb72c'; // Green for paid status
            case 'overdue':
                return '#ff5722'; // Red for overdue status
            case 'canceled':
                return '#f44336'; // Red for canceled status
            default:
                return '#000000'; // Default color if status doesn't match any condition
        }
    };
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/companies">Companies</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Edit Companies</li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/companies" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold d-flex align-items-center">Edit Company</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md="3" className="mb-3">
                                        <Card.Body className="rounded bg-light">
                                            <div className="d-flex justify-content-center">
                                                {logoPreview && (
                                                    <img src={logoPreview} className="img-fluid" alt="company logo" />
                                                )}

                                            </div>

                                        </Card.Body>
                                    </Col>
                                    <Col md="9">
                                        <Form className="row g-3 date-icon-set-modal">
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text1" className="font-weight-bold text-muted text-uppercase">Name</Form.Label>
                                                <Form.Control type="text" id="Text1" placeholder="Name" name="name"
                                                    value={name}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text4" className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                                <Form.Control type="text" id="Text4" placeholder="Enter Email" name="email"
                                                    value={email}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text5" className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                <Form.Control type="text" id="Text5" placeholder="Enter Phone" name="phone"
                                                    value={phone} readOnly />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="inputState" className="font-weight-bold text-muted text-uppercase">Theme Color</Form.Label>
                                                <div className="input-group my-colorpicker2">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={colorThem}
                                                        readOnly
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">
                                                            <i className="fas fa-square" style={{ color: colorThem }} />
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </Form>

                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>

                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold d-flex align-items-center">Company User Detail</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md="3" className="mb-3">
                                        <Card.Body className="rounded bg-light">
                                            <div className="d-flex justify-content-center">
                                                {userImagePreview && (
                                                    <img src={userImagePreview} className="img-fluid" alt="user image" />
                                                )}

                                            </div>
                                        </Card.Body>
                                    </Col>
                                    <Col md="9">
                                        <Form className="row g-3 date-icon-set-modal">
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text1" className="font-weight-bold text-muted text-uppercase">Name</Form.Label>
                                                <Form.Control type="text" id="Text1" placeholder="Name" name="name" readOnly
                                                    value={userName}
                                                />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text4" className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                                <Form.Control type="text" id="Text4" placeholder="Enter Email" name="email" readOnly
                                                    value={userEmail}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text5" className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                <Form.Control type="text" id="Text5" placeholder="Enter Phone" name="phone" readOnly
                                                    value={userPhone} />
                                            </div>

                                        </Form>

                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Companiesview;