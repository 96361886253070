import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import Swal from 'sweetalert2';
import $ from 'jquery';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, FontColor, Heading, Image, FontBackgroundColor, Underline, FontSize, FontFamily, FONT_SIZE, FontSizeCommand, Link, ImageUpload, FileUploader, Strikethrough, Superscript, Indent, Subscript, Alignment, List, BlockQuote, CodeBlock } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

const SupportInfo = ({ user }) => {

    const dataTableRef = useRef(null);
    const paymentLinkDataTableRef = useRef(null);
    const representativeDataTableRef = useRef(null);
    const editorRef = useRef(null);
    const editorEditRef = useRef(null);
    const [isAddBankLoading, setIsAddBankLoading] = useState(false);
    const [isEditBankLoading, setIsEditBankLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isPaymentLinkLoading, setIsPaymentLinkLoading] = useState(false);
    const [isRepresentativeListsLoading, setIsRepresentativeListsLoading] = useState(false);
    const token = user?.user?.user?.token;
    const [show, setShow] = useState(false);
    const [bankDetailsShow, setBankDetailsShow] = useState(false);
    const [editBankDetailsModalShow, setEditBankDetailsModalShow] = useState(false);
    const [errors, setErrors] = useState({});
    const [selectedBank, setSelectedBank] = useState({});
    const [editBank, setEditBank] = useState({});
    const [editPaymentLink, setEditPaymentLink] = useState({});
    const [editRepresentative, setEditRepresentative] = useState({});
    const [banks, setBanks] = useState([]);
    const [paymentLinks, setPaymentLinks] = useState([]);
    const [countries, setCountries] = useState([]);
    const [showGenericInfoModal, setShowGenericInfoModal] = useState(false);
    const [showGenericInfoEditModal, setShowGenericInfoEditModal] = useState(false);
    const [isGenericInfoSubmitting, setIsGenericInfoSubmitting] = useState(false);
    const [isGenericInfoEditSubmitting, setIsGenericInfoEditSubmitting] = useState(false);
    const [genericInfoCommunicationData, setGenericInfoCommunicationData] = useState('');
    const [genericInfoCommunicationEditData, setGenericInfoCommunicationEditData] = useState('');
    const [genericInfoEditId, setGenericInfoEditId] = useState('');
    const [genericInfo, setGenericInfo] = useState([]);
    const [representativesList, setRepresentativesList] = useState([]);
    const [addPaymentLinkModalShow, setAddPaymentLinkModalShow] = useState(false);
    const [editPaymentLinkModalShow, setEditPaymentLinkModalShow] = useState(false);
    const [isPaymentLinkSubmitting, setIsPaymentLinkSubmitting] = useState(false);
    const [IsPaymentLinkUpdating, setIsPaymentLinkUpdating] = useState(false);
    const [showAddRepresentativeModal, setShowAddRepresentativeModal] = useState(false);
    const [isRepresentativeSubmitting, setIsRepresentativeSubmitting] = useState(false);
    const [isRepresentativeUpdating, setIsRepresentativeUpdating] = useState(false);
    const [showEditRepresentativeModal, setShowEditRepresentativeModal] = useState(false);
    const [data, setData] = useState({
        bank_name: '',
        iban: '',
        account_holder: '',
        email: '',
        more_info: '',
        bic_swift: '',
        country: ''
    });
    const [linkData, setLinkData] = useState({
        title: '',
        payment_link: '',
        description: '',
    });
    const [representative, setRepresentative] = useState({
        name: '',
        type: '',
        phone: '',
        whatsapp: '',
        email: '',
        start_day: '',
        end_day: '',
        start_time: '',
        end_time: ''
    });

    const time = [];
    for (var i = 0; i < 24; i++) {
        time.push({ label: i + ':00', value: i });
    }

    useEffect(() => {
        getBanks();
        getCountries();
        getGenericInfoCommunication();
        getPaymentLink();
        getRepresentatives();
    }, []);

    useEffect(() => {
        if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
            $(dataTableRef.current).DataTable().destroy();
        }

        if (!isLoading && banks.length > 0) {
            if (!$.fn.DataTable.isDataTable(dataTableRef.current)) {
                $(dataTableRef.current).DataTable({
                    order: [[0, 'desc']],
                });
            }
        }

        $(dataTableRef.current).on('click', '.status-button', function () {
            const id = $(this).data('bank-id');
            const selectBank = banks.find(x => x?.id === id);
            setSelectedBank(selectBank);
            showBankDetailsModal();
        });


        $(dataTableRef.current).on('click', '.edit-button', function () {
            const id = $(this).data('bank-id');
            const selectBank = banks.find(x => x?.id === id);
            const details = {
                id: selectBank?.id,
                bank_name: selectBank?.bank_name,
                iban: selectBank?.iban,
                account_holder: selectBank?.account_holder,
                email: selectBank?.email,
                more_info: selectBank?.more_info,
                bic_swift: selectBank?.bic_swift,
                country: selectBank?.country_id
            }
            setEditBank(details);
            showEditBankDetailsModal();
        });

        $(dataTableRef.current).on('click', '.delete-button', function () {
            const id = $(this).data('bank-id');
            const headers = {
                Authorization: `Bearer ${token}`,
            }

            Swal.fire({
                title: "Are you sure, you want to delete this bank?",
                showCancelButton: true,
                confirmButtonText: "Confirm",
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(process.env.REACT_APP_API_URL + '/delete-bank', { id: id }, headers)
                        .then((res) => {
                            Swal.fire("Success!", res?.data?.message, "success")
                                .then(() => {
                                    getBanks();
                                });
                        })
                        .catch((err) => {
                            Swal.fire("Ooops!", err?.response?.data?.message, "error");
                        });
                }
            });
        });
    }, [banks, isLoading]);

    useEffect(() => {
        if ($.fn.DataTable.isDataTable(paymentLinkDataTableRef.current)) {
            $(paymentLinkDataTableRef.current).DataTable().destroy();
        }

        if (!isPaymentLinkLoading && paymentLinks.length > 0) {
            if (!$.fn.DataTable.isDataTable(paymentLinkDataTableRef.current)) {
                $(paymentLinkDataTableRef.current).DataTable({
                    order: [[0, 'desc']],
                });
            }
        }

        $(paymentLinkDataTableRef.current).on('click', '.edit-button', function () {
            const id = $(this).data('bank-id');
            const selectLink = paymentLinks.find(x => x?.id === id);
            const details = {
                id: selectLink?.id,
                title: selectLink?.title,
                payment_link: selectLink?.link,
                description: selectLink?.description
            }
            setEditPaymentLink(details);
            showEditPaymentLinkModal();
        });

        $(paymentLinkDataTableRef.current).on('click', '.delete-button', function () {
            const id = $(this).data('bank-id');
            const headers = {
                Authorization: `Bearer ${token}`,
            }

            Swal.fire({
                title: "Are you sure, you want to delete this payment link?",
                showCancelButton: true,
                confirmButtonText: "Confirm",
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(process.env.REACT_APP_API_URL + '/delete-payment-link', { id: id }, headers)
                        .then((res) => {
                            Swal.fire("Success!", res?.data?.message, "success")
                                .then(() => {
                                    getPaymentLink();
                                });
                        })
                        .catch((err) => {
                            Swal.fire("Ooops!", err?.response?.data?.message, "error");
                        });
                }
            });
        });
    }, [paymentLinks, isPaymentLinkLoading]);

    useEffect(() => {
        if ($.fn.DataTable.isDataTable(representativeDataTableRef.current)) {
            $(representativeDataTableRef.current).DataTable().destroy();
        }

        if (!isRepresentativeListsLoading && representativesList.length > 0) {
            if (!$.fn.DataTable.isDataTable(representativeDataTableRef.current)) {
                $(representativeDataTableRef.current).DataTable({
                    order: [[0, 'desc']],
                });
            }
        }

        $(representativeDataTableRef.current).on('click', '.edit-button', function () {
            const id = $(this).data('bank-id');
            const selectRepresentative = representativesList.find(x => x?.id === id);
            const details = {
                id: selectRepresentative?.id,
                name: selectRepresentative?.name,
                type: selectRepresentative?.type,
                phone: selectRepresentative?.phone,
                whatsapp: selectRepresentative?.whatsapp,
                email: selectRepresentative?.email,
                start_day: selectRepresentative?.start_day,
                end_day: selectRepresentative?.end_day,
                start_time: selectRepresentative?.start_time,
                end_time: selectRepresentative?.end_time
            }
            setEditRepresentative(details);
            openEditRepresentativeModal();
        });

        $(representativeDataTableRef.current).on('click', '.delete-button', function () {
            const id = $(this).data('bank-id');
            const headers = {
                Authorization: `Bearer ${token}`,
            }

            Swal.fire({
                title: "Are you sure, you want to delete this representative?",
                showCancelButton: true,
                confirmButtonText: "Confirm",
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(process.env.REACT_APP_API_URL + '/delete-representative', { id: id }, headers)
                        .then((res) => {
                            Swal.fire("Success!", res?.data?.message, "success")
                                .then(() => {
                                    getRepresentatives();
                                });
                        })
                        .catch((err) => {
                            Swal.fire("Ooops!", err?.response?.data?.message, "error");
                        });
                }
            });
        });
    }, [representativesList, isRepresentativeListsLoading]);

    const getCountries = () => {
        const headers = {
            Authorization: `Bearer ${token}`,
        }
        axios.get(process.env.REACT_APP_API_URL + '/get-all-countries', headers)
            .then((res) => {
                if (res?.data?.status) {
                    setCountries(res?.data?.data);
                }
            })
            .catch((err) => {
                console.log(err, "err");
            })
    }

    const getBanks = () => {
        if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
            $(dataTableRef.current).DataTable().destroy();
        }
        setBanks([]);
        setIsLoading(true);
        const headers = {
            Authorization: `Bearer ${token}`,
        }
        axios.get(process.env.REACT_APP_API_URL + '/get-banks', data, headers)
            .then((res) => {
                setIsLoading(false);
                if (res?.data?.status) {
                    setBanks(res?.data?.data);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err, "err");
            })
    }

    const addBankDetails = () => {
        setShow(true);
    }

    const showBankDetailsModal = () => {
        setBankDetailsShow(true);
    }

    const showEditBankDetailsModal = () => {
        setEditBankDetailsModalShow(true);
    }

    const closeBankDetailsModal = () => {
        setBankDetailsShow(false);
    }

    const closeEditBankDetailsModal = () => {
        setEditBankDetailsModalShow(false);
    }

    const handleClose = () => {
        setShow(false);
        setErrors({});
        setData({
            bank_name: '',
            iban: '',
            account_holder: '',
            email: '',
            more_info: '',
            bic_swift: '',
            country: ''
        });
    }

    const onChangeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const details = {
            bank_name: data?.bank_name,
            iban: data?.iban,
            account_holder: data?.account_holder,
            email: data?.email,
            more_info: data?.more_info,
            bic_swift: data?.bic_swift,
            country: data?.country
        }

        if (name === 'bank_name') {
            details.bank_name = value;
        }

        if (name === 'iban') {
            details.iban = value;
        }

        if (name === 'account_holder') {
            details.account_holder = value;
        }

        if (name === 'email') {
            details.email = value;
        }

        if (name === 'more_info') {
            details.more_info = value;
        }

        if (name === 'bic_swift') {
            details.bic_swift = value;
        }

        if (name === 'country') {
            details.country = value;
        }

        setData(details);
    }

    const onUpdateHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const details = {
            id: editBank?.id,
            bank_name: editBank?.bank_name,
            iban: editBank?.iban,
            account_holder: editBank?.account_holder,
            email: editBank?.email,
            more_info: editBank?.more_info,
            bic_swift: editBank?.bic_swift,
            country: editBank?.country
        }

        if (name === 'bank_name') {
            details.bank_name = value;
        }

        if (name === 'iban') {
            details.iban = value;
        }

        if (name === 'account_holder') {
            details.account_holder = value;
        }

        if (name === 'email') {
            details.email = value;
        }

        if (name === 'more_info') {
            details.more_info = value;
        }

        if (name === 'bic_swift') {
            details.bic_swift = value;
        }

        if (name === 'country') {
            details.country = value;
        }

        setEditBank(details);
    }

    const submitBankDetails = () => {
        setErrors({});

        setIsAddBankLoading(true);
        const headers = {
            Authorization: `Bearer ${token}`,
        }

        axios.post(process.env.REACT_APP_API_URL + '/add-bank', data, headers)
            .then((res) => {
                setIsAddBankLoading(false);
                if (res?.data?.status) {
                    Swal.fire('Success!', res?.data?.message, 'success');
                    setData({
                        bank_name: '',
                        iban: '',
                        account_holder: '',
                        email: '',
                        more_info: '',
                        bic_swift: '',
                        country: ''
                    });
                    handleClose();
                    getBanks();
                }
            })
            .catch((err) => {
                setIsAddBankLoading(false);
                console.log(err, "err");
                Swal.fire('Oops!', err?.response?.data?.message, 'error')
            });
    }

    const updateBankDetails = () => {
        setErrors({});
        setIsEditBankLoading(true);
        const headers = {
            Authorization: `Bearer ${token}`,
        }

        axios.post(process.env.REACT_APP_API_URL + '/edit-bank', editBank, headers)
            .then((res) => {
                setIsEditBankLoading(false);
                if (res?.data?.status) {
                    Swal.fire('Success!', res?.data?.message, 'success');
                    setEditBank({});
                    closeEditBankDetailsModal();
                    setBanks(res?.data?.data);
                }
            })
            .catch((err) => {
                setIsEditBankLoading(false);
                console.log(err, "err");
                Swal.fire('Oops!', err?.response?.data?.message, 'error')
            });
    }

    const addGenericInfoCommunication = () => {
        setShowGenericInfoModal(true);
    }

    const closeGenericInfoCommunicationModal = () => {
        setShowGenericInfoModal(false);
        setGenericInfoCommunicationData('');
    }

    const closeGenericInfoCommunicationEditModal = () => {
        setShowGenericInfoEditModal(false);
        setGenericInfoCommunicationEditData('');
    }

    const onGenericInfoChangeHandler = () => {
        setGenericInfoCommunicationData(editorRef.current?.getData());
    }

    const onGenericInfoEditChangeHandler = () => {
        setGenericInfoCommunicationEditData(editorEditRef.current?.getData());
    }

    const onGenericInfoSubmitHandler = () => {
        setIsGenericInfoSubmitting(true);

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        axios.post(process.env.REACT_APP_API_URL + '/add-generic-info-communication', { data: genericInfoCommunicationData }, headers)
            .then((res) => {
                if (res?.data?.status) {
                    Swal.fire('Success!', res?.data?.message, 'success');
                    setGenericInfoCommunicationData('');
                    setIsGenericInfoSubmitting(false);
                    closeGenericInfoCommunicationModal();
                    getGenericInfoCommunication();
                }
            })
            .catch((err) => {
                setIsGenericInfoSubmitting(false);
                console.log(err, "err");
            });
    }

    const onGenericInfoUpdateHandler = () => {
        setIsGenericInfoEditSubmitting(true);
        const data = {
            id: genericInfoEditId,
            info: genericInfoCommunicationEditData
        };
        const headers = {
            Authorization: `Bearer ${token}`,
        }
        axios.post(process.env.REACT_APP_API_URL + '/edit-generic-info-communication', data, headers)
            .then((res) => {
                if (res?.data?.status) {
                    Swal.fire('Success!', res?.data?.message, 'success');
                    setGenericInfoCommunicationEditData('');
                    setIsGenericInfoEditSubmitting(false);
                    closeGenericInfoCommunicationEditModal();
                    setGenericInfo(res?.data?.data);
                }
            })
            .catch((err) => {
                setIsGenericInfoEditSubmitting(false);
                console.log(err, "err");
            });
    }

    const getGenericInfoCommunication = () => {
        const headers = {
            Authorization: `Bearer ${token}`,
        }
        axios.get(process.env.REACT_APP_API_URL + '/get-generic-info-communication', headers)
            .then((res) => {
                if (res?.data?.status) {
                    setGenericInfo(res?.data?.data);
                }
            })
            .catch((err) => {
                console.log(err, "err");
            })
    }

    const editGenericInfo = (id) => {
        const editGenericInfo = genericInfo?.find(x => x?.id === id);
        setShowGenericInfoEditModal(true);
        setGenericInfoEditId(id);
        setTimeout(() => {
            editorEditRef.current.setData(editGenericInfo?.info);
        }, 1000);
    }

    const deleteGenericInfo = (id) => {
        console.log(id, "id");
        const headers = {
            Authorization: `Bearer ${token}`,
        }

        Swal.fire({
            title: "Are you sure, you want to delete this Generic Info / Communication?",
            showCancelButton: true,
            confirmButtonText: "Confirm",
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(process.env.REACT_APP_API_URL + '/delete-generic-info-communication', { id: id }, headers)
                    .then((res) => {
                        Swal.fire("Success!", res?.data?.message, "success")
                            .then(() => {
                                setGenericInfo(res?.data?.data);
                            });
                    })
                    .catch((err) => {
                        Swal.fire("Ooops!", err?.response?.data?.message, "error");
                    });
            }
        });
    }

    const addPaymentLink = () => {
        setAddPaymentLinkModalShow(true);
    }

    const closeAddPaymentLinkModal = () => {
        setAddPaymentLinkModalShow(false);
        setLinkData({
            title: '',
            payment_link: '',
            description: ''
        });
    }

    const onPaymentLinkChangeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const details = {
            title: linkData?.title,
            payment_link: linkData?.payment_link,
            description: linkData?.description,
        }

        if (name === 'title') {
            details.title = value;
        }

        if (name === 'payment_link') {
            details.payment_link = value;
        }

        if (name === 'description') {
            details.description = value;
        }

        setLinkData(details);
    }

    const onSubmitPaymentLinkData = () => {
        setIsPaymentLinkSubmitting(true);

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        axios.post(process.env.REACT_APP_API_URL + '/add-payment-link', linkData, headers)
            .then((res) => {
                setIsPaymentLinkSubmitting(false);
                if (res?.data?.status) {
                    Swal.fire('Success!', res?.data?.message, 'success');
                    setLinkData({
                        title: '',
                        payment_link: '',
                        description: ''
                    });
                    closeAddPaymentLinkModal();
                    getPaymentLink();
                }
            })
            .catch((err) => {
                setIsPaymentLinkSubmitting(false);
                console.log(err, "err");
                Swal.fire('Oops!', err?.response?.data?.message, 'error')
            });
    }

    const getPaymentLink = () => {
        if ($.fn.DataTable.isDataTable(paymentLinkDataTableRef.current)) {
            $(paymentLinkDataTableRef.current).DataTable().destroy();
        }
        setPaymentLinks([]);
        setIsPaymentLinkLoading(true);
        const headers = {
            Authorization: `Bearer ${token}`,
        }
        axios.get(process.env.REACT_APP_API_URL + '/get-payment-link', headers)
            .then((res) => {
                setIsPaymentLinkLoading(false);
                if (res?.data?.status) {
                    setPaymentLinks(res?.data?.data);
                }
            })
            .catch((err) => {
                setIsPaymentLinkLoading(false);
                console.log(err, "err");
            })
    }

    const showEditPaymentLinkModal = () => {
        setEditPaymentLinkModalShow(true);
    }

    const closeEditPaymentLinkModal = () => {
        setEditPaymentLinkModalShow(false);
    }

    const onUpdatePaymentLinkHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const details = {
            id: editPaymentLink?.id,
            title: editPaymentLink?.title,
            payment_link: editPaymentLink?.payment_link,
            description: editPaymentLink?.description
        }

        if (name === 'title') {
            details.title = value;
        }

        if (name === 'payment_link') {
            details.payment_link = value;
        }

        if (name === 'description') {
            details.description = value;
        }

        setEditPaymentLink(details);
    }

    const onUpdatePaymentLinkData = () => {
        setIsPaymentLinkUpdating(true);

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        axios.post(process.env.REACT_APP_API_URL + '/edit-payment-link', editPaymentLink, headers)
            .then((res) => {
                setIsPaymentLinkUpdating(false);
                if (res?.data?.status) {
                    Swal.fire('Success!', res?.data?.message, 'success');
                    setLinkData({
                        title: '',
                        payment_link: '',
                        description: ''
                    });
                    closeEditPaymentLinkModal();
                    getPaymentLink();
                }
            })
            .catch((err) => {
                setIsPaymentLinkUpdating(false);
                console.log(err, "err");
                Swal.fire('Oops!', err?.response?.data?.message, 'error')
            });
    }

    const addRepresentative = () => {
        setShowAddRepresentativeModal(true);
    }

    const closeAddRepresentativeModal = () => {
        setShowAddRepresentativeModal(false);
        setRepresentative({
            name: '',
            type: '',
            phone: '',
            whatsapp: '',
            email: '',
            start_day: '',
            end_day: '',
            start_time: '',
            end_time: ''
        });
    }

    const onRepresentativeChangeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const details = {
            name: representative?.name,
            type: representative?.type,
            phone: representative?.phone,
            whatsapp: representative?.whatsapp,
            email: representative?.email,
            start_day: representative?.start_day,
            end_day: representative?.end_day,
            start_time: representative?.start_time,
            end_time: representative?.end_time,
        }

        if (name === 'name') {
            details.name = value;
        }

        if (name === 'type') {
            details.type = value;
        }

        if (name === 'phone') {
            details.phone = value;
        }

        if (name === 'whatsapp') {
            details.whatsapp = value;
        }

        if (name === 'email') {
            details.email = value;
        }

        if (name === 'start_day') {
            details.start_day = value;
        }

        if (name === 'end_day') {
            details.end_day = value;
        }

        if (name === 'start_time') {
            details.start_time = value;
        }

        if (name === 'end_time') {
            details.end_time = value;
        }

        setRepresentative(details);
    }

    const onSubmitRepresentative = () => {
        setIsRepresentativeSubmitting(true);

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        axios.post(process.env.REACT_APP_API_URL + '/add-representative', representative, headers)
            .then((res) => {
                setIsRepresentativeSubmitting(false);
                if (res?.data?.status) {
                    Swal.fire('Success!', res?.data?.message, 'success');
                    closeAddRepresentativeModal();
                    getRepresentatives();
                }
            })
            .catch((err) => {
                setIsRepresentativeSubmitting(false);
                console.log(err, "err");
                Swal.fire('Oops!', err?.response?.data?.message, 'error');
            });
    }

    const getRepresentatives = () => {
        if ($.fn.DataTable.isDataTable(representativeDataTableRef.current)) {
            $(representativeDataTableRef.current).DataTable().destroy();
        }
        setRepresentativesList([]);
        setIsRepresentativeListsLoading(true);
        const headers = {
            Authorization: `Bearer ${token}`,
        }
        axios.get(process.env.REACT_APP_API_URL + '/get-representative', headers)
            .then((res) => {
                setIsRepresentativeListsLoading(false);
                if (res?.data?.status) {
                    setRepresentativesList(res?.data?.data);
                }
            })
            .catch((err) => {
                setIsRepresentativeListsLoading(false);
                console.log(err, "err");
            })
    }

    const openEditRepresentativeModal = () => {
        setShowEditRepresentativeModal(true);
    }

    const closeEditRepresentativeModal = () => {
        setShowEditRepresentativeModal(false);
    }

    const onUpdateRepresentativeChangeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const details = {
            id: editRepresentative?.id,
            name: editRepresentative?.name,
            type: editRepresentative?.type,
            phone: editRepresentative?.phone,
            whatsapp: editRepresentative?.whatsapp,
            email: editRepresentative?.email,
            start_day: editRepresentative?.start_day,
            end_day: editRepresentative?.end_day,
            start_time: editRepresentative?.start_time,
            end_time: editRepresentative?.end_time,
        }

        if (name === 'name') {
            details.name = value;
        }

        if (name === 'type') {
            details.type = value;
        }

        if (name === 'phone') {
            details.phone = value;
        }

        if (name === 'whatsapp') {
            details.whatsapp = value;
        }

        if (name === 'email') {
            details.email = value;
        }

        if (name === 'start_day') {
            details.start_day = value;
        }

        if (name === 'end_day') {
            details.end_day = value;
        }

        if (name === 'start_time') {
            details.start_time = value;
        }

        if (name === 'end_time') {
            details.end_time = value;
        }

        setEditRepresentative(details);
    }

    const onUpdateRepresentativeHandler = () => {
        setIsRepresentativeUpdating(true);

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        axios.post(process.env.REACT_APP_API_URL + '/edit-representative', editRepresentative, headers)
            .then((res) => {
                setIsRepresentativeUpdating(false);
                if (res?.data?.status) {
                    Swal.fire('Success!', res?.data?.message, 'success');
                    closeEditRepresentativeModal();
                    getRepresentatives();
                }
            })
            .catch((err) => {
                setIsRepresentativeUpdating(false);
                console.log(err, "err");
                Swal.fire('Oops!', err?.response?.data?.message, 'error');
            });
    }

    return (
        <section>
            <Container>
                <Row>
                    <Col lg="12">
                        <Card className="card-block card-stretch">
                            <Card.Body className="p-0">
                                <div className="d-flex justify-content-between align-items-center p-3">
                                    <h5 className="font-weight-bold">Generic Info / Communication</h5>
                                    {user?.user?.user?.user?.userModel?.is_admin ? <OverlayTrigger placement="top" overlay={<Tooltip>Add</Tooltip>}>
                                        <button className="btn btn-primary loginBtn" onClick={addGenericInfoCommunication}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                            </svg>
                                            Add
                                        </button>
                                    </OverlayTrigger> : null}
                                </div>
                                {genericInfo.length > 0 ? (
                                    <div className='generic-info-main-box'>
                                        {genericInfo.map((val, ind) => {
                                            return (
                                                <div className='generic-info' key={ind}>
                                                    <div className='generic-info-actions'>
                                                        {user?.user?.user?.user?.userModel?.is_admin ?
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                                                                <div style={{ cursor: 'pointer' }} onClick={() => editGenericInfo(val?.id)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                                    </svg>
                                                                </div>
                                                            </OverlayTrigger> : null}
                                                        {user?.user?.user?.user?.userModel?.is_admin ?
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                                                                <div style={{ cursor: 'pointer' }} onClick={() => deleteGenericInfo(val?.id)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                                    </svg>
                                                                </div>
                                                            </OverlayTrigger> : null}
                                                    </div>
                                                    <div dangerouslySetInnerHTML={{ __html: val?.info }}></div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                                    : (
                                        <div className="text-center mb-5">
                                            <p>No Generic Info / Communication Available.</p>
                                        </div>
                                    )
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="12">
                        <Card className="card-block card-stretch">
                            <Card.Body className="p-0">
                                <div className="d-flex justify-content-between align-items-center p-3">
                                    <h5 className="font-weight-bold">Banks</h5>
                                    {user?.user?.user?.user?.userModel?.is_admin ? <OverlayTrigger placement="top" overlay={<Tooltip>Add Bank</Tooltip>}>
                                        <button className="btn btn-primary loginBtn" onClick={addBankDetails}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                            </svg>
                                            Add Bank
                                        </button>
                                    </OverlayTrigger> : null}
                                </div>
                                {isLoading ? (
                                    <div className="text-center mb-5">
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div>
                                ) :
                                    banks.length > 0 ? (
                                        <div className="table-responsive">
                                            <table ref={dataTableRef} className="table data-table mb-0">
                                                <thead className="table-color-heading text-left">
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Bank Name</th>
                                                        <th scope="col">Account Holder</th>
                                                        <th scope="col">Iban</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {banks.map((bank, index) => {
                                                        return (
                                                            <tr key={index} className="white-space-no-wrap">
                                                                <td>{index + 1}</td>
                                                                <td>{bank?.bank_name}</td>
                                                                <td>{bank?.account_holder}</td>
                                                                <td>{bank?.iban}</td>
                                                                <td>{bank?.email}</td>
                                                                <td>{bank?.country?.name}</td>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>View Bank Details</Tooltip>}>
                                                                            <div style={{ cursor: 'pointer' }} >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" data-bank-id={bank?.id} className="w-6 h-6 status-button">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                                                </svg>
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                        {user?.user?.user?.user?.userModel?.is_admin ?
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Edit Bank Details</Tooltip>}>
                                                                                <div style={{ cursor: 'pointer' }} >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" data-bank-id={bank?.id} className="w-6 h-6 edit-button ml-2">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                                                    </svg>
                                                                                </div>
                                                                            </OverlayTrigger> : null}
                                                                        {user?.user?.user?.user?.userModel?.is_admin ?
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                                                                                <div style={{ cursor: 'pointer' }} >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" data-bank-id={bank?.id} className="w-6 h-6 delete-button ml-2">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                                                    </svg>
                                                                                </div>
                                                                            </OverlayTrigger> : null}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                        : (
                                            <div className="text-center mb-5">
                                                <p>No Banks Available.</p>
                                            </div>
                                        )
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="12">
                        <Card className="card-block card-stretch">
                            <Card.Body className="p-0">
                                <div className="d-flex justify-content-between align-items-center p-3">
                                    <h5 className="font-weight-bold">Payment Links</h5>
                                    {user?.user?.user?.user?.userModel?.is_admin ? <OverlayTrigger placement="top" overlay={<Tooltip>Add Payment Links</Tooltip>}>
                                        <button className="btn btn-primary loginBtn" onClick={addPaymentLink}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                            </svg>
                                            Add Payment Links
                                        </button>
                                    </OverlayTrigger> : null}
                                </div>
                                {isPaymentLinkLoading ? (
                                    <div className="text-center mb-5">
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div>
                                ) :
                                    paymentLinks.length > 0 ? (
                                        <div className="table-responsive">
                                            <table ref={paymentLinkDataTableRef} className="table data-table mb-0">
                                                <thead className="table-color-heading text-left">
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Title</th>
                                                        <th scope="col">description</th>
                                                        <th scope="col">Link</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {paymentLinks.map((paymentLink, index) => {
                                                        return (
                                                            <tr key={index} className="white-space-no-wrap">
                                                                <td>{index + 1}</td>
                                                                <td>{paymentLink?.title ? paymentLink?.title : 'N/A'}</td>
                                                                <td>{paymentLink?.description ? paymentLink?.description : 'N/A'}</td>
                                                                <td>{paymentLink?.link ? <a href={paymentLink?.link}>Click Here</a> : 'N/A'}</td>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        {user?.user?.user?.user?.userModel?.is_admin ?
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Edit Payment Link</Tooltip>}>
                                                                                <div style={{ cursor: 'pointer' }} >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" data-bank-id={paymentLink?.id} className="w-6 h-6 edit-button ml-2">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                                                    </svg>
                                                                                </div>
                                                                            </OverlayTrigger> : null}
                                                                        {user?.user?.user?.user?.userModel?.is_admin ?
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                                                                                <div style={{ cursor: 'pointer' }} >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" data-bank-id={paymentLink?.id} className="w-6 h-6 delete-button ml-2">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                                                    </svg>
                                                                                </div>
                                                                            </OverlayTrigger> : null}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                        : (
                                            <div className="text-center mb-5">
                                                <p>No Payment Links Available.</p>
                                            </div>
                                        )
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="12">
                        <Card className="card-block card-stretch">
                            <Card.Body className="p-0">
                                <div className="d-flex justify-content-between align-items-center p-3">
                                    <h5 className="font-weight-bold">Sales / Technical Representative</h5>
                                    {user?.user?.user?.user?.userModel?.is_admin ? <OverlayTrigger placement="top" overlay={<Tooltip>Add Representative</Tooltip>}>
                                        <button className="btn btn-primary loginBtn" onClick={addRepresentative}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                            </svg>
                                            Add Representative
                                        </button>
                                    </OverlayTrigger> : null}
                                </div>
                                {isRepresentativeListsLoading ? (
                                    <div className="text-center mb-5">
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div>
                                ) :
                                    representativesList.length > 0 ? (
                                        <div className="table-responsive">
                                            <table ref={representativeDataTableRef} className="table data-table mb-0">
                                                <thead className="table-color-heading text-left">
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Phone</th>
                                                        <th scope="col">Whatsapp</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Days</th>
                                                        <th scope="col">Time</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {representativesList.map((representatives, index) => {
                                                        return (
                                                            <tr key={index} className="white-space-no-wrap">
                                                                <td>{index + 1}</td>
                                                                <td>{representatives?.name ? representatives?.name : 'N/A'}</td>
                                                                <td>{representatives?.type ? (representatives?.type == 'SALES_SUPPORT' ? 'Sales Support' : 'Technical Support') : 'N/A'}</td>
                                                                <td>{representatives?.phone ? representatives?.phone : 'N/A'}</td>
                                                                <td>{representatives?.whatsapp ? representatives?.whatsapp : 'N/A'}</td>
                                                                <td>{representatives?.email ? representatives?.email : 'N/A'}</td>
                                                                <td>{representatives?.start_day ? representatives?.start_day : 'N/A'} - {representatives?.end_day ? representatives?.end_day : 'N/A'}</td>
                                                                <td>{representatives?.start_time ? representatives?.start_time + ':00' : 'N/A'} - {representatives?.end_time ? representatives?.end_time + ':00' : 'N/A'}</td>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        {user?.user?.user?.user?.userModel?.is_admin ?
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Edit Representative</Tooltip>}>
                                                                                <div style={{ cursor: 'pointer' }} >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" data-bank-id={representatives?.id} className="w-6 h-6 edit-button ml-2">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                                                    </svg>
                                                                                </div>
                                                                            </OverlayTrigger> : null}
                                                                        {user?.user?.user?.user?.userModel?.is_admin ?
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                                                                                <div style={{ cursor: 'pointer' }} >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" data-bank-id={representatives?.id} className="w-6 h-6 delete-button ml-2">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                                                    </svg>
                                                                                </div>
                                                                            </OverlayTrigger> : null}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                        : (
                                            <div className="text-center mb-5">
                                                <p>No Representative Available.</p>
                                            </div>
                                        )
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={showGenericInfoModal} onHide={closeGenericInfoCommunicationModal} size="lg">
                <Modal.Header>
                    <Modal.Title as="h5">Add Generic Info / Communication</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={closeGenericInfoCommunicationModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <CKEditor
                                editor={ClassicEditor}
                                onReady={(editor) => {
                                    editorRef.current = editor;
                                }}
                                onChange={onGenericInfoChangeHandler}
                                config={{
                                    height: '400px',
                                    toolbar: {
                                        items: [
                                            'undo', 'redo',
                                            '|', 'heading',
                                            '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                            '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                                            '|', 'alignment',
                                            '|', 'link', 'uploadImage', 'blockQuote',
                                            '-', // break point
                                            '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
                                        ],
                                        shouldNotGroupWhenFull: true
                                    },
                                    plugins: [
                                        Bold,
                                        Essentials,
                                        Italic,
                                        Mention,
                                        Paragraph,
                                        Undo,
                                        FontColor,
                                        Heading,
                                        FontBackgroundColor,
                                        Underline,
                                        FontSize,
                                        FontFamily,
                                        Link,
                                        Strikethrough,
                                        Superscript,
                                        Subscript,
                                        Indent,
                                        Alignment,
                                        List,
                                        BlockQuote
                                    ]
                                }}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {isGenericInfoSubmitting ?
                        <div className="loader-box float-right">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div> :
                        <Button className='btn btn-primary loginBtn' onClick={onGenericInfoSubmitHandler}>Submit</Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={showGenericInfoEditModal} onHide={closeGenericInfoCommunicationEditModal} size="lg">
                <Modal.Header>
                    <Modal.Title as="h5">Edit Generic Info / Communication</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={closeGenericInfoCommunicationEditModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <CKEditor
                                editor={ClassicEditor}
                                onReady={(editor) => {
                                    editorEditRef.current = editor;
                                }}
                                onChange={onGenericInfoEditChangeHandler}
                                config={{
                                    toolbar: {
                                        items: [
                                            'undo', 'redo',
                                            '|', 'heading',
                                            '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                            '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript',
                                            '|', 'alignment',
                                            '|', 'link', 'blockQuote',
                                            '-', // break point
                                            '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
                                        ],
                                        shouldNotGroupWhenFull: true
                                    },
                                    plugins: [
                                        Bold,
                                        Essentials,
                                        Italic,
                                        Mention,
                                        Paragraph,
                                        Undo,
                                        FontColor,
                                        Heading,
                                        FontBackgroundColor,
                                        Underline,
                                        FontSize,
                                        FontFamily,
                                        Link,
                                        Strikethrough,
                                        Superscript,
                                        Subscript,
                                        Indent,
                                        Alignment,
                                        List,
                                        BlockQuote
                                    ]
                                }}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {isGenericInfoEditSubmitting ?
                        <div className="loader-box float-right">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div> :
                        <Button className='btn btn-primary loginBtn' onClick={onGenericInfoUpdateHandler}>Update</Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header>
                    <Modal.Title as="h5">Add Bank Details</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="bank_name">Bank Name</Form.Label>
                                <Form.Control id="bank_name" className={errors.bank_name ? 'is-invalid' : ''} onChange={onChangeHandler} name="bank_name" placeholder='Enter Bank Name' value={data.bank_name} />
                                {errors.bank_name ? <div className="invalid-feedback">{errors.bank_name}</div> : null}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="account_holder">Account Holder</Form.Label>
                                <Form.Control id="account_holder" className={errors.account_holder ? 'is-invalid' : ''} onChange={onChangeHandler} name="account_holder" placeholder='Enter Account Holder' value={data.account_holder} />
                                {errors.account_holder ? <div className="invalid-feedback">{errors.account_holder}</div> : null}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="iban">IBAN</Form.Label>
                                <Form.Control id="iban" className={errors.iban ? 'is-invalid' : ''} onChange={onChangeHandler} name="iban" placeholder='Enter IBAN' value={data.iban} />
                                {errors.iban ? <div className="invalid-feedback">{errors.iban}</div> : null}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="bic_swift">BIC / SWIFT</Form.Label>
                                <Form.Control id="bic_swift" className={errors.bic_swift ? 'is-invalid' : ''} onChange={onChangeHandler} name="bic_swift" placeholder='Enter BIC / SWIFT' value={data.bic_swift} />
                                {errors.bic_swift ? <div className="invalid-feedback">{errors.bic_swift}</div> : null}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="email">Email</Form.Label>
                                <Form.Control id="email" className={errors.email ? 'is-invalid' : ''} onChange={onChangeHandler} name="email" placeholder='Enter Email' value={data.email} />
                                {errors.email ? <div className="invalid-feedback">{errors.email}</div> : null}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="country">Country</Form.Label>
                                <Form.Control as={'select'} id="country" className={errors.country ? 'is-invalid' : ''} onChange={onChangeHandler} name="country" value={data.country}>
                                    <option value={''} disabled>Select Country</option>
                                    {countries?.length > 0 ? countries.map((country, ind) => {
                                        return (
                                            <option key={ind} value={country?.id}>{country?.name}</option>
                                        )
                                    }) : null}
                                </Form.Control>
                                {errors.country ? <div className="invalid-feedback">{errors.country}</div> : null}
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="more_info">More Info</Form.Label>
                                <Form.Control id="more_info" className={errors.more_info ? 'is-invalid' : ''} as={'textarea'} onChange={onChangeHandler} name="more_info" placeholder='Enter More Info' value={data.more_info} />
                                {errors.more_info ? <div className="invalid-feedback">{errors.more_info}</div> : null}
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {isAddBankLoading ?
                        <div className="loader-box float-right">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div> :
                        <Button className='btn btn-primary loginBtn' onClick={submitBankDetails}>Submit</Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={editBankDetailsModalShow} onHide={closeEditBankDetailsModal} size="lg">
                <Modal.Header>
                    <Modal.Title as="h5">Edit Bank Details</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={closeEditBankDetailsModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="bank_name">Bank Name</Form.Label>
                                <Form.Control id="bank_name" className={errors.bank_name ? 'is-invalid' : ''} onChange={onUpdateHandler} name="bank_name" placeholder='Enter Bank Name' value={editBank.bank_name} />
                                {errors.bank_name ? <div className="invalid-feedback">{errors.bank_name}</div> : null}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="account_holder">Account Holder</Form.Label>
                                <Form.Control id="account_holder" className={errors.account_holder ? 'is-invalid' : ''} onChange={onUpdateHandler} name="account_holder" placeholder='Enter Account Holder' value={editBank.account_holder} />
                                {errors.account_holder ? <div className="invalid-feedback">{errors.account_holder}</div> : null}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="iban">IBAN</Form.Label>
                                <Form.Control id="iban" className={errors.iban ? 'is-invalid' : ''} onChange={onUpdateHandler} name="iban" placeholder='Enter IBAN' value={editBank.iban} />
                                {errors.iban ? <div className="invalid-feedback">{errors.iban}</div> : null}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="bic_swift">BIC / SWIFT</Form.Label>
                                <Form.Control id="bic_swift" className={errors.bic_swift ? 'is-invalid' : ''} onChange={onUpdateHandler} name="bic_swift" placeholder='Enter BIC / SWIFT' value={editBank.bic_swift} />
                                {errors.bic_swift ? <div className="invalid-feedback">{errors.bic_swift}</div> : null}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="email">Email</Form.Label>
                                <Form.Control id="email" className={errors.email ? 'is-invalid' : ''} onChange={onUpdateHandler} name="email" placeholder='Enter Email' value={editBank.email} />
                                {errors.email ? <div className="invalid-feedback">{errors.email}</div> : null}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="country">Country</Form.Label>
                                <Form.Control as={'select'} id="country" className={errors.country ? 'is-invalid' : ''} onChange={onChangeHandler} name="country" value={editBank.country}>
                                    <option value={''} disabled>Select Country</option>
                                    {countries?.length > 0 ? countries.map((country, ind) => {
                                        return (
                                            <option key={ind} value={country?.id}>{country?.name}</option>
                                        )
                                    }) : null}
                                </Form.Control>
                                {errors.country ? <div className="invalid-feedback">{errors.country}</div> : null}
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="more_info">More Info</Form.Label>
                                <Form.Control id="more_info" className={errors.more_info ? 'is-invalid' : ''} as={'textarea'} onChange={onUpdateHandler} name="more_info" placeholder='Enter More Info' value={editBank.more_info} />
                                {errors.more_info ? <div className="invalid-feedback">{errors.more_info}</div> : null}
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {isEditBankLoading ?
                        <div className="loader-box float-right">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div> :
                        <Button className='btn btn-primary loginBtn' onClick={updateBankDetails}>Submit</Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={bankDetailsShow} onHide={closeBankDetailsModal} size="lg">
                <Modal.Header>
                    <Modal.Title as="h5">Bank Details</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={closeBankDetailsModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <h5>Bank Name:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.bank_name}</p>
                        </Col>
                        <Col md={4}>
                            <h5>Account Holder:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.account_holder}</p>
                        </Col>
                        <Col md={4}>
                            <h5>IBAN:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.iban}</p>
                        </Col>
                        <Col md={4}>
                            <h5>BIC / SWIFT:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.bic_swift}</p>
                        </Col>
                        <Col md={4}>
                            <h5>Email:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.email}</p>
                        </Col>
                        <Col md={4}>
                            <h5>Country:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.country?.name}</p>
                        </Col>
                        <Col md={4}>
                            <h5>More Info:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.more_info}</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-primary loginBtn' onClick={closeBankDetailsModal}>close</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={addPaymentLinkModalShow} onHide={closeAddPaymentLinkModal} size="lg">
                <Modal.Header>
                    <Modal.Title as="h5">Add Payment Link</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={closeAddPaymentLinkModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="title">Title</Form.Label>
                                <Form.Control id="title" onChange={onPaymentLinkChangeHandler} name="title" placeholder='Enter Title' value={linkData.title} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="payment_link">Link</Form.Label>
                                <Form.Control id="payment_link" onChange={onPaymentLinkChangeHandler} name="payment_link" placeholder='Enter Payment Link' value={linkData.payment_link} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="description">Description</Form.Label>
                                <Form.Control id="description" as={'textarea'} onChange={onPaymentLinkChangeHandler} name="description" placeholder='Enter Description' value={linkData.description} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {isPaymentLinkSubmitting ?
                        <div className="loader-box float-right">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div> :
                        <Button className='btn btn-primary loginBtn' onClick={onSubmitPaymentLinkData}>Submit</Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={editPaymentLinkModalShow} onHide={closeEditPaymentLinkModal} size="lg">
                <Modal.Header>
                    <Modal.Title as="h5">Edit Payment Link</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={closeEditPaymentLinkModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="title">Title</Form.Label>
                                <Form.Control id="title" onChange={onUpdatePaymentLinkHandler} name="title" placeholder='Enter Title' value={editPaymentLink.title} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="payment_link">Link</Form.Label>
                                <Form.Control id="payment_link" onChange={onUpdatePaymentLinkHandler} name="payment_link" placeholder='Enter Payment Link' value={editPaymentLink.payment_link} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="description">Description</Form.Label>
                                <Form.Control id="description" as={'textarea'} onChange={onUpdatePaymentLinkHandler} name="description" placeholder='Enter Description' value={editPaymentLink.description} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {IsPaymentLinkUpdating ?
                        <div className="loader-box float-right">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div> :
                        <Button className='btn btn-primary loginBtn' onClick={onUpdatePaymentLinkData}>Update</Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={showAddRepresentativeModal} onHide={closeAddRepresentativeModal} size="lg">
                <Modal.Header>
                    <Modal.Title as="h5">Add Representative</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={closeAddRepresentativeModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="name">Name</Form.Label>
                                <Form.Control id="name" onChange={onRepresentativeChangeHandler} name="name" placeholder='Enter Name' value={representative.name} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="type">Type</Form.Label>
                                <Form.Control as={'select'} id="type" onChange={onRepresentativeChangeHandler} name="type" value={representative.type}>
                                    <option value="" disabled>Select type</option>
                                    <option value="SALES_SUPPORT">Sales Support</option>
                                    <option value="TECHNICAL_SUPPORT">Technical Support</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="phone">Phone</Form.Label>
                                <Form.Control id="phone" onChange={onRepresentativeChangeHandler} name="phone" placeholder='Enter Phone' value={representative.phone} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="whatsapp">WhatsApp</Form.Label>
                                <Form.Control id="whatsapp" onChange={onRepresentativeChangeHandler} name="whatsapp" placeholder='Enter WhatsApp' value={representative.whatsapp} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="email">Email</Form.Label>
                                <Form.Control id="email" onChange={onRepresentativeChangeHandler} name="email" placeholder='Enter Email' value={representative.email} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="start_day">Start Day</Form.Label>
                                <Form.Control as={'select'} id="start_day" name="start_day" onChange={onRepresentativeChangeHandler} value={representative.start_day}>
                                    <option value="" disabled>Select Start Day</option>
                                    <option value="Monday">Monday</option>
                                    <option value="Tuesday">Tuesday</option>
                                    <option value="Wednesday">Wednesday</option>
                                    <option value="Thursday">Thursday</option>
                                    <option value="Friday">Friday</option>
                                    <option value="Saturday">Saturday</option>
                                    <option value="Sunday">Sunday</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="end_day">End Day</Form.Label>
                                <Form.Control as={'select'} id="end_day" name="end_day" onChange={onRepresentativeChangeHandler} value={representative.end_day}>
                                    <option value="" disabled>Select End Day</option>
                                    <option value="Monday">Monday</option>
                                    <option value="Tuesday">Tuesday</option>
                                    <option value="Wednesday">Wednesday</option>
                                    <option value="Thursday">Thursday</option>
                                    <option value="Friday">Friday</option>
                                    <option value="Saturday">Saturday</option>
                                    <option value="Sunday">Sunday</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="start_time">Start Time</Form.Label>
                                <Form.Control as={'select'} id="start_time" name="start_time" onChange={onRepresentativeChangeHandler} value={representative.start_time}>
                                    <option value="" disabled>Select Start Time</option>
                                    {time.map((t, i) => {
                                        return (
                                            <option key={i} value={t?.value}>{t?.label}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="end_time">End Time</Form.Label>
                                <Form.Control as={'select'} id="end_time" name="end_time" onChange={onRepresentativeChangeHandler} value={representative.end_time}>
                                    <option value="" disabled>Select End Time</option>
                                    {time.map((t, i) => {
                                        return (
                                            <option key={i} value={t?.value}>{t?.label}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {isRepresentativeSubmitting ?
                        <div className="loader-box float-right">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div> :
                        <Button className='btn btn-primary loginBtn' onClick={onSubmitRepresentative}>Submit</Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={showEditRepresentativeModal} onHide={closeEditRepresentativeModal} size="lg">
                <Modal.Header>
                    <Modal.Title as="h5">Edit Representative</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={closeEditRepresentativeModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="name">Name</Form.Label>
                                <Form.Control id="name" onChange={onUpdateRepresentativeChangeHandler} name="name" placeholder='Enter Name' value={editRepresentative.name} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="type">Type</Form.Label>
                                <Form.Control as={'select'} id="type" onChange={onUpdateRepresentativeChangeHandler} name="type" value={editRepresentative.type}>
                                    <option value="" disabled>Select type</option>
                                    <option value="SALES_SUPPORT">Sales Support</option>
                                    <option value="TECHNICAL_SUPPORT">Technical Support</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="phone">Phone</Form.Label>
                                <Form.Control id="phone" onChange={onUpdateRepresentativeChangeHandler} name="phone" placeholder='Enter Phone' value={editRepresentative.phone} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="whatsapp">WhatsApp</Form.Label>
                                <Form.Control id="whatsapp" onChange={onUpdateRepresentativeChangeHandler} name="whatsapp" placeholder='Enter WhatsApp' value={editRepresentative.whatsapp} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="email">Email</Form.Label>
                                <Form.Control id="email" onChange={onUpdateRepresentativeChangeHandler} name="email" placeholder='Enter Email' value={editRepresentative.email} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="start_day">Start Day</Form.Label>
                                <Form.Control as={'select'} id="start_day" name="start_day" onChange={onUpdateRepresentativeChangeHandler} value={editRepresentative.start_day}>
                                    <option value="" disabled>Select Start Day</option>
                                    <option value="Monday">Monday</option>
                                    <option value="Tuesday">Tuesday</option>
                                    <option value="Wednesday">Wednesday</option>
                                    <option value="Thursday">Thursday</option>
                                    <option value="Friday">Friday</option>
                                    <option value="Saturday">Saturday</option>
                                    <option value="Sunday">Sunday</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="end_day">End Day</Form.Label>
                                <Form.Control as={'select'} id="end_day" name="end_day" onChange={onUpdateRepresentativeChangeHandler} value={editRepresentative.end_day}>
                                    <option value="" disabled>Select End Day</option>
                                    <option value="Monday">Monday</option>
                                    <option value="Tuesday">Tuesday</option>
                                    <option value="Wednesday">Wednesday</option>
                                    <option value="Thursday">Thursday</option>
                                    <option value="Friday">Friday</option>
                                    <option value="Saturday">Saturday</option>
                                    <option value="Sunday">Sunday</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="start_time">Start Time</Form.Label>
                                <Form.Control as={'select'} id="start_time" name="start_time" onChange={onUpdateRepresentativeChangeHandler} value={editRepresentative.start_time}>
                                    <option value="" disabled>Select Start Time</option>
                                    {time.map((t, i) => {
                                        return (
                                            <option key={i} value={t?.value}>{t?.label}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="end_time">End Time</Form.Label>
                                <Form.Control as={'select'} id="end_time" name="end_time" onChange={onUpdateRepresentativeChangeHandler} value={editRepresentative.end_time}>
                                    <option value="" disabled>Select End Time</option>
                                    {time.map((t, i) => {
                                        return (
                                            <option key={i} value={t?.value}>{t?.label}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {isRepresentativeUpdating ?
                        <div className="loader-box float-right">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div> :
                        <Button className='btn btn-primary loginBtn' onClick={onUpdateRepresentativeHandler}>Update</Button>
                    }
                </Modal.Footer>
            </Modal>
        </section>
    );
}

export default SupportInfo;
