import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import './custome-select.css'

const Customeradd = ({ user }) => {
    let history = useHistory()
    const [countriesData, setCountriesData] = useState([]);
    const [statesData, setStatesData] = useState([]);
    const [allStatesData, setAllStatesData] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        company_id: '',
        email: '',
        phone: '',
        country_id: '',
        state_id: '',
        address: '',
        zipcode: '',
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const token = user.user.user.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/countries-states`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setCountriesData(response.data.countries);
                // setStatesData(response.data.states);
                setAllStatesData(response.data.states);
            } catch (error) {
                console.error('Error fetching customer details:', error);
            }
        };
        fetchData();
    }, [token, user.user.user.companyDetails.id]);

    const getCountryStates = (countryId) => {
        const matchingStates = allStatesData.filter((state) => state.country_id == countryId);
        setStatesData(matchingStates);
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleCountryChange = (event) => {
        setSelectedCountry(event);
        setStatesData([]);
        setSelectedState("");
        getCountryStates(event.value);
        setFormData((prevData) => ({
            ...prevData,
            country_id: event.value,
        }));
    };

    const handleStateChange = (event) => {
        setSelectedState(event);
        setFormData((prevData) => ({
            ...prevData,
            state_id: event.value,
        }));
    };
    const validateForm = () => {
        const newErrors = {};

        // Check for required fields
        if (!formData.name) {
            newErrors.name = 'Name is required';
        }
        if (!formData.email) {
            newErrors.email = 'Email is required';
        }
        if (!formData.phone) {
            newErrors.phone = 'Phone is required';
        }

        if (!formData.address) {
            newErrors.address = 'Address is required';
        }
        if (!formData.zipcode) {
            newErrors.zipcode = 'Zipcode is required';
        }

        setErrors(newErrors);

        // Return true if there are no errors
        return Object.keys(newErrors).length === 0;
    };
    const handleAddCustomer = async () => {
        if (validateForm()) {
            try {
                setIsLoading(true);
                const formDataWithCompanyId = {
                    ...formData,
                    company_id: user.user.user.companyDetails.id, // Append the selected company_id
                };

                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/companies/${user.user.user.companyDetails.id}/customers`,
                    formDataWithCompanyId,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.data.status) {
                    Swal.fire('Done!', response.data.message, 'success').then(() => {
                        history.push('/customers');
                    });
                } else {
                    Swal.fire('Oops!', response.data.message, 'error');
                }
                // Handle any further actions or notifications
            } catch (error) {
                Swal.fire('Oops!', error.message, 'error');
                // Handle error notifications or messages
            } finally {
                setIsLoading(false); // Set isLoading back to false after submission
            }
        };
    };
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/customers">Customers</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Customer</li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/customers" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold0 d-flex align-items-center">Add New Customer</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md="9">
                                        <Form className="row g-3 date-icon-set-modal">
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text1" className="font-weight-bold text-muted text-uppercase">Full Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Text1"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Full Name"
                                                />
                                                {errors.name && <div className="text-danger">{errors.name}</div>}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text4" className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Text4"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Email"
                                                />
                                                {errors.email && <div className="text-danger">{errors.email}</div>}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text5" className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Text5"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Phone"
                                                />
                                                {errors.phone && <div className="text-danger">{errors.phone}</div>}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="inputcountry" className="font-weight-bold text-muted text-uppercase">Country</Form.Label>

                                                <Select
                                                    classNamePrefix="form-control"
                                                    id="customerSelect"
                                                    className='custom-select-container'
                                                    value={selectedCountry}
                                                    options={countriesData.map(country => ({ value: country.id, label: country.name }))}
                                                    onChange={handleCountryChange}
                                                    placeholder="Search Country"
                                                />
                                                {errors.country_id && <div className="text-danger">{errors.country_id}</div>}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="inputState" className="font-weight-bold text-muted text-uppercase">State/Region</Form.Label>
                                                <Select
                                                    classNamePrefix="my-select"
                                                    id="customerSelect"
                                                    className='custom-select-container'
                                                    value={selectedState}
                                                    options={statesData.map(state => ({ value: state.id, label: state.name }))}
                                                    onChange={handleStateChange}
                                                    placeholder="Search State"
                                                />

                                                {errors.state_id && <div className="text-danger">{errors.state_id}</div>}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text6" className="font-weight-bold text-muted text-uppercase">Address</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Text6"
                                                    name="address"
                                                    value={formData.address}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Address"
                                                />
                                                {errors.address && <div className="text-danger">{errors.address}</div>}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text7" className="font-weight-bold text-muted text-uppercase">Zipcode</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Text7"
                                                    name="zipcode"
                                                    value={formData.zipcode}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Zipcode"
                                                />
                                                {errors.zipcode && <div className="text-danger">{errors.zipcode}</div>}
                                            </div>
                                        </Form>

                                        <div className="d-flex justify-content-end mt-3">
                                            <Button
                                                variant="btn btn-primary"
                                                onClick={handleAddCustomer}
                                                disabled={isLoading} // Disable the button when isLoading is true
                                            >
                                                {isLoading ? 'Adding...' : 'Add Customer'}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container >

        </>
    )
}

export default Customeradd;