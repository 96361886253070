import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import noImage from '../../../assets/images/no-image.png';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { logoutUser, resetTwoFactor, twoFactorStatusChange, updateAdminProfile } from '../../../store/actions';
import $ from 'jquery';

const AdminAccountSettings = ({ user }) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [userImagePreview, setUserImagePreview] = useState(null);
    const [image, setimage] = useState('');
    const [name, setname] = useState('');
    const [surname, setsurname] = useState('');
    const [email, setemail] = useState('');
    const [phone, setphone] = useState('');
    const [isProfileDataUpdating, setIsProfileDataUpdating] = useState(false);
    const [isResetCodeLoading, setIsResetCodeLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [twoFA, setTwoFA] = useState('');
    const [twoFAChecked, setTwoFAChecked] = useState(null);
    const [isTwoFALoading, setIsTwoFALoading] = useState(false);
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [passwordData, setPasswordData] = useState({
        id: user?.user?.user?.user?.userModel?.id,
        password: '',
        password_confirmation: ''
    });
    const token = user?.user?.user?.token;
    const userData = user?.user?.user?.user?.userModel;
    const qr = user?.user?.user?.qr;
    const secretKey = user?.user?.user?.secret;

    useEffect(() => {
        setname(user?.user?.user?.user?.userModel?.name);
        setsurname(user?.user?.user?.user?.userModel?.surname);
        setemail(user?.user?.user?.user?.userModel?.email);
        setphone(user?.user?.user?.user?.userModel?.phone);
        setUserImagePreview(user?.user?.user?.user?.userModel?.image !== null && user?.user?.user?.user?.userModel?.image !== '' ? process.env.REACT_APP_BACKEND_URL + user?.user?.user?.user?.userModel?.image : noImage);
        setTwoFAChecked(user?.user?.user?.user?.userModel?.allow_2fa);
    }, []);

    const handleImageChange = (event) => {
        const selectedUserImage = event.target.files[0];
        if (selectedUserImage) {
            setimage(selectedUserImage);
            setUserImagePreview(URL.createObjectURL(selectedUserImage));
        }
    };

    const onUpdateProfileData = (e) => {
        e.preventDefault();

        const validate = checkProfileDataValidation();

        if (validate) {
            setIsProfileDataUpdating(true);

            const headers = {
                Authorization: `Bearer ${token}`,
            }

            const formData = new FormData();
            formData.append("id", userData.id);
            formData.append("name", name);
            formData.append("surname", surname);
            formData.append("phone", phone);
            formData.append("image", image);

            axios.post(process.env.REACT_APP_API_URL + '/update-admin-profile', formData, { headers })
                .then((res) => {
                    setIsProfileDataUpdating(false);
                    if (res.data.status) {
                        dispatch(updateAdminProfile(res?.data?.data));
                        Swal.fire('Success!', res.data.message, 'success');
                    } else {
                        Swal.fire('Ooops!', res.data.message, 'error');
                    }
                })
                .catch((err) => {
                    console.log(err);

                    if (err.response.status === 401) {
                        dispatch(logoutUser());
                    }

                    setIsProfileDataUpdating(false);
                    Swal.fire('Ooops!', err.response.data.message, 'error');
                })
        }
    }

    const checkProfileDataValidation = () => {
        const validationErrors = {};

        if (!name) {
            validationErrors.name = 'Please enter name'
        }

        if (!surname) {
            validationErrors.surname = 'Please enter surname'
        }

        if (!phone) {
            validationErrors.phone = 'Please enter phone number'
        } else if (phone.length > 14) {
            validationErrors.phone = 'Phone must not be greater than 14 digits'
        }

        if (image !== null && image !== '') {
            if (image.size > 5000000) {
                validationErrors.image = 'Maximum file size exceeds.';
            }
        }

        // If there are validation errors, update the state and return
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return false;
        } else {
            return true;
        }
    }

    const resetCodeForTwoFA = (e) => {
        e.preventDefault();
        setIsResetCodeLoading(true);

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        axios.post(process.env.REACT_APP_API_URL + '/reset-two-factor', { id: userData?.id }, { headers })
            .then((res) => {
                setIsResetCodeLoading(false);
                if (res.data.status) {
                    dispatch(resetTwoFactor(res.data.data));
                    setShowModal(true);
                } else {
                    Swal.fire('Ooops!', res.data.message, 'error');
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401) {
                    dispatch(logoutUser());
                }
                setIsResetCodeLoading(false);
                Swal.fire('Ooops!', err.response.data.message, 'error');
            });
    }

    const twoFactorChangeHandler = (e) => {
        if (e.target.checked) {
            setTwoFA("ON");
            setTwoFAChecked(1);
        } else {
            setTwoFA("OFF");
            setTwoFAChecked(0);
        }
    }

    const twoFactorUpdateHandler = (e) => {
        e.preventDefault();
        if (user.user.user.user.userModel.allow_2fa !== twoFAChecked) {
            setIsTwoFALoading(true);

            const headers = {
                Authorization: `Bearer ${token}`,
            }

            axios.post(process.env.REACT_APP_API_URL + '/change-status-two-factor', { id: userData?.id, twoFA: twoFA }, { headers })
                .then((res) => {
                    setIsTwoFALoading(false);
                    if (res.data.status) {
                        dispatch(twoFactorStatusChange(res.data.data));
                        Swal.fire('Success!', res.data.message, 'success');
                    } else {
                        Swal.fire('Ooops!', res.data.message, 'error');
                    }
                })
                .catch((err) => {
                    console.log(err);

                    if (err.response.status === 401) {
                        dispatch(logoutUser());
                    }

                    setIsTwoFALoading(false);
                    Swal.fire('Ooops!', err.response.data.message, 'error');
                });
        }
    }

    const passwordChangeHandler = (e) => {
        setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
    }

    const passwordUpddateHandler = (e) => {
        e.preventDefault();
        const validate = checkValidation();

        if (validate) {
            setIsChangePassword(true);

            const headers = {
                Authorization: `Bearer ${token}`,
            }

            axios.post(process.env.REACT_APP_API_URL + '/change-password', { id: passwordData.id, password: passwordData.password, password_confirmation: passwordData.password_confirmation }, { headers })
                .then((res) => {
                    setIsChangePassword(false);
                    if (res.data.status) {
                        Swal.fire('Success!', res.data.message, 'success');
                        setPasswordData({
                            id: user.user.user.user.userModel.id,
                            password: '',
                            password_confirmation: ''
                        });
                    } else {
                        Swal.fire('Ooops!', res.data.message, 'error');
                    }
                })
                .catch((err) => {
                    setIsChangePassword(false);

                    if (err.response.status === 401) {
                        dispatch(logoutUser());
                    }

                    if (Object.keys(err.response.data.errors).length > 0) {
                        var errors = "";
                        $.each(err.response.data.errors, function (index, value) {
                            if (value.length > 1) {
                                $.each(value, function (index, error) {
                                    errors += `${error}\n`;
                                })
                            } else {
                                errors += `${value}\n`;
                            }
                        });
                        Swal.fire('Ooops!', errors, 'error');
                    } else {
                        Swal.fire('Ooops!', err.response.data.message, 'error');
                    }
                });
        }
    }

    const checkValidation = () => {
        const validationError = {};

        if (passwordData.password == null || passwordData.password == '') {
            validationError.password = 'Please enter new password';
        } else if (passwordData.password.length < 8) {
            validationError.password = 'New password must be 8 digits long';
        } else if (passwordData.oldPassword == passwordData.password) {
            validationError.password = 'The password and old password must be different.';
        }

        if (passwordData.password_confirmation == null || passwordData.password_confirmation == '') {
            validationError.password_confirmation = 'Please enter confirm password';
        } else if (passwordData.password_confirmation.length < 8) {
            validationError.password_confirmation = 'Confirm password must be 8 digits long';
        } else if (passwordData.password_confirmation !== passwordData.password) {
            validationError.password_confirmation = 'The password confirmation does not match.'
        }

        if (Object.keys(validationError).length > 0) {
            setErrors(validationError);
            return false;
        } else {
            setErrors({});
            return true;
        }
    }

    const modalCloseHandler = () => {
        setShowModal(false);
    }

    return (
        <section>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>
                                    Edit Profile Information
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row className="form-group  align-items-center">
                                    <Col md="4">
                                        <div className="profile-img-edit">
                                            <div className="crm-profile-img-edit">
                                                <p>Profile Image</p>
                                                <div className={errors.image ? 'imageBox is-invalid' : 'imageBox'}>
                                                    <img className="crm-profile-pic rounded-circle avatar-100" src={userImagePreview ? userImagePreview : noImage} alt="profile-pic" />
                                                    <div className="crm-p-image bg-primary bg-theme">
                                                        <label htmlFor="userImageUpload" className="upload-label" style={{ cursor: 'pointer' }}>
                                                            <i className="las la-pen upload-button"></i>
                                                        </label>
                                                        <input type="file" accept="image/*" id="userImageUpload" onChange={handleImageChange} style={{ display: 'none' }} />
                                                    </div>
                                                </div>
                                                {errors.image ? <div className="invalid-feedback mt-3">{errors.image}</div> : null}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col md={12}>
                                        <Form className="row g-3 date-icon-set-modal">
                                            <div className="col-md-4 mb-3">
                                                <Form.Label htmlFor="name" className="font-weight-bold text-muted text-uppercase">Name</Form.Label>
                                                <Form.Control type="text" id="name" placeholder="Name" className={errors.name ? 'is-invalid' : ''} name="name" value={name} onChange={(e) => setname(e.target.value)} />
                                                {errors.name ? <div className="invalid-feedback">{errors.name}</div> : null}
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <Form.Label htmlFor="surname" className="font-weight-bold text-muted text-uppercase">Surname</Form.Label>
                                                <Form.Control type="text" id="surname" placeholder="Surname" className={errors.surname ? 'is-invalid' : ''} name="surname" value={surname} onChange={(e) => setsurname(e.target.value)} />
                                                {errors.surname ? <div className="invalid-feedback">{errors.surname}</div> : null}
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <Form.Label htmlFor="email" className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                                <Form.Control type="email" id="email" placeholder="Enter Email" className={errors.email ? 'is-invalid' : ''} name="email" value={email} disabled={true} />
                                                {errors.email ? <div className="invalid-feedback">{errors.email}</div> : null}
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <Form.Label htmlFor="phone" className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                <Form.Control type="tel" id="phone" placeholder="Enter Phone" className={errors.phone ? 'is-invalid' : ''} name="phone" value={phone} onChange={(e) => setphone(e.target.value)} />
                                                {errors.phone ? <div className="invalid-feedback">{errors.phone}</div> : null}
                                            </div>

                                            <div className="col-md-12">
                                                {isProfileDataUpdating ?
                                                    <div className="loader-box float-right">
                                                        <Spinner animation="border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </Spinner>
                                                    </div> :
                                                    <Button type="button" className="loginBtn float-right" variant="primary" onClick={onUpdateProfileData}>Update</Button>
                                                }
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Setup 2-Factor Authentication</Card.Title>
                                {isResetCodeLoading ?
                                    <div className="loader-box">
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div> :
                                    <Button type="button" variant="btn btn-primary mt-2 loginBtn" onClick={resetCodeForTwoFA}>Reset Code</Button>
                                }
                            </Card.Header>
                            <Card.Body>
                                <p>You have to download <strong>Google Authenticator</strong> from <strong>Playstore</strong> or <strong>Appstore</strong> in your mobile phone.</p>
                                <div className="custom-control custom-switch custom-control-inline">
                                    <Form.Check.Input bsPrefix="custom-control-input" id="customSwitch2" defaultChecked={user.user.user.user.userModel.allow_2fa ? true : false} onChange={twoFactorChangeHandler} />
                                    <Form.Check.Label bsPrefix="custom-control-label" htmlFor="customSwitch2">Enable</Form.Check.Label>
                                </div>
                                {isTwoFALoading ?
                                    <div className="loader-box float-right">
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div> :
                                    <Button type="button" className="float-right" onClick={twoFactorUpdateHandler} variant="btn btn-primary mt-2 loginBtn">Update</Button>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Change Password</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row className="align-items-center mt-1">
                                    <Col md={3}>
                                        <p><strong>New Password</strong></p>
                                    </Col>
                                    <Col md={9}>
                                        <Form.Control name="password" type="password" className={errors.password ? 'is-invalid' : ''} onChange={passwordChangeHandler} value={passwordData.password} placeholder="Enter New Password" />
                                        {errors.password ? <div className="invalid-feedback">{errors.password}</div> : null}
                                    </Col>
                                </Row>
                                <Row className="align-items-center mt-1">
                                    <Col md={3}>
                                        <p><strong>Confirm Password</strong></p>
                                    </Col>
                                    <Col md={9}>
                                        <Form.Control name="password_confirmation" type="password" className={errors.password_confirmation ? 'is-invalid' : ''} onChange={passwordChangeHandler} value={passwordData.password_confirmation} placeholder="Enter Confirm Password" />
                                        {errors.password_confirmation ? <div className="invalid-feedback">{errors.password_confirmation}</div> : null}
                                    </Col>
                                </Row>
                                {isChangePassword ?
                                    <div className="loader-box float-right">
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div> :
                                    <Button type="button" className="float-right" onClick={passwordUpddateHandler} variant="btn btn-primary mt-2 loginBtn">Update</Button>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={showModal} onHide={modalCloseHandler}>
                <Modal.Header>
                    <Modal.Title as="h5">Scan QR Code</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={modalCloseHandler}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Row className="align-items-center justify-content-center h-100">
                        <Col md={12}>
                            <Card>
                                <h4 className="card-heading text-center mt-4">Set up Google Authenticator</h4>
                                <Card.Body className="card-body" style={{ textAlign: 'center' }}>
                                    <p>Set up your two factor authentication by scanning the QR Code below. Alternatively, you can use the code: <strong>{secretKey}</strong></p>
                                    <div dangerouslySetInnerHTML={{ __html: qr }}></div>
                                    <p>You have to download <strong>Google Authenticator</strong> from <strong>Playstore</strong> or <strong>Appstore</strong> in your mobile phone.</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="loginBtn" onClick={modalCloseHandler}>Close</Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}

export default AdminAccountSettings;
