import React, { useEffect, useState } from 'react'
import { Container, Col, Row, Form, Button, Spinner } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import Card from '../../../components/Card'
import { connect } from "react-redux";
import { getDarkMode } from '../../../store/mode'
import axios from 'axios';
import Swal from 'sweetalert2';
//img
import logo from '../../../assets/images/logo.png'
import { registrationSuccess } from '../../../store/actions';
// import darklogo from '../../../assets/images/logo-dark.png'
import logo2 from '../../../assets/images/logo2.png';


function mapStateToProps(state) {
   return {
      darkMode: getDarkMode(state)
   };
}

const SignUp = (props) => {
   let history = useHistory();

   const [errors, setErrors] = useState({});
   const [countries, setCountries] = useState([]);
   const [states, setStates] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [name, setName] = useState('');
   const [surname, setSurname] = useState('');
   const [companyName, setCompanyName] = useState('');
   const [phone, setPhone] = useState('');
   const [email, setEmail] = useState('');
   const [ivaNumber, setIVANumber] = useState('');
   const [country, setCountry] = useState('');
   const [state, setState] = useState('');
   const [city, setCity] = useState('');
   const [zipCode, setZipCode] = useState('');
   const [address, setAddress] = useState('');
   const [password, setPassword] = useState('');
   const [confirmPassword, setConfirmPassword] = useState('');
   const [companyRegistration, setCompanyRegistration] = useState(null);
   const [companyRegistrationName, setCompanyRegistrationName] = useState(null);
   const [residencePermit, setResidencePermit] = useState(null);
   const [residencePermitName, setResidencePermitName] = useState(null);

   useEffect(() => {
      getCountries();
      document.title = 'Sign Up | 2G Connect';
   }, []);

   useEffect(() => {
      if (country !== '') {
         getStates();
      }
   }, [country]);

   const getCountries = () => {
      axios.get(process.env.REACT_APP_API_URL + '/get-all-countries')
         .then((res) => {
            if (res.status) {
               setCountries(res.data.data);
            }
         })
         .catch((err) => {
            console.log(err);
            Swal.fire('Ooops!', err.response.data.message, 'error');
         })
   }

   const getStates = () => {
      axios.post(process.env.REACT_APP_API_URL + '/get-states-by-country-id', { countryId: country })
         .then((res) => {
            if (res.data.status) {
               setStates(res.data.data);
            } else {
               Swal.fire('Ooops!', res.data.message, 'error');
            }
         })
         .catch((err) => {
            console.log(err);
            Swal.fire('Ooops!', err.response.data.message, 'error');
         })
   }

   const handleSubmit = event => {
      event.preventDefault();
      setIsLoading(true);

      // Validate
      const validate = checkValidation();

      // If validation passes, attempt registration
      if (validate) {
         const formData = new FormData();

         formData.append("name", name);
         formData.append("surname", surname);
         formData.append("companyName", companyName);
         formData.append("phone", phone);
         formData.append("email", email);
         formData.append("ivaNumber", ivaNumber);
         formData.append("country", country);
         formData.append("state", state);
         formData.append("city", city);
         formData.append("zipCode", zipCode);
         formData.append("address", address);
         formData.append("password", password);
         formData.append("companyRegistration", companyRegistration);
         formData.append("residencePermit", residencePermit);

         axios.post(process.env.REACT_APP_API_URL + '/register', formData)
            .then(res => {
               // Handle successful register, for example dispatch a redux action
               // and navigate to the QR image
               if (res.data.status) {
                  setIsLoading(false);
                  props.dispatchRegisterSuccess(res.data.data);
                  // history.push('/auth/2FA');
                  history.push('/auth/verify-email');
               } else {
                  setIsLoading(false);
                  Swal.fire('Ooops!', res.data.message, 'error');
               }
            })
            .catch(err => {
               console.log(err);
               setIsLoading(false);
               Swal.fire('Ooops!', err.response.data.message, 'error');
            });
      } else {
         setIsLoading(false);
      }
   };

   const checkValidation = () => {
      const validationErrors = {};

      if (!name) {
         validationErrors.name = 'Please enter name'
      }

      if (!surname) {
         validationErrors.surname = 'Please enter surname'
      }

      if (!companyName) {
         validationErrors.companyName = 'Please enter company name'
      }

      if (!phone) {
         validationErrors.phone = 'Please enter phone number'
      } else if (phone.length > 14) {
         validationErrors.phone = 'Phone must not be greater than 14 digits'
      }

      if (!email) {
         validationErrors.email = 'Please enter email address';
      } else if (!email.match(/^\S+@\S+\.\S+$/)) {
         validationErrors.email = 'Email address is not valid';
      }

      if (!ivaNumber) {
         validationErrors.ivaNumber = 'Please enter IVA number'
      } else if (ivaNumber.length < 11) {
         validationErrors.ivaNumber = 'IVA number must be at least 11 digits long'
      } else if (ivaNumber.length > 11) {
         validationErrors.ivaNumber = 'IVA number must not be greater than 11 digits'
      }

      if (!country) {
         validationErrors.country = 'Please select country'
      }

      if (!state) {
         validationErrors.state = 'Please select state'
      }

      if (!city) {
         validationErrors.city = 'Please enter city'
      }

      if (!zipCode) {
         validationErrors.zipCode = 'Please enter zip code'
      }

      if (!address) {
         validationErrors.address = 'Please enter address'
      }

      if (!password) {
         validationErrors.password = 'Please enter password';
      } else if (password.length < 8) {
         validationErrors.password = 'Password must be at least 8 characters long';
      }

      if (!confirmPassword) {
         validationErrors.confirmPassword = 'Please enter confirm password';
      } else if (confirmPassword.length < 8) {
         validationErrors.confirmPassword = 'Confirm Password must be at least 8 characters long';
      }

      if (companyRegistration !== null && companyRegistration !== '') {
         if (companyRegistration.size > 5000000) {
            validationErrors.companyRegistration = 'Maximum file size exceeds.';
         }
      } else if (companyRegistration == null || companyRegistration == '') {
         validationErrors.companyRegistration = 'Company Registration is required.';
      }

      if (residencePermit !== null && residencePermit !== '') {
         if (residencePermit.size > 5000000) {
            validationErrors.residencePermit = 'Maximum file size exceeds.';
         }
      } else if (residencePermit == null || residencePermit == '') {
         validationErrors.residencePermit = 'Residence Permit is required.';
      }

      // If there are validation errors, update the state and return
      if (Object.keys(validationErrors).length > 0) {
         setErrors(validationErrors);
         return false;
      } else {
         return true;
      }
   }

   const handleInputChange = (e) => {

      const { name, value } = e.target;

      setErrors({
         ...errors,
         [name]: ''
      });

      if (name === 'name') {
         setName(value);
      } else if (name === 'surname') {
         setSurname(value);
      } else if (name === 'companyName') {
         setCompanyName(value);
      } else if (name === 'phone') {
         setPhone(value);
      } else if (name === 'phone') {
         setPhone(value);
      } else if (name === 'email') {
         setEmail(value);
      } else if (name === 'ivaNumber') {
         setIVANumber(value);
      } else if (name === 'country') {
         setCountry(value);
      } else if (name === 'state') {
         setState(value);
      } else if (name === 'city') {
         setCity(value);
      } else if (name === 'zipCode') {
         setZipCode(value);
      } else if (name === 'address') {
         setAddress(value);
      } else if (name === 'password') {
         setPassword(value);
      } else if (name === 'confirmPassword') {
         setConfirmPassword(value);
      }
   };

   const handleCompanyRegistrationFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
         setCompanyRegistration(file);
         setCompanyRegistrationName(file.name);
      }
   }

   const handleResidencePermitFileChange = (e) => {
      const file = e.target.files[0];
      console.log(file);
      if (file) {
         setResidencePermit(file);
         setResidencePermitName(file.name);
      }
   }

   return (
      <>
         <section className="login-content">
            <Container className="h-100">
               <Row className="align-items-center justify-content-center h-100">
                  <Col md="7">
                     <Card className="p-3">
                        <Card.Body>
                           <div className="auth-logo">
                              <img src={logo} className={`img-fluid  rounded-normal  ${!props.darkMode ? 'd-none' : ''}`} alt="logo" />
                              {/* <img src={darklogo} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} alt="logo" /> */}
                              <img src={logo} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} alt="logo" />
                              <img src={logo2} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} alt="logo" style={{ height: '40px', marginTop: '15px', marginLeft: '15px' }} />
                           </div>
                           <h3 className="mb-3 font-weight-bold text-center">Getting Started</h3>
                           <Form>
                              <Row>
                                 <Col lg="6">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">Name</Form.Label>
                                       <Form.Control type="text" name="name" className={errors.name ? 'is-invalid' : ''} onChange={handleInputChange} placeholder="ex: John" />
                                       {errors.name ? <div className="invalid-feedback">{errors.name}</div> : null}
                                    </Form.Group>
                                 </Col>
                                 <Col lg="6">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">Surname</Form.Label>
                                       <Form.Control type="text" name="surname" className={errors.surname ? 'is-invalid' : ''} onChange={handleInputChange} placeholder="ex: Doe" />
                                       {errors.surname ? <div className="invalid-feedback">{errors.surname}</div> : null}
                                    </Form.Group>
                                 </Col>
                                 <Col lg="6" className="mt-2">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">Company Name</Form.Label>
                                       <Form.Control type="text" name="companyName" className={errors.companyName ? 'is-invalid' : ''} onChange={handleInputChange} placeholder="ex: Example Inc." />
                                       {errors.companyName ? <div className="invalid-feedback">{errors.companyName}</div> : null}
                                    </Form.Group>
                                 </Col>
                                 <Col lg="6" className="mt-2">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">Phone</Form.Label>
                                       <Form.Control type="tel" name="phone" className={errors.phone ? 'is-invalid' : ''} onChange={handleInputChange} placeholder="ex: +1 (234) 567 890" />
                                       {errors.phone ? <div className="invalid-feedback">{errors.phone}</div> : null}
                                    </Form.Group>
                                 </Col>
                                 <Col lg="6" className="mt-2">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">Email</Form.Label>
                                       <Form.Control type="email" name="email" className={errors.email ? 'is-invalid' : ''} onChange={handleInputChange} placeholder="ex: example@example.com" />
                                       {errors.email ? <div className="invalid-feedback">{errors.email}</div> : null}
                                    </Form.Group>
                                 </Col>
                                 {/* <Col lg="6" className="mt-2">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">Tax ID code</Form.Label>
                                       <Form.Control type="number" placeholder="Enter Tax ID code" />
                                    </Form.Group>
                                 </Col> */}
                                 <Col lg="6" className="mt-2">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">Personal IVA Number</Form.Label>
                                       <Form.Control type="text" name="ivaNumber" className={errors.ivaNumber ? 'is-invalid' : ''} onChange={handleInputChange} placeholder="ex: IT123456789" />
                                       {errors.ivaNumber ? <div className="invalid-feedback">{errors.ivaNumber}</div> : null}
                                    </Form.Group>
                                 </Col>
                                 <Col lg="6" className="mt-2">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">Select Country</Form.Label>
                                       <Form.Control as="select" name="country" className={errors.country ? 'is-invalid' : ''} onChange={handleInputChange} defaultValue={""}>
                                          <option value="" disabled={true}>Select Country</option>
                                          {countries.length > 0 ? countries.map((country, index) => {
                                             return (
                                                <option key={index} value={country.id}>{country.name}</option>
                                             )
                                          }) : null}
                                       </Form.Control>
                                       {errors.country ? <div className="invalid-feedback">{errors.country}</div> : null}
                                    </Form.Group>
                                 </Col>
                                 <Col lg="6" className="mt-2">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">Select State</Form.Label>
                                       <Form.Control as="select" name="state" className={errors.state ? 'is-invalid' : ''} onChange={handleInputChange} defaultValue={""}>
                                          <option value="" disabled={true}>Select State</option>
                                          {states.length > 0 ? states.map((state, index) => {
                                             return (
                                                <option key={index} value={state.id}>{state.name}</option>
                                             )
                                          }) : null}
                                       </Form.Control>
                                       {errors.state ? <div className="invalid-feedback">{errors.state}</div> : null}
                                    </Form.Group>
                                 </Col>
                                 <Col lg="6" className="mt-2">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">City</Form.Label>
                                       <Form.Control type="text" name="city" className={errors.city ? 'is-invalid' : ''} onChange={handleInputChange} placeholder="ex: Milano" />
                                       {errors.city ? <div className="invalid-feedback">{errors.city}</div> : null}
                                    </Form.Group>
                                 </Col>
                                 <Col lg="6" className="mt-2">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">Zip Code</Form.Label>
                                       <Form.Control type="number" name="zipCode" className={errors.zipCode ? 'is-invalid' : ''} onChange={handleInputChange} placeholder="ex: 12345" />
                                       {errors.zipCode ? <div className="invalid-feedback">{errors.zipCode}</div> : null}
                                    </Form.Group>
                                 </Col>
                                 <Col lg="6" className="mt-2">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">Password</Form.Label>
                                       <Form.Control type="password" name="password" className={errors.password ? 'is-invalid' : ''} onChange={handleInputChange} placeholder="Enter Password" />
                                       {errors.password ? <div className="invalid-feedback">{errors.password}</div> : null}
                                    </Form.Group>
                                 </Col>
                                 <Col lg="6" className="mt-2">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">Confirm Password</Form.Label>
                                       <Form.Control type="password" name="confirmPassword" className={errors.confirmPassword ? 'is-invalid' : ''} onChange={handleInputChange} placeholder="Enter Confirm Password" />
                                       {errors.confirmPassword ? <div className="invalid-feedback">{errors.confirmPassword}</div> : null}
                                    </Form.Group>
                                 </Col>
                                 <Col lg="12" className="mt-2">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">Shop Address</Form.Label>
                                       <Form.Control type="text" name="address" className={errors.address ? 'is-invalid' : ''} onChange={handleInputChange} placeholder="ex: 123, My Street, My City, My State 12345." />
                                       {errors.address ? <div className="invalid-feedback">{errors.address}</div> : null}
                                    </Form.Group>
                                 </Col>
                                 <Col lg="6" className="mt-2">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">Company Registration <small>(Max: 5MBs)</small></Form.Label>
                                       <div className="custom-file">
                                          <Form.Control type="file" name="companyRegistration" className={errors.companyRegistration ? 'is-invalid custom-file-input' : 'custom-file-input'} onChange={handleCompanyRegistrationFileChange} />
                                          <Form.Label className="text-secondary custom-file-label">{companyRegistrationName !== null ? companyRegistrationName : 'Choose File'}</Form.Label>
                                          {errors.companyRegistration ? <div className="invalid-feedback">{errors.companyRegistration}</div> : null}
                                       </div>
                                    </Form.Group>
                                 </Col>
                                 <Col lg="6" className="mt-2">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">ID / Residence Permit <small>(Max: 5MBs)</small></Form.Label>
                                       <div className="custom-file">
                                          <Form.Control type="file" name="residencePermit" className={errors.residencePermit ? 'is-invalid custom-file-input' : 'custom-file-input'} onChange={handleResidencePermitFileChange} />
                                          <Form.Label className="text-secondary custom-file-label">{residencePermitName !== null ? residencePermitName : 'Choose File'}</Form.Label>
                                          {errors.residencePermit ? <div className="invalid-feedback">{errors.residencePermit}</div> : null}
                                       </div>
                                    </Form.Group>
                                 </Col>
                                 {/* {companyRegistrationPreview ?
                                    <Col lg="6" className="mt-2">
                                       <div className="imagePreview">
                                          <img src={companyRegistrationPreview} />
                                       </div>
                                    </Col> : null
                                 }
                                 {residencePermitPreview ?
                                    <Col lg="6" className="mt-2">
                                       <div className="imagePreview">
                                          <img src={residencePermitPreview} />
                                       </div>
                                    </Col> : null
                                 } */}
                                 <Col lg="12" className="mt-2">
                                    <Form.Check className="form-check-inline">
                                       <div className="custom-control custom-checkbox custom-control-inline mb-3">
                                          <Form.Check.Input type="checkbox" className="custom-control-input m-0 bgColor" id="inlineCheckbox1" />
                                          <Form.Check.Label className="custom-control-label pl-2" htmlFor="inlineCheckbox1">I agree to the <Link to="terms-of-service.html" className="linkColor">Terms of Service </Link> and <Link to="privacy-policy.html" className="linkColor">Privacy Policy</Link></Form.Check.Label>
                                       </div>
                                    </Form.Check>
                                 </Col>
                              </Row>
                              {isLoading ?
                                 <div className="loader-box">
                                    <Spinner animation="border" role="status">
                                       <span className="sr-only">Loading...</span>
                                    </Spinner>
                                 </div> :
                                 <Button type="button" onClick={handleSubmit} variant="btn btn-primary btn-block mt-2 loginBtn">Create Account</Button>
                              }
                              <div className="col-lg-12 mt-3">
                                 <p className="mb-0 text-center">Do you have an account? <Link to="/" className="linkColor">Log In</Link></p>
                              </div>
                           </Form>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </section>
      </>
   )
}

const mapDispatchToProps = (dispatch) => ({
   dispatchRegisterSuccess: (userData) => dispatch(registrationSuccess(userData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);