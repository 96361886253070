import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, OverlayTrigger, Tooltip, Spinner, Badge } from 'react-bootstrap'
import Card from '../../../components/Card'
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import './tablesearch.css';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../store/actions';
// img


const PendingUsers = ({ user }) => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const dispatch = useDispatch();

    const isMounted = useRef(true);

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {

        // Initialize the DataTable when categories change
        if (!isLoading && users.length > 0) {
            if (!$.fn.DataTable.isDataTable(dataTableRef.current)) {
                // If not initialized, initialize the DataTable
                $(dataTableRef.current).DataTable({

                    columns: [
                        { data: 'id', title: 'ID' },
                        { data: 'name', title: 'Name' },
                        { data: 'surname', title: 'Surname' },
                        { data: 'email', title: 'Email' },
                        { data: 'phone', title: 'Phone' },
                        { data: 'status', title: 'Status' },
                        { data: '', title: 'Action' },
                    ],
                    order: [[0, 'desc']],
                });
            }
        }

        $(dataTableRef.current).on('click', '.status-button', function () {
            const userId = $(this).data('user-id');
            history.push('/user-details/' + btoa(userId));
        });
    }, [users, isLoading]);

    const dataTableRef = useRef(null);

    const getUsers = () => {

        const token = user.user.user.token;

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        axios.get(process.env.REACT_APP_API_URL + '/get-pending-users', { headers })
            .then((response) => {
                setUsers(response.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching customers:', error);

                if (error.response.status === 401) {
                    dispatch(logoutUser());
                }

                setIsLoading(false);
            });
    };

    const changeUserStatus = (userId) => {

        const token = user.user.user.token;

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        Swal.fire({
            title: 'Approve User',
            text: 'Are you sure you want to approve this user?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f8e22',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, approve it',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(process.env.REACT_APP_API_URL + '/change-user-status', { id: userId, status: 1 }, { headers })
                    .then((response) => {
                        if (response.status) {
                            getUsers();
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching customers:', error);

                        if (error.response.status === 401) {
                            dispatch(logoutUser());
                        }

                        setIsLoading(false);
                    });
            }
        })

    }

    // const handleDelete = (customerId) => {
    //     Swal.fire({
    //         title: 'Delete Customer',
    //         text: 'Are you sure you want to delete this customer?',
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#d33',
    //         cancelButtonColor: '#3085d6',
    //         confirmButtonText: 'Yes, delete it',
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             const token = user.user.user.token
    //             axios
    //                 .delete(process.env.REACT_APP_API_URL + `/companies/${user.user.user.companyDetails.id}/customers/${customerId}`, {
    //                     headers: {
    //                         Authorization: `Bearer ${token}`,
    //                     },
    //                 })
    //                 .then((response) => {
    //                     if (response.data.status) {
    //                         Swal.fire('Done!', response.data.message, 'success').then(() => {
    //                             setUsers((prevCustomers) => prevCustomers.filter((customer) => customer.id !== customerId));
    //                         });
    //                     } else {
    //                         Swal.fire('Ooopsss!', response.data.message, 'error');
    //                     }
    //                 })
    //                 .catch((error) => {
    //                     Swal.fire('Ooopsss!', error.response.data.message, 'error');
    //                 });
    //         }
    //     });
    // };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold">Pending Users</h4>
                            </div>
                        </div>
                        <Row>
                            <Col lg="12">
                                <Card className="card-block card-stretch">
                                    <Card.Body className="p-0">
                                        <div className="d-flex justify-content-between align-items-center p-3">
                                            <h5 className="font-weight-bold">Pending Users List</h5>

                                        </div>
                                        <div className="table-responsive">
                                            {isLoading ? (
                                                <div className="text-center mb-5">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <table ref={dataTableRef} className="table data-table mb-0">
                                                    <thead className="table-color-heading text-left">
                                                        <tr className="">
                                                            <th scope="col">
                                                                Id
                                                            </th>
                                                            <th scope="col">
                                                                Name
                                                            </th>
                                                            <th scope="col">
                                                                Surname
                                                            </th>
                                                            <th scope="col">
                                                                Email
                                                            </th>
                                                            <th scope="col">
                                                                Phone
                                                            </th>
                                                            <th scope="col">
                                                                Status
                                                            </th>
                                                            <th scope="col">
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {users.map((user, index) => {
                                                            return (
                                                                <tr key={index} className="white-space-no-wrap">
                                                                    <td>{user.id}</td>
                                                                    <td className="">
                                                                        <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                                            <div className="data-content">
                                                                                <div>
                                                                                    <span className="font-weight-bold">{user.name}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{user.surname}</td>
                                                                    <td>{user.email}</td>
                                                                    <td>{user.phone}</td>
                                                                    <td>{<Badge pill className="bg-warning">Pending</Badge>}</td>
                                                                    <td>
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>View User</Tooltip>}>
                                                                            <button className="text-info status-button" data-user-id={user.id}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                                                </svg>
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row >
            </Container >

        </>

    )
}
export default PendingUsers;