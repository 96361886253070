import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, OverlayTrigger, Tooltip, Spinner, Badge, Button, Dropdown, Modal } from 'react-bootstrap'
import Card from '../../../components/Card'
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import './tablesearch.css';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../store/actions';
import { useHistory } from 'react-router-dom';
import DownloadExcel from './DownloadExcel';
// img


const Users = ({ user }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const dataTableRef = useRef(null);
    const [users, setUsers] = useState([]);
    const [downloadData, setDownloadData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [filterData, setFilterData] = useState({
        id: '',
        name: '',
        surname: '',
        email: '',
        phone: '',
        company: '',
        city: '',
        status: ''
    });

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {

        // Initialize the DataTable when categories change
        if (!isLoading && users.length > 0) {
            if (!$.fn.DataTable.isDataTable(dataTableRef.current)) {
                // If not initialized, initialize the DataTable
                $(dataTableRef.current).DataTable({

                    columns: [
                        { data: 'id', title: 'ID' },
                        { data: 'name', title: 'Name' },
                        { data: 'surname', title: 'Surname' },
                        { data: 'email', title: 'Email' },
                        { data: 'phone', title: 'Phone' },
                        { data: 'company_name', title: 'Name of Company' },
                        { data: 'city', title: 'City' },
                        { data: 'status', title: 'Status' },
                        { data: '', title: 'Action' },
                    ],
                    order: [[0, 'desc']],
                    buttons: [
                        'copy', 'excel', 'pdf'
                    ]
                });
            }
        }

        $(dataTableRef.current).on('click', '.status-button', function () {
            const userId = $(this).data('user-id');
            // const userStatus = $(this).data('user-status');
            // changeUserStatus(userId, userStatus);
            history.push('/user-details/' + btoa(userId));
        });

        const userData = [];
        users.map((user, index) => {
            userData.push({
                'Id': user.id,
                'Name': user.name,
                'Surname': user.surname,
                'Email': user.email,
                'Phone': user.phone,
                'Name of Company': user?.user_details?.company_name,
                'City': user?.user_details?.city,
                'Status': user.is_active ? 'active' : 'deactive',
            });
        });
        setDownloadData(userData);
    }, [users, isLoading]);

    const getUsers = () => {
        setUsers([]);
        setIsLoading(true);
        const token = user.user.user.token;

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        axios.post(process.env.REACT_APP_API_URL + '/get-users', filterData, { headers })
            .then((response) => {
                if (response?.data?.status) {
                    setUsers(response.data.data);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching users:', error);
                if (error.response.status === 401) {
                    dispatch(logoutUser());
                }

                setIsLoading(false);
            });
    };

    const openFilterModal = () => {
        setFilterModal(true);
    }

    const handleFilterModalClose = () => {
        setFilterModal(false);
        setFilterData({
            id: '',
            name: '',
            surname: '',
            email: '',
            phone: '',
            company: '',
            city: '',
            status: ''
        });
    }

    const onFilterChangeHandler = (e) => {
        if (e.target.name === 'id') {
            setFilterData(prevState => ({
                ...prevState,
                id: e.target.value
            }));
        } else if (e.target.name === 'name') {
            setFilterData(prevState => ({
                ...prevState,
                name: e.target.value
            }));
        } else if (e.target.name === 'surname') {
            setFilterData(prevState => ({
                ...prevState,
                surname: e.target.value
            }));
        } else if (e.target.name === 'email') {
            setFilterData(prevState => ({
                ...prevState,
                email: e.target.value
            }));
        } else if (e.target.name === 'phone') {
            setFilterData(prevState => ({
                ...prevState,
                phone: e.target.value
            }));
        } else if (e.target.name === 'company') {
            setFilterData(prevState => ({
                ...prevState,
                company: e.target.value
            }));
        } else if (e.target.name === 'city') {
            setFilterData(prevState => ({
                ...prevState,
                city: e.target.value
            }));
        } else if (e.target.name === 'status') {
            setFilterData(prevState => ({
                ...prevState,
                status: e.target.value
            }));
        }
    }

    const onFilterSubmitHandler = () => {

        if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
            $(dataTableRef.current).DataTable().destroy(); // Destroy existing DataTable
        }

        getUsers();
        handleFilterModalClose();
    }

    return (
        <>
            <Container fluid>
                <Modal size="lg" show={filterModal}>
                    <Modal.Header>
                        <Modal.Title as="h5">Filter Users</Modal.Title>
                        <button className="badge bg-danger modal-close-button" onClick={handleFilterModalClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="id">Id</Form.Label>
                                        <Form.Control id="id" onChange={onFilterChangeHandler} name="id" placeholder='Enter Id' value={filterData.id} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="name">Name</Form.Label>
                                        <Form.Control id="name" onChange={onFilterChangeHandler} name="name" placeholder='Enter Name' value={filterData.name} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="surname">Surname</Form.Label>
                                        <Form.Control id="surname" onChange={onFilterChangeHandler} name="surname" placeholder='Enter Surname' value={filterData.surname} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="email">Email</Form.Label>
                                        <Form.Control id="email" onChange={onFilterChangeHandler} name="email" placeholder='Enter Email' value={filterData.email} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="phone">Phone</Form.Label>
                                        <Form.Control id="phone" onChange={onFilterChangeHandler} name="phone" placeholder='Enter Phone' value={filterData.phone} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="company">Name of Company</Form.Label>
                                        <Form.Control id="company" onChange={onFilterChangeHandler} name="company" placeholder='Enter Name of Company' value={filterData.company} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="city">City</Form.Label>
                                        <Form.Control id="city" onChange={onFilterChangeHandler} name="city" placeholder='Enter City' value={filterData.city} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="status">Status</Form.Label>
                                        <Form.Select id="status" className="form-control" name="status" onChange={onFilterChangeHandler} value={filterData.status}>
                                            <option value={''} disabled>Select Status</option>
                                            <option value={'active'}>Active</option>
                                            <option value={'deactive'}>Deactive</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary loginBtn" onClick={onFilterSubmitHandler}>Submit</Button>
                    </Modal.Footer>
                </Modal>
                <Row>
                    <Col lg="12">
                        <Card className="card-block card-stretch">
                            <Card.Body className="p-0">
                                <div className="d-flex justify-content-between align-items-center p-3">
                                    <h5 className="font-weight-bold">Users List</h5>
                                    <div>
                                        <DownloadExcel data={downloadData} fileName={'users'} />
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Filter</Tooltip>}>
                                            <button className="btn btn-primary loginBtn ml-2" onClick={openFilterModal}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                                                </svg>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {isLoading ? (
                                        <div className="text-center mb-5">
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                    ) : (
                                        <table ref={dataTableRef} className="table data-table mb-0">
                                            <thead className="table-color-heading text-left">
                                                <tr className="">
                                                    <th scope="col">
                                                        Id
                                                    </th>
                                                    <th scope="col">
                                                        Name
                                                    </th>
                                                    <th scope="col">
                                                        Surname
                                                    </th>
                                                    <th scope="col">
                                                        Email
                                                    </th>
                                                    <th scope="col">
                                                        Phone
                                                    </th>
                                                    <th scope="col">
                                                        Name of Company
                                                    </th>
                                                    <th scope="col">
                                                        City
                                                    </th>
                                                    <th scope="col">
                                                        Status
                                                    </th>
                                                    <th scope="col">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.map((user, index) => {
                                                    return (
                                                        <tr key={index} className="white-space-no-wrap">
                                                            <td>{user.id}</td>
                                                            <td className="">
                                                                <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                                    <div className="data-content">
                                                                        <div>
                                                                            <span className="font-weight-bold">{user.name}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{user.surname}</td>
                                                            <td>{user.email}</td>
                                                            <td>{user.phone}</td>
                                                            <td>{user?.company_name ? user?.company_name : 'N/A'}</td>
                                                            <td>{user?.city ? user?.city : 'N/A'}</td>
                                                            <td>{user.is_active ? <Badge pill className="bg-success">Active</Badge> : <Badge pill className="bg-danger">De-Active</Badge>}</td>
                                                            <td>
                                                                {/* <div className="d-flex justify-content-center align-items-center"> */}
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>View User</Tooltip>}>
                                                                    <button className="text-info status-button" data-user-id={user.id}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                                        </svg>
                                                                    </button>
                                                                </OverlayTrigger>
                                                                {/* </div> */}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container >

        </>

    )
}
export default Users;