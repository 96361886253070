import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import $ from 'jquery';

const SupportInfoForReseller = ({ user }) => {

    const dataTableRef = useRef(null);
    const paymentLinkDataTableRef = useRef(null);
    const representativeDataTableRef = useRef(null);
    const token = user?.user?.user?.token;
    const [genericInfo, setGenericInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [banks, setBanks] = useState([]);
    const [isPaymentLinkLoading, setIsPaymentLinkLoading] = useState(false);
    const [paymentLinks, setPaymentLinks] = useState([]);
    const [isRepresentativeListsLoading, setIsRepresentativeListsLoading] = useState(false);
    const [representativesList, setRepresentativesList] = useState([]);
    const [selectedBank, setSelectedBank] = useState({});
    const [bankDetailsShow, setBankDetailsShow] = useState(false);

    useEffect(() => {
        getGenericInfoCommunication();
        getBanks();
        getPaymentLink();
        getRepresentatives();
    }, []);

    useEffect(() => {
        if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
            $(dataTableRef.current).DataTable().destroy();
        }

        if (!isLoading && banks.length > 0) {
            if (!$.fn.DataTable.isDataTable(dataTableRef.current)) {
                $(dataTableRef.current).DataTable({
                    order: [[0, 'desc']],
                });
            }
        }

        $(dataTableRef.current).on('click', '.status-button', function () {
            const id = $(this).data('bank-id');
            const selectBank = banks.find(x => x?.id === id);
            setSelectedBank(selectBank);
            showBankDetailsModal();
        });
    }, [banks, isLoading]);

    useEffect(() => {
        if ($.fn.DataTable.isDataTable(paymentLinkDataTableRef.current)) {
            $(paymentLinkDataTableRef.current).DataTable().destroy();
        }

        if (!isPaymentLinkLoading && paymentLinks.length > 0) {
            if (!$.fn.DataTable.isDataTable(paymentLinkDataTableRef.current)) {
                $(paymentLinkDataTableRef.current).DataTable({
                    order: [[0, 'desc']],
                });
            }
        }
    }, [paymentLinks, isPaymentLinkLoading]);

    useEffect(() => {
        if ($.fn.DataTable.isDataTable(representativeDataTableRef.current)) {
            $(representativeDataTableRef.current).DataTable().destroy();
        }

        if (!isRepresentativeListsLoading && representativesList.length > 0) {
            if (!$.fn.DataTable.isDataTable(representativeDataTableRef.current)) {
                $(representativeDataTableRef.current).DataTable({
                    order: [[0, 'desc']],
                });
            }
        }
    }, [representativesList, isRepresentativeListsLoading]);

    const getGenericInfoCommunication = () => {
        const headers = {
            Authorization: `Bearer ${token}`,
        }
        axios.get(process.env.REACT_APP_API_URL + '/get-generic-info-communication', headers)
            .then((res) => {
                if (res?.data?.status) {
                    setGenericInfo(res?.data?.data);
                }
            })
            .catch((err) => {
                console.log(err, "err");
            })
    }

    const getBanks = () => {
        if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
            $(dataTableRef.current).DataTable().destroy();
        }
        setBanks([]);
        setIsLoading(true);
        const headers = {
            Authorization: `Bearer ${token}`,
        }
        axios.get(process.env.REACT_APP_API_URL + '/get-banks', headers)
            .then((res) => {
                setIsLoading(false);
                if (res?.data?.status) {
                    setBanks(res?.data?.data);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err, "err");
            })
    }

    const getPaymentLink = () => {
        if ($.fn.DataTable.isDataTable(paymentLinkDataTableRef.current)) {
            $(paymentLinkDataTableRef.current).DataTable().destroy();
        }
        setPaymentLinks([]);
        setIsPaymentLinkLoading(true);
        const headers = {
            Authorization: `Bearer ${token}`,
        }
        axios.get(process.env.REACT_APP_API_URL + '/get-payment-link', headers)
            .then((res) => {
                setIsPaymentLinkLoading(false);
                if (res?.data?.status) {
                    setPaymentLinks(res?.data?.data);
                }
            })
            .catch((err) => {
                setIsPaymentLinkLoading(false);
                console.log(err, "err");
            })
    }

    const getRepresentatives = () => {
        if ($.fn.DataTable.isDataTable(representativeDataTableRef.current)) {
            $(representativeDataTableRef.current).DataTable().destroy();
        }
        setRepresentativesList([]);
        setIsRepresentativeListsLoading(true);
        const headers = {
            Authorization: `Bearer ${token}`,
        }
        axios.get(process.env.REACT_APP_API_URL + '/get-representative', headers)
            .then((res) => {
                setIsRepresentativeListsLoading(false);
                if (res?.data?.status) {
                    setRepresentativesList(res?.data?.data);
                }
            })
            .catch((err) => {
                setIsRepresentativeListsLoading(false);
                console.log(err, "err");
            })
    }

    const showBankDetailsModal = () => {
        setBankDetailsShow(true);
    }

    const closeBankDetailsModal = () => {
        setBankDetailsShow(false);
    }

    return (
        <section>
            <Container>
                <Row>
                    {genericInfo.length > 0 ? (
                        <Col lg="12">
                            <Card className="card-block card-stretch">
                                <Card.Body className="p-0">
                                    <div className="d-flex justify-content-between align-items-center p-3">
                                        <h5 className="font-weight-bold">Generic Info / Communication</h5>
                                    </div>

                                    <div className='generic-info-main-box'>
                                        {genericInfo.map((val, ind) => {
                                            return (
                                                <div className='generic-info' key={ind}>
                                                    <div dangerouslySetInnerHTML={{ __html: val?.info }}></div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>) : null}
                    {banks.length > 0 ?
                        <Col lg="12">
                            <Card className="card-block card-stretch">
                                <Card.Body className="p-0">
                                    <div className="d-flex justify-content-between align-items-center p-3">
                                        <h5 className="font-weight-bold">Banks</h5>
                                    </div>
                                    {isLoading ?
                                        <div className="text-center mb-5">
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                        : <div className="table-responsive">
                                            <table ref={dataTableRef} className="table data-table mb-0">
                                                <thead className="table-color-heading text-left">
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Bank Name</th>
                                                        <th scope="col">Account Holder</th>
                                                        <th scope="col">Iban</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {banks.map((bank, index) => {
                                                        return (
                                                            <tr key={index} className="white-space-no-wrap">
                                                                <td>{index + 1}</td>
                                                                <td>{bank?.bank_name}</td>
                                                                <td>{bank?.account_holder}</td>
                                                                <td>{bank?.iban}</td>
                                                                <td>{bank?.email}</td>
                                                                <td>{bank?.country?.name}</td>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>View Bank Details</Tooltip>}>
                                                                            <div style={{ cursor: 'pointer' }} >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" data-bank-id={bank?.id} className="w-6 h-6 status-button">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                                                </svg>
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>}
                                </Card.Body>
                            </Card>
                        </Col> : null}
                    {paymentLinks.length > 0 ?
                        <Col lg="12">
                            <Card className="card-block card-stretch">
                                <Card.Body className="p-0">
                                    <div className="d-flex justify-content-between align-items-center p-3">
                                        <h5 className="font-weight-bold">Payment Links</h5>
                                    </div>
                                    {isPaymentLinkLoading ?
                                        <div className="text-center mb-5">
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                        :
                                        <div className="table-responsive">
                                            <table ref={paymentLinkDataTableRef} className="table data-table mb-0">
                                                <thead className="table-color-heading text-left">
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Title</th>
                                                        <th scope="col">description</th>
                                                        <th scope="col">Link</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {paymentLinks.map((paymentLink, index) => {
                                                        return (
                                                            <tr key={index} className="white-space-no-wrap">
                                                                <td>{index + 1}</td>
                                                                <td>{paymentLink?.title ? paymentLink?.title : 'N/A'}</td>
                                                                <td>{paymentLink?.description ? paymentLink?.description : 'N/A'}</td>
                                                                <td>{paymentLink?.link ? <a href={paymentLink?.link} target='_blank'>Click Here</a> : 'N/A'}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>}
                                </Card.Body>
                            </Card>
                        </Col> : null}
                    {representativesList.length > 0 ?
                        <Col lg="12">
                            <Card className="card-block card-stretch">
                                <Card.Body className="p-0">
                                    <div className="d-flex justify-content-between align-items-center p-3">
                                        <h5 className="font-weight-bold">Sales / Technical Representative</h5>
                                    </div>
                                    {isRepresentativeListsLoading ?
                                        <div className="text-center mb-5">
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                        :

                                        <div className="table-responsive">
                                            <table ref={representativeDataTableRef} className="table data-table mb-0">
                                                <thead className="table-color-heading text-left">
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Phone</th>
                                                        <th scope="col">Whatsapp</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Days</th>
                                                        <th scope="col">Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {representativesList.map((representatives, index) => {
                                                        return (
                                                            <tr key={index} className="white-space-no-wrap">
                                                                <td>{index + 1}</td>
                                                                <td>{representatives?.name ? representatives?.name : 'N/A'}</td>
                                                                <td>{representatives?.type ? (representatives?.type == 'SALES_SUPPORT' ? 'Sales Support' : 'Technical Support') : 'N/A'}</td>
                                                                <td>{representatives?.phone ? representatives?.phone : 'N/A'}</td>
                                                                <td>{representatives?.whatsapp ? representatives?.whatsapp : 'N/A'}</td>
                                                                <td>{representatives?.email ? representatives?.email : 'N/A'}</td>
                                                                <td>{representatives?.start_day ? representatives?.start_day : 'N/A'} - {representatives?.end_day ? representatives?.end_day : 'N/A'}</td>
                                                                <td>{representatives?.start_time ? representatives?.start_time + ':00' : 'N/A'} - {representatives?.end_time ? representatives?.end_time + ':00' : 'N/A'}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>}
                                </Card.Body>
                            </Card>
                        </Col> : null}
                </Row>
            </Container>

            <Modal show={bankDetailsShow} onHide={closeBankDetailsModal} size="lg">
                <Modal.Header>
                    <Modal.Title as="h5">Bank Details</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={closeBankDetailsModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <h5>Bank Name:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.bank_name}</p>
                        </Col>
                        <Col md={4}>
                            <h5>Account Holder:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.account_holder}</p>
                        </Col>
                        <Col md={4}>
                            <h5>IBAN:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.iban}</p>
                        </Col>
                        <Col md={4}>
                            <h5>BIC / SWIFT:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.bic_swift}</p>
                        </Col>
                        <Col md={4}>
                            <h5>Email:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.email}</p>
                        </Col>
                        <Col md={4}>
                            <h5>Country:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.country?.name}</p>
                        </Col>
                        <Col md={4}>
                            <h5>More Info:</h5>
                        </Col>
                        <Col md={8}>
                            <p>{selectedBank?.more_info}</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-primary loginBtn' onClick={closeBankDetailsModal}>close</Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}

export default SupportInfoForReseller;
