import axios from "axios";
import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import noImage from '../../../assets/images/no-image.png';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { useDispatch } from "react-redux";
import { logoutUser, updateProfile } from "../../../store/actions";

const UserDetails = (user) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const userId = atob(history.location.pathname.split('/')[2]);
    const [isLoading, setIsLoading] = useState(true);
    const [isTwoFALoading, setIsTwoFALoading] = useState(false);
    const [transactionsErrors, setTransactionsErrors] = useState({});
    const [transactionLoading, setTransactionLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const [name, setname] = useState('');
    const [surname, setsurname] = useState('');
    const [email, setemail] = useState('');
    const [phone, setphone] = useState('');
    const [image, setimage] = useState('');
    const [userImagePreview, setUserImagePreview] = useState(null);
    const [emailVerifiedAt, setemailVerifiedAt] = useState('');
    const [isActive, setisActive] = useState(false);
    const [allow2fa, setallow2fa] = useState(false);
    const [country, setCountry] = useState({});
    const [state, setState] = useState({});
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [pIvaNumber, setPIvaNumber] = useState('');
    const [balance, setBalance] = useState('');
    const [limitPerTransaction, setLimitPerTransaction] = useState('');
    const [dailyTransactionLimit, setDailyTransactionLimit] = useState('');
    const [gainType, setGainType] = useState(1);
    const [defaultGain, setDefaultGain] = useState(0);
    const [address, setAddress] = useState('');
    const [companyRegistration, setCompanyRegistration] = useState('');
    const [companyRegistrationName, setCompanyRegistrationName] = useState('');
    const [residencePermit, setResidencePermit] = useState('');
    const [residencePermitName, setResidencePermitName] = useState('');
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [twoFA, setTwoFA] = useState('');
    const [errors, setErrors] = useState({});
    const [isProfileDataUpdating, setIsProfileDataUpdating] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [viewImageTitle, setViewImageTitle] = useState('');
    const [showModal, setShowModal] = useState(false);
    const token = user.user.user.user.token;

    useEffect(() => {
        getUserDetails();
        getCountries();
        Fancybox.bind();
    }, []);

    useEffect(() => {
        getStatesByCountryId();
    }, [country]);

    useEffect(() => {
        setname(userData.name);
        setsurname(userData.surname);
        setemail(userData.email);
        setphone(userData.phone);
        setUserImagePreview(userData.image !== null && userData.image !== '' ? process.env.REACT_APP_BACKEND_URL + userData.image : noImage);
        setemailVerifiedAt(userData.email_verified_at);
        setisActive(userData.is_active);
        setallow2fa(userData.allow_2fa);
        setCountry(userDetails?.country_id);
        setState(userDetails?.state_id);
        setCity(userDetails?.city);
        setZipCode(userDetails?.zip_code);
        setCompanyName(userDetails?.company_name);
        setPIvaNumber(userDetails?.p_iva_number);
        setBalance(userDetails?.balance);
        setLimitPerTransaction(userDetails?.limit_per_transaction);
        setDailyTransactionLimit(userDetails?.daily_transaction_limit);
        setGainType(userDetails?.gainType);
        setDefaultGain(userDetails?.default_gain);
        setAddress(userDetails?.address);
        setCompanyRegistration(userDetails?.company_registration);
        setCompanyRegistrationName(userDetails?.company_registration ? userDetails?.company_registration.split('/')[3] : '');
        setResidencePermit(userDetails?.residence_permit);
        setResidencePermitName(userDetails?.residence_permit ? userDetails?.residence_permit.split('/')[3] : '');
    }, [userData, userDetails]);

    const getCountries = () => {
        axios.get(process.env.REACT_APP_API_URL + '/get-all-countries')
            .then((res) => {
                if (res.data.status) {
                    setCountries(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getStatesByCountryId = () => {
        const header = {
            Authorization: `Bearer ${token}`
        };

        axios.post(process.env.REACT_APP_API_URL + '/get-states-by-country-id', { countryId: country }, { header })
            .then((res) => {
                if (res.data.status) {
                    setStates(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401) {
                    dispatch(logoutUser());
                }
            });
    }

    const getUserDetails = () => {
        setIsLoading(true);

        const headers = {
            Authorization: `Bearer ${token}`,
        }

        axios.post(process.env.REACT_APP_API_URL + '/get-user-details', { userId: userId }, { headers })
            .then((res) => {
                setIsLoading(false);
                if (res.data.status) {
                    setUserData(res.data.data.user);
                    setUserDetails(res.data.data.userDetails);
                }
            })
            .catch((err) => {
                console.log(err);

                if (err.response.status === 401) {
                    dispatch(logoutUser());
                }

                setIsLoading(false);
                Swal.fire('Oops!', err.response.data.message, 'error')
            });
    }

    const handleTransactionSettingChange = (e) => {
        if (e.target.name == 'limit_per_transaction') {
            setLimitPerTransaction(e.target.value);
        } else if (e.target.name == 'daily_transaction_limit') {
            setDailyTransactionLimit(e.target.value);
        }
    }

    const onTransactionSettingSubmitHandler = (e) => {
        e.preventDefault();
        const validate = checkTransactionsSettingsValidations();
        if (validate) {
            setTransactionLoading(true);

            const headers = {
                Authorization: `Bearer ${token}`,
            }

            axios.post(process.env.REACT_APP_API_URL + '/change-transactions-settings', { id: userId, limit_per_transaction: limitPerTransaction, daily_transaction_limit: dailyTransactionLimit }, { headers })
                .then((res) => {
                    setTransactionLoading(false);
                    if (res.data.status) {
                        Swal.fire('Success!', 'Transactions settings updated.');
                        setUserData(res.data.data.user);
                        setUserDetails(res.data.data.userDetails);
                    }
                })
                .catch((err) => {
                    console.log(err.response.data.message);

                    if (err.response.status === 401) {
                        dispatch(logoutUser());
                    }

                    Swal.fire('Oops!', err.response.data.message, 'error')
                    setTransactionLoading(false);
                })
        }
    }

    const checkTransactionsSettingsValidations = () => {
        const validationErrors = {};

        if (limitPerTransaction == null || limitPerTransaction == '') {
            validationErrors.limit_per_transaction = 'Please enter limit per transaction.';
        }

        if (dailyTransactionLimit == null || dailyTransactionLimit == '') {
            validationErrors.daily_transaction_limit = 'Please enter daily transaction limit.';
        }

        // If there are validation errors, update the state and return
        if (Object.keys(validationErrors).length > 0) {
            setTransactionsErrors(validationErrors);
            return false;
        } else {
            setTransactionsErrors(validationErrors);
            return true;
        }
    }

    const onTwoFactorChangeHandler = (e) => {
        if (e.target.checked) {
            setTwoFA("ON");
            setallow2fa(1);
        } else {
            setTwoFA("OFF");
            setallow2fa(0);
        }
    }

    const onTwoFactorSubmitHandler = (e) => {
        e.preventDefault();
        if (userData.allow_2fa !== allow2fa) {
            setIsTwoFALoading(true);

            const headers = {
                Authorization: `Bearer ${token}`,
            }

            axios.post(process.env.REACT_APP_API_URL + '/change-users-two-factor-status', { id: userId, twoFA: twoFA }, { headers })
                .then((res) => {
                    setIsTwoFALoading(false);
                    console.log(res);
                    if (res.data.status) {
                        Swal.fire('Success!', res.data.message, 'success');
                        setUserData(res.data.data.user);
                        setUserDetails(res.data.data.userDetails);
                    } else {
                        Swal.fire('Ooops!', res.data.message, 'error');
                    }
                })
                .catch((err) => {
                    console.log(err);

                    if (err.response.status === 401) {
                        dispatch(logoutUser());
                    }

                    setIsTwoFALoading(false);
                    Swal.fire('Ooops!', err.response.data.message, 'error');
                });
        }
    }

    const handleImageChange = (event) => {
        const selectedUserImage = event.target.files[0];
        if (selectedUserImage) {
            setimage(selectedUserImage);
            setUserImagePreview(URL.createObjectURL(selectedUserImage));
        }
    };

    const companyRegistrationHandler = (event) => {
        const selectedUserImage = event.target.files[0];
        if (selectedUserImage) {
            setCompanyRegistration(selectedUserImage);
            setCompanyRegistrationName(selectedUserImage.name);
        }
    };

    const residencePermitHandler = (event) => {
        const selectedUserImage = event.target.files[0];
        if (selectedUserImage) {
            setResidencePermit(selectedUserImage);
            setResidencePermitName(selectedUserImage.name);
        }
    };

    const onUpdateProfileData = (e) => {
        e.preventDefault();

        const validate = checkProfileDataValidation();

        if (validate) {
            setIsProfileDataUpdating(true);

            const headers = {
                Authorization: `Bearer ${token}`,
            }

            const formData = new FormData();
            formData.append("id", userData.id);
            formData.append("name", name);
            formData.append("surname", surname);
            formData.append("companyName", companyName);
            formData.append("phone", phone);
            formData.append("country", country);
            formData.append("state", state);
            formData.append("city", city);
            formData.append("zip", zipCode);
            formData.append("pIVA", pIvaNumber);
            formData.append("address", address);
            formData.append("image", image);
            formData.append("companyRegistration", companyRegistration);
            formData.append("residencePermit", residencePermit);
            formData.append("status", isActive);

            axios.post(process.env.REACT_APP_API_URL + '/update-profile', formData, { headers })
                .then((res) => {
                    setIsProfileDataUpdating(false);
                    if (res.data.status) {
                        // dispatch(updateProfile(res.data.data));
                        // setGain('');
                        Swal.fire('Success!', res.data.message, 'success');
                    } else {
                        Swal.fire('Ooops!', res.data.message, 'error');
                    }
                })
                .catch((err) => {
                    console.log(err);

                    if (err.response.status === 401) {
                        dispatch(logoutUser());
                    }

                    setIsProfileDataUpdating(false);
                    Swal.fire('Ooops!', err.response.data.message, 'error');
                })
        }
    }

    const checkProfileDataValidation = () => {
        const validationErrors = {};

        if (!name) {
            validationErrors.name = 'Please enter name'
        }

        if (!surname) {
            validationErrors.surname = 'Please enter surname'
        }

        if (!companyName) {
            validationErrors.companyName = 'Please enter company name'
        }

        if (!phone) {
            validationErrors.phone = 'Please enter phone number'
        } else if (phone.length > 14) {
            validationErrors.phone = 'Phone must not be greater than 14 digits'
        }

        if (!pIvaNumber) {
            validationErrors.pIvaNumber = 'Please enter IVA number'
        } else if (pIvaNumber.length < 11) {
            validationErrors.pIvaNumber = 'IVA number must be at least 11 digits long'
        } else if (pIvaNumber.length > 11) {
            validationErrors.pIvaNumber = 'IVA number must not be greater than 11 digits'
        }

        if (!country) {
            validationErrors.country = 'Please select country'
        }

        if (!state) {
            validationErrors.state = 'Please select state'
        }

        if (!city) {
            validationErrors.city = 'Please enter city'
        }

        if (!zipCode) {
            validationErrors.zipCode = 'Please enter zip code'
        }

        if (!address) {
            validationErrors.address = 'Please enter address'
        }

        if (image !== null && image !== '') {
            if (image.size > 5000000) {
                validationErrors.image = 'Maximum file size exceeds.';
            }
        }

        if (companyRegistration !== null && companyRegistration !== '') {
            if (companyRegistration.size > 5000000) {
                validationErrors.companyRegistration = 'Maximum file size exceeds.';
            }
        }

        if (residencePermit !== null && residencePermit !== '') {
            if (residencePermit.size > 5000000) {
                validationErrors.residencePermit = 'Maximum file size exceeds.';
            }
        }

        // If there are validation errors, update the state and return
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return false;
        } else {
            return true;
        }
    }

    const onStatusChangeHandler = (e) => {
        if (e.target.checked) {
            setisActive(1);
        } else {
            setisActive(0);
        }
    }

    const goBack = () => {
        history.goBack();
    };

    const showImageModal = (imageUrl, title) => {
        const image = process.env.REACT_APP_BACKEND_URL + '/users/images/' + userData?.id + '/' + imageUrl;
        setSelectedImage(image);
        setViewImageTitle(title);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedImage('');
    };

    return (
        <>
            {isLoading ?
                <div className="loader-box float-right">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
                :
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                                <h4 className="font-weight-bold">Users Details</h4>
                                <Button onClick={goBack} className="loginBtn">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    Back
                                </Button>
                            </div>
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <Card.Header>
                                            <Card.Title>
                                                Edit Profile Information
                                            </Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row className="form-group  align-items-center">
                                                <Col md="4">
                                                    <div className="profile-img-edit">
                                                        <div className="crm-profile-img-edit">
                                                            <p>Profile Image</p>
                                                            <div className={errors.image ? 'imageBox is-invalid' : 'imageBox'}>
                                                                <img className="crm-profile-pic rounded-circle avatar-100" src={userImagePreview ? userImagePreview : noImage} alt="profile-pic" />
                                                                <div className="crm-p-image bg-primary bg-theme">
                                                                    <label htmlFor="userImageUpload" className="upload-label" style={{ cursor: 'pointer' }}>
                                                                        <i className="las la-pen upload-button"></i>
                                                                    </label>
                                                                    <input type="file" accept="image/*" id="userImageUpload" onChange={handleImageChange} style={{ display: 'none' }} />
                                                                </div>
                                                            </div>
                                                            {errors.image ? <div className="invalid-feedback mt-3">{errors.image}</div> : null}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <div className="profile-img-edit">
                                                        <div className="crm-profile-img-edit">
                                                            <p>Company Registration</p>
                                                            <div className={errors.userImage ? 'imageBox is-invalid' : 'imageBox'}>
                                                                {/* <img className="crm-profile-pic avatar-100" src={companyRegistrationPreview ? companyRegistrationPreview : noImage} alt="profile-pic" /> */}
                                                                <span onClick={() => showImageModal(companyRegistrationName, 'Company Registration')}>{companyRegistrationName}</span>
                                                                <div className="attachedFiles bg-primary bg-theme">
                                                                    <label htmlFor="companyRegistrationUpload" className="upload-label" style={{ cursor: 'pointer' }}>
                                                                        <i className="las la-pen upload-button"></i>
                                                                    </label>
                                                                    <input type="file" accept="image/*" id="companyRegistrationUpload" onChange={companyRegistrationHandler} style={{ display: 'none' }} />
                                                                </div>
                                                            </div>
                                                            {errors.companyRegistration ? <div className="invalid-feedback mt-3">{errors.companyRegistration}</div> : null}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <div className="profile-img-edit">
                                                        <div className="crm-profile-img-edit">
                                                            <p>Residence Permit</p>
                                                            <div className={errors.userImage ? 'imageBox is-invalid' : 'imageBox'}>
                                                                {/* <img className="crm-profile-pic avatar-100" src={residencePermitPreview ? residencePermitPreview : noImage} alt="profile-pic" /> */}
                                                                <span onClick={() => showImageModal(residencePermitName, 'Residence Permit')}>{residencePermitName}</span>
                                                                <div className="attachedFiles bg-primary bg-theme">
                                                                    <label htmlFor="residencePermitUpload" className="upload-label" style={{ cursor: 'pointer' }}>
                                                                        <i className="las la-pen upload-button"></i>
                                                                    </label>
                                                                    <input type="file" accept="image/*" id="residencePermitUpload" onChange={residencePermitHandler} style={{ display: 'none' }} />
                                                                </div>
                                                            </div>
                                                            {errors.residencePermit ? <div className="invalid-feedback mt-3">{errors.residencePermit}</div> : null}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center">
                                                <Col md={12}>
                                                    <Form className="row g-3 date-icon-set-modal">
                                                        <div className="col-md-4 mb-3">
                                                            <Form.Label htmlFor="name" className="font-weight-bold text-muted text-uppercase">Name</Form.Label>
                                                            <Form.Control type="text" id="name" placeholder="Name" className={errors.name ? 'is-invalid' : ''} name="name" value={name} onChange={(e) => setname(e.target.value)} />
                                                            {errors.name ? <div className="invalid-feedback">{errors.name}</div> : null}
                                                        </div>

                                                        <div className="col-md-4 mb-3">
                                                            <Form.Label htmlFor="surname" className="font-weight-bold text-muted text-uppercase">Surname</Form.Label>
                                                            <Form.Control type="text" id="surname" placeholder="Surname" className={errors.surname ? 'is-invalid' : ''} name="surname" value={surname} onChange={(e) => setsurname(e.target.value)} />
                                                            {errors.surname ? <div className="invalid-feedback">{errors.surname}</div> : null}
                                                        </div>

                                                        <div className="col-md-4 mb-3">
                                                            <Form.Label htmlFor="companyName" className="font-weight-bold text-muted text-uppercase">Company name</Form.Label>
                                                            <Form.Control type="text" id="companyName" placeholder="Company Name" className={errors.companyName ? 'is-invalid' : ''} name="companyName" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                                                            {errors.companyName ? <div className="invalid-feedback">{errors.companyName}</div> : null}
                                                        </div>

                                                        <div className="col-md-4 mb-3">
                                                            <Form.Label htmlFor="email" className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                                            <Form.Control type="email" id="email" placeholder="Enter Email" className={errors.email ? 'is-invalid' : ''} name="email" value={email} disabled={true} />
                                                            {errors.email ? <div className="invalid-feedback">{errors.email}</div> : null}
                                                        </div>

                                                        <div className="col-md-4 mb-3">
                                                            <Form.Label htmlFor="phone" className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                            <Form.Control type="tel" id="phone" placeholder="Enter Phone" className={errors.phone ? 'is-invalid' : ''} name="phone" value={phone} onChange={(e) => setphone(e.target.value)} />
                                                            {errors.phone ? <div className="invalid-feedback">{errors.phone}</div> : null}
                                                        </div>

                                                        <div className="col-md-4 mb-3">
                                                            <Form.Label htmlFor="country" className="font-weight-bold text-muted text-uppercase">Country</Form.Label>
                                                            <Form.Control as={'select'} id="country" name="country" value={country} className={errors.country ? 'is-invalid' : ''} onChange={(e) => setCountry(e.target.value)}>
                                                                {countries.length > 0 ? countries.map((country, index) => {
                                                                    return (
                                                                        <option value={country.id} key={index}>{country.name}</option>
                                                                    )
                                                                }) : null}
                                                            </Form.Control>
                                                            {errors.country ? <div className="invalid-feedback">{errors.country}</div> : null}
                                                        </div>

                                                        <div className="col-md-4 mb-3">
                                                            <Form.Label htmlFor="state" className="font-weight-bold text-muted text-uppercase">State</Form.Label>
                                                            <Form.Control as={'select'} id="state" name="state" value={state} className={errors.state ? 'is-invalid' : ''} onChange={(e) => setState(e.target.value)}>
                                                                {states.length > 0 ? states.map((state, index) => {
                                                                    return (
                                                                        <option value={state.id} key={index}>{state.name}</option>
                                                                    )
                                                                }) : null}
                                                            </Form.Control>
                                                            {errors.state ? <div className="invalid-feedback">{errors.state}</div> : null}
                                                        </div>

                                                        <div className="col-md-4 mb-3">
                                                            <Form.Label htmlFor="city" className="font-weight-bold text-muted text-uppercase">City</Form.Label>
                                                            <Form.Control type="text" id="city" placeholder="Enter City" className={errors.city ? 'is-invalid' : ''} name="city" value={city} onChange={(e) => setCity(e.target.value)} />
                                                            {errors.city ? <div className="invalid-feedback">{errors.city}</div> : null}
                                                        </div>

                                                        <div className="col-md-4 mb-3">
                                                            <Form.Label htmlFor="zip" className="font-weight-bold text-muted text-uppercase">Zip</Form.Label>
                                                            <Form.Control type="number" id="zip" placeholder="Enter Zip" className={errors.zipCode ? 'is-invalid' : ''} name="zip" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
                                                            {errors.zipCode ? <div className="invalid-feedback">{errors.zipCode}</div> : null}
                                                        </div>

                                                        <div className="col-md-4 mb-3">
                                                            <Form.Label htmlFor="pIvaNumber" className="font-weight-bold text-muted text-uppercase">P IVA Number</Form.Label>
                                                            <Form.Control type="text" id="pIvaNumber" placeholder="Enter P IVA Number" className={errors.pIvaNumber ? 'is-invalid' : ''} name="pIvaNumber" value={pIvaNumber} onChange={(e) => setPIvaNumber(e.target.value)} />
                                                            {errors.pIvaNumber ? <div className="invalid-feedback">{errors.pIvaNumber}</div> : null}
                                                        </div>

                                                        <div className="col-md-8 mb-3">
                                                            <Form.Label htmlFor="address" className="font-weight-bold text-muted text-uppercase">Shop Address</Form.Label>
                                                            <Form.Control type="text" id="address" placeholder="Enter Address" className={errors.address ? 'is-invalid' : ''} name="address" value={address} onChange={(e) => setAddress(e.target.value)} />
                                                            {errors.address ? <div className="invalid-feedback">{errors.address}</div> : null}
                                                        </div>

                                                        <div className="col-md-4 mb-3 ml-3 custom-control custom-switch custom-control-inline">
                                                            <Form.Check.Input bsPrefix="custom-control-input" id="customSwitch1" checked={isActive === 1 ? true : false} onChange={onStatusChangeHandler} />
                                                            {/* <Form.Check.Input bsPrefix="custom-control-input" id="customSwitch1" checked={isActive === 1 ? true : false} /> */}
                                                            <Form.Check.Label bsPrefix="custom-control-label" htmlFor="customSwitch1">Active</Form.Check.Label>
                                                        </div>

                                                        <div className="col-md-12">
                                                            {isProfileDataUpdating ?
                                                                <div className="loader-box float-right">
                                                                    <Spinner animation="border" role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </Spinner>
                                                                </div> :
                                                                <Button type="button" className="loginBtn float-right" variant="primary" onClick={onUpdateProfileData}>Update</Button>
                                                            }
                                                        </div>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Card>
                                        <Card.Header>
                                            <Card.Title>Transaction Settings</Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form>
                                                <Form.Group>
                                                    <Form.Label htmlFor="limit_per_transaction">Limit Per Transaction</Form.Label>
                                                    <Form.Control type="number" name="limit_per_transaction" id="limit_per_transaction" className={transactionsErrors.limit_per_transaction ? 'is-invalid' : ''} value={limitPerTransaction ? limitPerTransaction : 0} onChange={handleTransactionSettingChange} />
                                                    {transactionsErrors.limit_per_transaction ? <div className="invalid-feedback">{transactionsErrors.limit_per_transaction}</div> : null}
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label htmlFor="daily_transaction_limit">Daily Transaction Limit</Form.Label>
                                                    <Form.Control type="number" name="daily_transaction_limit" id="daily_transaction_limit" className={transactionsErrors.daily_transaction_limit ? 'is-invalid' : ''} value={dailyTransactionLimit ? dailyTransactionLimit : 0} onChange={handleTransactionSettingChange} />
                                                    {transactionsErrors.daily_transaction_limit ? <div className="invalid-feedback">{transactionsErrors.daily_transaction_limit}</div> : null}
                                                </Form.Group>
                                                {transactionLoading ?
                                                    <div className="loader-box float-right">
                                                        <Spinner animation="border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </Spinner>
                                                    </div> :
                                                    <Button type="button" className="loginBtn float-right mt-3" onClick={onTransactionSettingSubmitHandler}>Update</Button>
                                                }
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={6}>
                                    <Card>
                                        <Card.Header>
                                            <Card.Title>Setup 2-Factor Authentication</Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form>
                                                <div className="custom-control custom-switch custom-control-inline">
                                                    <Form.Check.Input bsPrefix="custom-control-input" id="customSwitch2" onChange={onTwoFactorChangeHandler} checked={allow2fa ? true : false} />
                                                    <Form.Check.Label bsPrefix="custom-control-label" htmlFor="customSwitch2">Active</Form.Check.Label>
                                                </div>
                                                {isTwoFALoading ?
                                                    <div className="loader-box float-right">
                                                        <Spinner animation="border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </Spinner>
                                                    </div> :
                                                    <Button type="button" className="loginBtn float-right mt-3" onClick={onTwoFactorSubmitHandler}>Update</Button>
                                                }
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Modal show={showModal} onHide={handleCloseModal} centered>
                        <Modal.Header>
                            <Modal.Title>{viewImageTitle}</Modal.Title>
                            <span style={{ cursor: 'pointer', marginLeft: 'auto' }} onClick={handleCloseModal}>
                                {/* Example SVG Icon */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </span>
                        </Modal.Header>
                        <Modal.Body className="text-center">
                            <img src={selectedImage} alt="Payment Proof" className="img-fluid" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            }
        </>
    )
}

export default UserDetails;