import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useParams, useHistory } from 'react-router-dom'
import { ChromePicker } from 'react-color';
import './colorPicker.css';
import Swal from 'sweetalert2';
import axios from 'axios'; // Import Axios
import './custome-select.css'

const Companiesedit = ({ user }) => {
    let history = useHistory()
    const { companyId } = useParams();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [colorThem, setColorThem] = useState('');
    const [logo, setLogo] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userImage, setUserImage] = useState(null);
    const [userImagePreview, setUserImagePreview] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [publishableKey, setPublishableKey] = useState('');
    const token = user.user.user.token;
    useEffect(() => {
        fetchCurrencyOptions();
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/company/${companyId}/edit`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                console.log(response.data);
                setName(response.data.company.name);
                setEmail(response.data.company.email);
                setPhone(response.data.company.phone);
                setSelectedCurrency(response.data.company.currency_id);
                setSecretKey(response.data.company.secretKey);
                setPublishableKey(response.data.company.publishableKey);
                setColorThem(response.data.company.color_them || '#ffffff');
                setLogo(response.data.company.logo);
                if (response.data.company.logo) {
                    setLogoPreview(process.env.REACT_APP_BACKEND_URL + response.data.company.logo);
                }
                setUserName(response.data.company.userName);
                setUserEmail(response.data.company.userEmail || '');
                setUserPhone(response.data.company.userPhone || '');
                // You might not want to set the userPassword here for security reasons
                setUserImage(null); // Clear previously selected user image
                if (response.data.company.userImage) {
                    setUserImagePreview(process.env.REACT_APP_BACKEND_URL + response.data.company.userImage);
                }

            } catch (error) {
                console.error('Error fetching Company details:', error);
            }
        };

        fetchData();
    }, [companyId, token, user.user.user.companyDetails.id]);
    const fetchCurrencyOptions = () => {
        axios.get(process.env.REACT_APP_API_URL + '/currencies', {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response) => {
                const fetchedCurrencies = response.data;
                setCurrencyOptions(fetchedCurrencies.currencies);
            })
            .catch((error) => {
                console.error('Error fetching currencies:', error);
            });
    };
    const toggleColorPicker = () => {
        if (colorThem !== null) {
            setShowColorPicker(!showColorPicker);
        }
    };

    const closeColorPicker = () => {
        setShowColorPicker(false);
    };

    const handleColorChange = (color) => {
        setColorThem(color.hex);
    };

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setLogo(file);
            setLogoPreview(URL.createObjectURL(file));
        }
    };
    const handleImageChange = (event) => {
        const selectedUserImage = event.target.files[0];
        if (selectedUserImage) {
            setUserImage(selectedUserImage);
            setUserImagePreview(URL.createObjectURL(selectedUserImage));
        }
    };


    const handleSave = async () => {

        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('currencyId', selectedCurrency);
        formData.append('color_them', colorThem);
        formData.append("_method", "PUT");
        formData.append('userName', userName);
        formData.append('userEmail', userEmail);
        formData.append('userPhone', userPhone);
        formData.append('publishableKey', publishableKey);
        formData.append('secretKey', secretKey);

        if (logo) {
            formData.append('logo', logo);
        }
        if (userImage) {
            formData.append('userImage', userImage);
        }
        try {
            setIsUpdating(true);
            const response = await axios.post(
                process.env.REACT_APP_API_URL + '/company/' + companyId,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data', // Set the correct content type
                    },
                }
            );

            if (response.data.status) {
                Swal.fire('Done!', response.data.message, 'success').then(() => {
                    // Handle the success response
                    console.log(response.data);
                });
            } else {
                Swal.fire('Oops!', response.data.message, 'error');
            }
        } catch (error) {
            console.error('Error updating company:', error);
            // Handle error scenarios
        } finally {
            setIsUpdating(false);
        }
    };
    const handleDelete = () => {

        Swal.fire({
            title: 'Delete Company',
            text: 'Are you sure you want to delete this company?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it'
        }).then((result) => {
            if (result.isConfirmed) {

                axios.delete(process.env.REACT_APP_API_URL + '/company/' + companyId, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                    .then((response) => {
                        if (response.data.status) {

                            Swal.fire('Done!', response.data.message, 'success').then(() => {
                                history.push('/companies');
                            });
                        }
                        else {
                            Swal.fire('Ooopsss!', response.data.message, 'error');
                        }
                    })
                    .catch((error) => {
                        Swal.fire('Ooopsss!', error.response.data.message, 'error');
                    });
            }
        });
    }
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/companies">Companies</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Edit Companies</li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/companies" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold d-flex align-items-center">Edit Company</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md="3" className="mb-3">
                                        <Card.Body className="rounded bg-light">
                                            <div className="d-flex justify-content-center">
                                                {logoPreview && (
                                                    <img src={logoPreview} className="img-fluid" alt="company logo" />
                                                )}

                                            </div>
                                            <div className="d-flex justify-content-center mt-2 mb-3">
                                                <label htmlFor="imageUpload" className="mb-0 text-muted font-weight-bold upload-label" style={{ cursor: 'pointer' }}>
                                                    Upload Image
                                                </label>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    id="imageUpload"
                                                    onChange={handleLogoChange}
                                                    style={{ display: 'none' }} // Hide the input element
                                                />
                                            </div>

                                        </Card.Body>
                                    </Col>
                                    <Col md="9">
                                        <Form className="row g-3 date-icon-set-modal">
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text1" className="font-weight-bold text-muted text-uppercase">Name</Form.Label>
                                                <Form.Control type="text" id="Text1" name="name"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)} />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text4" className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                                <Form.Control type="text" id="Text4" name="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text5" className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                <Form.Control type="text" id="Text5" name="phone"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)} />
                                            </div>
                                            <div className="col-6 mb-3">
                                                <Form.Label className="form-label font-weight-bold text-muted text-uppercase">Currency</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    className="form-select"
                                                    value={selectedCurrency}
                                                    onChange={(e) => setSelectedCurrency(e.target.value)}
                                                >
                                                    <option value="">Select Currency</option>
                                                    {currencyOptions.map((currency) => (
                                                        <option key={currency.id} value={currency.id}>
                                                            {currency.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="inputState" className="font-weight-bold text-muted text-uppercase">Theme Color</Form.Label>
                                                <div className="input-group my-colorpicker2">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={colorThem}
                                                        onClick={toggleColorPicker}
                                                        readOnly
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">
                                                            <i className="fas fa-square" style={{ color: colorThem }} />
                                                        </span>
                                                    </div>
                                                </div>
                                                {showColorPicker && (
                                                    <div className="color-picker-popover" onClick={closeColorPicker}>
                                                        <ChromePicker color={colorThem} onChange={handleColorChange} />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text6" className="font-weight-bold text-muted text-uppercase">Stripe Publishable Key</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Text6"
                                                    name="publishableKey"
                                                    value={publishableKey}
                                                    onChange={(e) => setPublishableKey(e.target.value)}
                                                />

                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text7" className="font-weight-bold text-muted text-uppercase">Stripe Secret Key</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Text7"
                                                    name="secretKey"
                                                    value={secretKey}
                                                    onChange={(e) => setSecretKey(e.target.value)}
                                                />
                                            </div>
                                        </Form>

                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>

                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold d-flex align-items-center">Company User Detail</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md="3" className="mb-3">
                                        <Card.Body className="rounded bg-light">
                                            <div className="d-flex justify-content-center">
                                                {userImagePreview && (
                                                    <img src={userImagePreview} className="img-fluid" alt="user" />
                                                )}

                                            </div>
                                            <div className="d-flex justify-content-center mt-2 mb-3">
                                                <label htmlFor="userImageUpload" className="mb-0 text-muted font-weight-bold upload-label" style={{ cursor: 'pointer' }}>
                                                    Upload Image
                                                </label>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    id="userImageUpload"
                                                    onChange={handleImageChange}
                                                    style={{ display: 'none' }} // Hide the input element
                                                />
                                            </div>

                                        </Card.Body>
                                    </Col>
                                    <Col md="9">
                                        <Form className="row g-3 date-icon-set-modal">
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text1" className="font-weight-bold text-muted text-uppercase">Name</Form.Label>
                                                <Form.Control type="text" id="Text1" name="name"
                                                    value={userName}
                                                    onChange={(e) => setUserName(e.target.value)} />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text4" className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                                <Form.Control type="text" id="Text4" name="email"
                                                    value={userEmail}
                                                    onChange={(e) => setUserEmail(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text5" className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                <Form.Control type="text" id="Text5" placeholder="Enter Phone" name="phone"
                                                    value={userPhone}
                                                    onChange={(e) => setUserPhone(e.target.value)} />
                                            </div>

                                        </Form>
                                        <div className="d-flex flex-wrap justify-content-between mt-3">
                                            <Button variant="btn btn-secondary font-weight-bold btn-sm"

                                                onClick={handleDelete}
                                                disabled={isUpdating} // Disable the button while updating
                                            >
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                    </svg>
                                                    {isUpdating ? 'Deleting...' : 'Delete Company'}
                                                </div>
                                            </Button>
                                            <Button
                                                variant="btn btn-primary font-weight-bold btn-sm"
                                                onClick={handleSave}
                                                disabled={isUpdating} // Disable the button while updating
                                            >
                                                {isUpdating ? 'Updating...' : 'Save Company'}
                                            </Button>

                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Companiesedit;
