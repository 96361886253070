import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Card from '../../../../components/Card';
import noImage from '../../../../assets/images/no-image.png';

//img
import user1 from '../../../../assets/images/user/1.jpg'

const UserProfile = ({ user }) => {
    console.log(user.user.user.user);
    const userData = user.user.user.user.userModel;
    const userDetails = user.user.user.user.UserDetailModel;
    const handleDateFormat = (date) => {
        const dateTimeString = date;
        const dateTime = new Date(dateTimeString);

        // Convert to a human-readable format
        const formattedDate = dateTime.toLocaleDateString(); // e.g. "8/26/2023"
        const formattedTime = dateTime.toLocaleTimeString(); // e.g. "12:00:00 AM"

        // Combine date and time
        const formattedDateTime = `${formattedDate} ${formattedTime}`;
        return formattedDateTime;
    }
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="4">
                        <Card className="card-block p-card">
                            <div className="profile-box">
                                <div className="profile-card rounded">
                                    <img src={process.env.REACT_APP_BACKEND_URL + (userData.image ? userData.image : noImage)} alt="profile-bg"
                                        className="avatar-100 rounded d-block mx-auto img-fluid mb-3" />
                                    <h3 className="font-600 text-white text-center mb-0">{userData.name}</h3>
                                    <p className='mt-5'></p>
                                </div>
                                <div className="pro-content rounded">
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="p-icon mr-3">
                                            <i className="las la-envelope-open-text"></i>
                                        </div>
                                        <p className="mb-0 eml">{userData.email}</p>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="p-icon mr-3">
                                            <i className="las la-phone"></i>
                                        </div>
                                        <p className="mb-0">{userData.phone}</p>
                                    </div>
                                </div>
                            </div>
                        </Card>

                    </Col>
                    <Col lg="8">
                        {/* <Card>
                            <Card.Body className="p-0">
                                <Tab.Container defaultActiveKey="first">
                                    <Nav as="ul" variant="nav tab-nav-pane nav-tabs pt-2 mb-0">

                                    </Nav>

                                    <Tab.Content>
                                        <Tab.Pane eventKey="first" className=" fade show  ">
                                            <div className="d-flex justify-content-between align-items-center p-3">
                                                <h5>Company Details</h5>

                                            </div>
                                            <div className="table-responsive">
                                                <table className="table data-table mb-0">
                                                    <thead className="table-color-heading">
                                                        <tr className="text-muted">
                                                            <th scope="col">ID</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Email </th>
                                                            <th scope="col">Phone</th>
                                                            <th scope="col">Logo</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className="white-space-no-wrap">
                                                            <td>{user.companyDetails.id}</td>
                                                            <td>{user.companyDetails.name}</td>
                                                            <td>{user.companyDetails.email}</td>
                                                            <td>{user.companyDetails.phone}</td>
                                                            <td>
                                                                {user.user.user.companyDetails.logo ? (
                                                                    <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                                        <div className="h-avatar is-medium">
                                                                            <img src={process.env.REACT_APP_BACKEND_URL + user.user.user.companyDetails.logo} alt="Company Logo" className="avatar rounded-circle" />
                                                                        </div>

                                                                    </div>

                                                                ) : (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                                                    </svg>

                                                                )}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Tab.Pane>

                                    </Tab.Content>
                                </Tab.Container>
                            </Card.Body>
                        </Card> */}
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default UserProfile