import React, { useState } from 'react';
import { Container, Col, Row, Form, Button, Spinner } from 'react-bootstrap'
import Card from '../../../components/Card'
import { connect } from "react-redux";
import { getDarkMode } from '../../../store/mode'
import axios from 'axios';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom'
import logo from '../../../assets/images/logo.png';
import darklogo from '../../../assets/images/logo.png';
import logo2 from '../../../assets/images/logo2.png';

function mapStateToProps(state) {
   return {
      darkMode: getDarkMode(state)
   };
}

const RecoverPassword = (props) => {
   let history = useHistory()
   const [email, setEmail] = useState('');
   const [isSaving, setIsSaving] = useState(false);
   const [error, setError] = useState(null);

   const handleResetPassword = async (e) => {
      e.preventDefault();
      if (validate(email)) {
         try {
            setIsSaving(true);
            // Send a request to your API with the user's email address
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/forgot-password`, { email });

            if (response.data.status) {
               history.push('/auth/confirm-mail');
            } else {
               Swal.fire("Ooops!", response.data.message, 'error');
            }

         } catch (error) {
            Swal.fire("Ooops!", error?.response?.data?.message, 'error');
         }
         finally {
            setIsSaving(false);
         }
      }
   };

   const validate = (data) => {
      if (data === '' || data === null) {
         setError('Email is required.');
      } else if (!data.match(/^\S+@\S+\.\S+$/)) {
         setError('Please enter a valid email address.');
      } else {
         if (error !== null && error !== '') {
            return false;
         } else {
            return true;
         }
      }
   }

   const onChangeHandler = (e) => {
      setError(null);
      setEmail(e.target.value);
   }

   return (
      <>
         <section className="login-content">
            <Container className="h-100">
               <Row className="align-items-center justify-content-center h-100">
                  <Col md="5">
                     <Card className="p-5">
                        <Card.Body>
                           <div className="auth-logo">
                              <img src={logo} className={`img-fluid  rounded-normal  ${!props.darkMode ? 'd-none' : ''}`} alt="logo" />
                              <img src={darklogo} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} alt="logo" />
                              <img src={logo2} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} alt="logo" style={{ height: '40px', marginTop: '15px', marginLeft: '15px' }} />
                           </div>
                           <h3 className="mb-3 text-center">Reset Password</h3>
                           <p className="text-center small text-secondary mb-3">You can reset your password here</p>

                           <Row>
                              <Col lg="12">
                                 <Form.Group>
                                    <Form.Label className="text-secondary">Email</Form.Label>
                                    <Form.Control
                                       type="email"
                                       placeholder="Enter Email"
                                       // Bind the input value to a component state (e.g., setEmail)
                                       // Example:
                                       value={email}
                                       onChange={(e) => onChangeHandler(e)}
                                       className={error ? 'is-invalid' : ''}
                                    />
                                    {error && <div className="text-danger">{error}</div>}
                                 </Form.Group>
                              </Col>
                              <Col lg="12" className="mt-2">
                                 {isSaving ?
                                    <div className="loader-box">
                                       <Spinner animation="border" role="status">
                                          <span className="sr-only">Loading...</span>
                                       </Spinner>
                                    </div> :

                                    <Button type="button"
                                       variant="btn btn-primary btn-block loginBtn"
                                       onClick={(e) => handleResetPassword(e)}
                                       disabled={isSaving}
                                    >
                                       Reset Password
                                    </Button>}
                              </Col>
                           </Row>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </section>
      </>
   )
}

export default connect(mapStateToProps)(RecoverPassword)