import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Form, OverlayTrigger, Tooltip, Spinner, Modal } from 'react-bootstrap';
import Card from '../../../components/Card';
import axios from 'axios'; // Import Axios
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import './tablesearch.css';
import { Link, useHistory } from 'react-router-dom';

const Transaction = ({ user }) => {

    const history = useHistory();
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterModalShow, setFilterModalShow] = useState(false);
    const [filterData, setFilterData] = useState({
        id: '',
        reseller: '',
        status: ''
    });

    useEffect(() => {
        const token = user.user.user.token;
        const headers = { Authorization: `Bearer ${token}` }

        // Fetch transactions data using Axios
        axios.post(process.env.REACT_APP_API_URL + '/resellers/all-transactions', filterData, { headers })
            .then((response) => {
                if (response.data.status) {
                    setTransactions(response.data.transactionData); // Assuming API response is an array of transactions
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching transactions:', error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {

        // Initialize the DataTable when categories change
        if (!loading && transactions.length > 0) {
            if (!$.fn.DataTable.isDataTable(dataTableRef.current)) {
                // If not initialized, initialize the DataTable
                $(dataTableRef.current).DataTable({
                    columns: [
                        { data: 'id', title: 'ID' },
                        { data: 'customer', title: 'Reseller' },
                        { data: 'requested_amount', title: 'Requested Amount' },
                        { data: 'transaction_amount', title: 'Approved Amount' },
                        { data: 'status', title: 'Status' },
                        { data: 'action', title: 'Action' },
                    ],
                    order: [[0, 'desc']],
                });
            }
        }
    }, [transactions, loading]);

    const dataTableRef = useRef(null);

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return '#3498db'; // Blue
            case 'completed':
                return '#3cb72c'; // Green
            case 'declined':
                return '#e74c3c'; // Red
            case 'refunded':
                return '#f39c12'; // Orange
            case 'partially refunded':
                return '#e67e22'; // Darker Orange
            default:
                return '#000000'; // Black (Fallback color)
        }
    };

    const getStatusName = (status) => {
        let statusText;
        switch (status) {
            case 'pending':
                statusText = 'Pending';
                break;
            case 'completed':
                statusText = 'Completed';
                break;
            case 'declined':
                statusText = 'Declined';
                break;
            case 'refunded':
                statusText = 'Refunded';
                break;
            case 'partially refunded':
                statusText = 'Partially Refunded';
                break;
            default:
                statusText = 'Unknown Status';
        }
        return statusText;
    };

    const openFilterModal = () => {
        setFilterModalShow(true);
    }

    const onFilterChangeHandler = (e) => {
        if (e.target.name === 'id') {
            setFilterData(prevState => ({
                ...prevState,
                id: e.target.value
            }));
        } else if (e.target.name === 'reseller') {
            setFilterData(prevState => ({
                ...prevState,
                reseller: e.target.value
            }));
        } else if (e.target.name === 'status') {
            setFilterData(prevState => ({
                ...prevState,
                status: e.target.value
            }));
        }
    }

    const onFilterSubmitHandler = (e) => {
        e.preventDefault();
        handleFilterModalClose();

        if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
            $(dataTableRef.current).DataTable().destroy(); // Destroy existing DataTable
        }

        setLoading(true);
        const token = user.user.user.token;
        const headers = { Authorization: `Bearer ${token}` }

        // Fetch transactions data using Axios
        axios.post(process.env.REACT_APP_API_URL + '/resellers/all-transactions', filterData, { headers })
            .then((response) => {
                if (response.data.status) {
                    setTransactions(response.data.transactionData); // Assuming API response is an array of transactions
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching transactions:', error);
                setLoading(false);
            });
    }

    const handleFilterModalClose = () => {
        setFilterModalShow(false);
        setFilterData({
            id: '',
            reseller: '',
            status: ''
        });
    }

    const goBack = () => {
        history.goBack();
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <Row>
                            <Col lg="12">
                                <Card className="card-block card-stretch">
                                    <Card.Body className="p-0">
                                        <div className="d-flex justify-content-between align-items-center p-3">
                                            <h5 className="font-weight-bold">Reseller's Recharge Transactions</h5>
                                            <OverlayTrigger placement="top" overlay={<Tooltip>Filter</Tooltip>}>
                                                <button className="btn btn-primary loginBtn" onClick={openFilterModal}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                                                    </svg>
                                                </button>
                                            </OverlayTrigger>
                                        </div>
                                        <div className="table-responsive">
                                            {loading ? (
                                                <div className="text-center mb-5">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <table ref={dataTableRef} className="table data-table mb-0">
                                                    <thead className="table-color-heading">
                                                        <tr className="">
                                                            <th scope="col">ID</th>
                                                            <th scope="col">Reseller</th>
                                                            <th scope="col">Requested Amount</th>
                                                            <th scope="col">Approved Amount</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transactions.map((transaction) => (
                                                            <tr key={transaction.id}>
                                                                <td>{transaction.id}</td>
                                                                <td>{transaction.reseller}</td>
                                                                <td>&euro; {transaction.requested_amount}</td>
                                                                <td>&euro; {transaction.transaction_amount}</td>
                                                                <td>
                                                                    <p className={`mb-0 ${getStatusColor(transaction.transaction_status)} font-weight-bold d-flex justify-content-start align-items-center`}>
                                                                        <small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                                                            <circle cx="12" cy="12" r="8" fill={getStatusColor(transaction.transaction_status)}></circle></svg>
                                                                        </small>{getStatusName(transaction.transaction_status)}
                                                                    </p></td>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        {/* Wallet View */}
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>View Wallet</Tooltip>} >
                                                                            <Link className="" to={`/reseller/wallet-view/${transaction.user_id}`}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 14V6a2 2 0 00-2-2H6a2 2 0 00-2 2v8m16 0h2m0 0h-2m0 0h-2m0 0h2m-2 0a4 4 0 01-4 4H6a4 4 0 01-4-4M4 10h16m4 0a6 6 0 01-6 6H8a6 6 0 01-6-6m16 0v4m0 0a2 2 0 01-2 2H6a2 2 0 01-2-2m0 0a6 6 0 016-6h4a6 6 0 016 6z"></path>
                                                                                </svg>
                                                                            </Link>
                                                                        </OverlayTrigger>
                                                                        {/* Reseller Profile */}
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>View Profile</Tooltip>} >
                                                                            <Link to={`/reseller/wallet-view/${transaction.user_id}`}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 2c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3zM12 14c-2.598 0-8 1.334-8 4v2c0 1.105.895 2 2 2h12c1.105 0 2-.895 2-2v-2c0-2.666-5.402-4-8-4z"></path>
                                                                                </svg>
                                                                            </Link>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Modal size="lg" show={filterModalShow}>
                    <Modal.Header>
                        <Modal.Title as="h5">Filter Reseller Transactions</Modal.Title>
                        <button className="badge bg-danger modal-close-button" onClick={handleFilterModalClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="id">Id</Form.Label>
                                        <Form.Control id="id" onChange={onFilterChangeHandler} name="id" placeholder='Enter Id' value={filterData.id} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="reseller">Reseller</Form.Label>
                                        <Form.Control id="reseller" onChange={onFilterChangeHandler} name="reseller" placeholder='Enter Reseller' value={filterData.reseller} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="status">Status</Form.Label>
                                        <Form.Select id="status" className="form-control" name="status" onChange={onFilterChangeHandler} value={filterData.status}>
                                            <option value={''} disabled>Select Status</option>
                                            <option value={'completed'}>Completed</option>
                                            <option value={'pending'}>Pending</option>
                                            <option value={'declined'}>Declined</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary loginBtn" onClick={onFilterSubmitHandler}>Submit</Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </>
    )

}

export default Transaction;