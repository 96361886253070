import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, ListGroup, Spinner } from 'react-bootstrap';
import Card from './../../../components/Card'
import { Link, useParams, useHistory } from 'react-router-dom'
import axios from 'axios'; // Import Axios
import './print.css'

const Invoiceview = ({ user }) => {
    const history = useHistory();
    const { invoiceId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [invoiceData, setInvoiceData] = useState([]);
    const [orderData, setOrderData] = useState({});
    const [orderItemsData, setOrderItemsData] = useState([]);
    const [customerData, setCustomerData] = useState({});
    const [customerCountryData, setCustomerCountryData] = useState({});
    const [customerStateData, setCustomerStateData] = useState({});
    const [companyData, setCompanyData] = useState({});
    const [currencyData, setCurrencyData] = useState({});


    const token = user.user.user.token

    useEffect(() => {
        fetchInvoiceData();
    }, []);

    const fetchInvoiceData = () => {
        axios.get(process.env.REACT_APP_API_URL + '/companies/' + user.user.user.companyDetails.id + '/invoices/' + invoiceId, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response) => {
                setIsLoading(false);
                const fetchedInvoiceData = response.data;
                if (fetchedInvoiceData.status) {
                    setInvoiceData(fetchedInvoiceData.invoiceData);
                    setCompanyData(fetchedInvoiceData.invoiceData.company);
                    setOrderData(fetchedInvoiceData.invoiceData.order);
                    setOrderItemsData(fetchedInvoiceData.invoiceData.order.order_items);
                    setCustomerData(fetchedInvoiceData.invoiceData.customer);
                    setCustomerCountryData(fetchedInvoiceData.invoiceData.customer.country);
                    setCustomerStateData(fetchedInvoiceData.invoiceData.customer.state);
                    setCurrencyData(fetchedInvoiceData.invoiceData.currency);

                }
            })
            .catch((error) => {
                history.push('/not-found');
            });
    }

    function formatDate(inputDate) {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };

        const date = new Date(inputDate);
        const formattedDate = date.toLocaleDateString('en-US', options);

        // Replace 'st', 'nd', 'rd', 'th' for the day part
        const dayWithSuffix = formattedDate.replace(
            /(\d{1,2})(st|nd|rd|th)/,
            (match, day, suffix) => {
                return day + (suffix === 'st' ? 'st' : suffix === 'nd' ? 'nd' : suffix === 'rd' ? 'rd' : 'th');
            }
        );

        return dayWithSuffix;
    }
    const handlePrint = () => {
        window.print(); // This will trigger the browser's print dialog
    };
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12" className="no-print">

                        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                            <h4 className="font-weight-bold">Invoice View</h4>
                            <Link to="/invoices" className="btn btn-primary btn-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>


                        </div>
                    </Col>

                    <Col lg="12">
                        {isLoading ? (
                            <div className="text-center">
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                        ) : (
                            <Card>
                                <Card.Body>
                                    <Row className="pb-4 mx-0 card-header-border">
                                        <Col lg="12" className="mb-3">
                                            <img className="avatar avatar-50 is-squared" alt="user-icon" src={process.env.REACT_APP_BACKEND_URL + companyData.logo} />

                                            <button className="btn btn-secondary px-4 float-right no-print" onClick={handlePrint}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"></path>
                                                </svg>
                                                Print
                                            </button>
                                        </Col>
                                        <Col lg="6">
                                            <div className="text-left">
                                                <h5 className="font-weight-bold mb-2">Invoice number</h5>
                                                <p className="mb-0">{invoiceData.invoice_number}</p>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div className="text-right">
                                                <h5 className="font-weight-bold mb-2">Invoice Date</h5>
                                                <p className="mb-0">{formatDate(invoiceData.invoice_date)}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="pt-4 pb-5 mx-0">
                                        <Col lg="6">
                                            <div className="text-left">
                                                <h5 className="font-weight-bold mb-3">Invoice From</h5>
                                                <p className="mb-0 mb-1">{companyData.name}</p>
                                                <p className="mb-0 mb-2">{companyData.email}</p>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div className="text-right">
                                                <h5 className="font-weight-bold mb-3">Invoice To</h5>
                                                <p className="mb-0 mb-1">{customerData.name}</p>
                                                <p className="mb-0 mb-1">{customerData.address}</p>
                                                <p className="mb-0 mb-1">{customerStateData.name}</p>
                                                <p className="mb-0 mb-1">{customerData.zipcode}</p>
                                                <p className="mb-0 mb-2">{customerCountryData.name}</p>
                                                <p className="mb-0 mb-2">info@blueberry.com</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <ListGroup as="ul" className="list-group-flush">
                                                <ListGroup.Item as="li" className="p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered mb-0">
                                                            <thead>
                                                                <tr className="text-muted">
                                                                    <th scope="col" className="text-left">No</th>
                                                                    <th scope="col">Name</th>
                                                                    <th scope="col">Description</th>
                                                                    <th scope="col" className="text-right">Quantity</th>
                                                                    <th scope="col" className="text-right">Price</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    orderItemsData.map((item) => (

                                                                        <tr key={item.id}>
                                                                            <td className="text-left">
                                                                                1
                                                                            </td>
                                                                            <td>
                                                                                {item.product.name}
                                                                            </td>
                                                                            <td>
                                                                                {item.product.product_description}
                                                                            </td>
                                                                            <td className="text-right">
                                                                                {item.quantity}
                                                                            </td>
                                                                            <td className="text-right">
                                                                                {currencyData.symbol}{item.price}
                                                                            </td>

                                                                        </tr>
                                                                    ))
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </ListGroup.Item>
                                                <ListGroup.Item as="li">
                                                    <div className="d-flex justify-content-end mb-2">
                                                        Subtotal: <p className="ml-2 mb-0">{currencyData.symbol}{invoiceData.total_amount}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-end mb-2">
                                                        Discount: <p className="ml-2 mb-0">{currencyData.symbol}{invoiceData.discount_amount}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-end mb-2">
                                                        Total: <p className="ml-2 mb-0 font-weight-bold">{currencyData.symbol}{invoiceData.paid}</p>
                                                    </div>

                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Col>

                                        <Col lg="12">
                                            <div className="d-flex flex-wrap justify-content-between align-items-center p-4">
                                                <div className="flex align-items-start flex-column">
                                                    <h6>Notes</h6>
                                                    <p className="mb-0 my-2">{orderData.comments}</p>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        )}
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Invoiceview;