import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Card from '../../../components/Card';
import { Link, useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import './custome-select.css';


const Customeredit = ({ user }) => {
    let history = useHistory()
    const { customerId } = useParams();
    const [customerData, setCustomerData] = useState({});
    const [countriesData, setCountriesData] = useState([]);
    const [statesData, setStatesData] = useState([]);
    const [customerEmail, setCustomerEmail] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [allStatesData, setAllStatesData] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);

    const token = user.user.user.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/companies/${user.user.user.companyDetails.id}/customers/${customerId}/edit`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setCustomerData(response.data.customersData);
                setCountriesData(response.data.countries);

                setCustomerEmail(response.data.customersData.email);
                setAllStatesData(response.data.states);

                const selectedCountryId = response.data.customersData.country_id;
                const selectedCountry = response.data.countries.find(country => country.id === selectedCountryId);

                const matchingStates = response.data.states.filter((state) => state.country_id === selectedCountryId);
                setStatesData(matchingStates);

                const selectedStateId = response.data.customersData.state_id;
                const selectedState = response.data.states.find(state => state.id === selectedStateId);

                setSelectedCountry({ 'value': selectedCountry.id, 'label': selectedCountry.name });
                setSelectedState({ 'value': selectedState.id, 'label': selectedState.name });

            } catch (error) {
                console.error('Error fetching customer details:', error);
            }
        };

        fetchData();
    }, [customerId, token, user.user.user.companyDetails.id]);
    const getCountryStates = (countryId) => {
        const matchingStates = allStatesData.filter((state) => state.country_id === countryId);

        setStatesData(matchingStates);
    }
    const handleNameChange = (event) => {
        const newName = event.target.value;
        setCustomerData((prevData) => ({
            ...prevData,
            name: newName,
        }));
    };
    const handlePhoneChange = (event) => {
        const newPhone = event.target.value;
        setCustomerData((prevData) => ({
            ...prevData,
            phone: newPhone,
        }));
    };
    const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setCustomerData((prevData) => ({
            ...prevData,
            email: newEmail,
        }));
    };
    const handleCompanyChange = (event) => {
        const selectedCompanyId = event.target.value;
        setCustomerData(prevCustomerData => ({
            ...prevCustomerData,
            company_id: selectedCompanyId
        }));
    };
    const handleCountryChange = (event) => {

        setSelectedCountry(event);
        setStatesData([]);
        setSelectedState("");
        getCountryStates(event.value);
        setCustomerData(prevCustomerData => ({
            ...prevCustomerData,
            country_id: event.value
        }));
    };

    const handleStateChange = (event) => {
        setSelectedState(event);
        setCustomerData(prevCustomerData => ({
            ...prevCustomerData,
            state_id: event.value
        }));
    };
    const handleAddressChange = (event) => {
        const newAddress = event.target.value;
        setCustomerData((prevData) => ({
            ...prevData,
            address: newAddress,
        }));
    };

    const handleZipcodeChange = (event) => {
        const newZipcode = event.target.value;
        setCustomerData((prevData) => ({
            ...prevData,
            zipcode: newZipcode,
        }));
    };
    const handleSaveCustomer = async () => {
        try {
            setIsUpdating(true);
            if (customerData.email === customerEmail) {
                delete customerData.email;
            }
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/companies/${user.user.user.companyDetails.id}/customers/${customerId}`,
                customerData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.data.status) {
                Swal.fire('Done!', response.data.message, 'success');
            } else {
                Swal.fire('Oops!', response.data.message, 'error');
            }
            setIsUpdating(false);
        } catch (error) {
            setIsUpdating(false);
            Swal.fire('Oops!', 'Error', 'error');
            // Handle error notifications or messages
        }
    };
    const handleDelete = () => {
        Swal.fire({
            title: 'Delete Customer',
            text: 'Are you sure you want to delete this customer?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it',
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(process.env.REACT_APP_API_URL + `/companies/${user.user.user.companyDetails.id}/customers/${customerId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((response) => {
                        if (response.data.status) {
                            Swal.fire('Done!', response.data.message, 'success').then(() => {
                                history.push('/customers');
                            });
                        } else {
                            Swal.fire('Ooopsss!', response.data.message, 'error');
                        }
                    })
                    .catch((error) => {
                        Swal.fire('Ooopsss!', error.response.data.message, 'error');
                    });
            }
        });
    };
    return (

        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/customers">Customers</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Edit Customer</li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/customers" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold d-flex align-items-center">Edit Customer</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row>

                                    <Col md="9">
                                        <Form className="row g-3 date-icon-set-modal">
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text1" className="font-weight-bold text-muted text-uppercase">Full Name</Form.Label>
                                                <Form.Control type="text" id="Text1" onChange={handleNameChange} value={customerData.name || ''} />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text4" className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                                <Form.Control type="text" id="Text3" onChange={handleEmailChange} value={customerData.email || ''} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text5" className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                <Form.Control type="text" id="Text3" onChange={handlePhoneChange} value={customerData.phone || ''} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="inputCountry" className="font-weight-bold text-muted text-uppercase">Country</Form.Label>
                                                <Select
                                                    classNamePrefix="my-select"
                                                    className='custom-select-container'
                                                    id="customerSelect"
                                                    value={selectedCountry}
                                                    options={countriesData.map(country => ({ value: country.id, label: country.name }))}
                                                    onChange={handleCountryChange}
                                                />

                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="inputState" className="font-weight-bold text-muted text-uppercase">State/Region</Form.Label>

                                                <Select
                                                    classNamePrefix="my-select"
                                                    className='custom-select-container'
                                                    id="customerSelect"
                                                    value={selectedState}
                                                    options={statesData.map(state => ({ value: state.id, label: state.name }))}
                                                    onChange={handleStateChange}
                                                />

                                            </div>


                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text6" className="font-weight-bold text-muted text-uppercase">Address</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Text6"
                                                    value={customerData.address || ""}
                                                    onChange={handleAddressChange}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text7" className="font-weight-bold text-muted text-uppercase">Zipcode</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Text7"
                                                    value={customerData.zipcode || ""}
                                                    onChange={handleZipcodeChange}
                                                />
                                            </div>


                                        </Form>
                                        <div className="d-flex flex-wrap justify-content-between mt-3">
                                            <Button variant="btn btn-secondary font-weight-bold btn-sm"

                                                onClick={handleDelete}
                                                disabled={isUpdating} // Disable the button while updating
                                            >
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                    </svg>
                                                    {isUpdating ? 'Deleting...' : 'Delete Customer'}
                                                </div>
                                            </Button>
                                            <Button
                                                variant="btn btn-primary font-weight-bold btn-sm"
                                                onClick={handleSaveCustomer}
                                                disabled={isUpdating} // Disable the button while updating
                                            >
                                                {isUpdating ? 'Updating...' : 'Save Customer'}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Customeredit;
