import React, { useRef, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';


const AddProductModal = ({ show, handleClose, handleAddProduct }) => {
    const productNameRef = useRef(null);
    const priceRef = useRef(null);
    const [error, setError] = useState('');
    // Add refs for other form inputs

    const validateForm = () => {
        if (!productNameRef.current.value || !priceRef.current.value) {
            setError('Please fill out all fields');
            return false;
        }
        setError('');
        return true;
    }
    const handleSubmit = () => {
        if (validateForm()) {
            handleAddProduct(productNameRef.current.value, priceRef.current.value);
            handleClose();
        }
    }
    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Add Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formProductName">
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control type="text" ref={productNameRef} />
                    </Form.Group>
                    <Form.Group controlId="formPrice">
                        <Form.Label>Price</Form.Label>
                        <Form.Control type="number" ref={priceRef} />
                    </Form.Group>
                </Form>
                {error && <div className="text-danger">{error}</div>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Add Item
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddProductModal;
